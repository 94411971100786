import React, {useState, useEffect} from 'react';

import {BoxContainer,ContentWrapper,GradientOverlayContent,ThumbnailContent,ThumbnailWrapper,
TitleContent,TitleWrapper} from './modelboxElements';

const ModelBox = ( props ) => {

    console.debug( 'ModelBox props:', props );

    return(
    <BoxContainer  onClick={() => props.handleClick(props.model, props.modelNumber)} >
        <ContentWrapper>
            <GradientOverlayContent></GradientOverlayContent>
            <ThumbnailWrapper>
                <ThumbnailContent src={props.modelsUrl}></ThumbnailContent>
            </ThumbnailWrapper>
            <TitleWrapper>
                {props.model.sfDoc.name}
            </TitleWrapper>
        </ContentWrapper>

    </BoxContainer>
    )

}

export default ModelBox;