import styled, { keyframes } from 'styled-components';

// Add keyframes for expand and shrink animations
const expand = keyframes`
  from {
    max-width: 0;
    opacity: 0;
  }
  to {
    max-width: 400px;
    opacity: 1;
  }
`;

const shrink = keyframes`
  from {
    max-width: 400px;
    opacity: 1;
  }
  to {
    max-width: 0;
    opacity: 0;
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOutToLeft = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

export const PreviewContainer = styled.div`
display: flex;
`

export const PreviewWrapper = styled.div`
display: flex;
`

export const centeredLoading = styled.div`
display: flex;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 2;
  gap: 8px;
  /* background-color: #1c2127; */
  padding: 4px;
  border-radius: 6px;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  overflow: hidden;

`;

export const ButtonBorder = styled.div`
  position: absolute;
  justify-content: center;
  bottom: 32px;
  left: auto;
  right: auto;
  /* padding: 4px;
  background: linear-gradient(60deg, #3e4b58a6, #55687bba); */
  border-radius: 8px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  animation: ${props => (props.isVisible ? slideOutToLeft : slideInFromLeft)} 0.3s forwards;
  box-shadow: 2px 2px 8px #00000036;
`;

export const HudBigButton = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #3737376e;
  border-radius: 4px;
  font-family: 'Space Grotesk', monospace, sans-serif;
  font-size: 16px;
  color: white;
  user-select: none;

  &:hover {
      background-color: #373737a3;
  }

  img{
    width: 32px;
    height: 32px;
  }
`

export const HudSmallButton = styled.div`
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* background-color: #113D67; */
    border-radius: 4px;
    font-family: 'Space Grotesk', monospace, sans-serif;
/*     
    &:hover {
        background-color: #0D6ECB;
    } */
`

export const HudSpacer = styled.div`
  height: 24px;
  width: 2px;
  border-radius: 2px;
  background-color: #2d2d2d;
`

export const DirectionButtonsContainer = styled.div`
    display: flex;
    flex-direction: row; 
    gap: 8px;
`

export const DirectionButton = styled.img`
    z-index: 2;
    border-radius: 12px;
    height: 20px;
    width: 20px;
    padding: 2px;
    background: rgba(255,255,255,0.2);
    color: white;
    font-weight: bold;
    border: 0px;
    cursor: pointer;

    &:hover {
    -webkit-filter: invert(100%) !important;
    filter: invert(100%);
    }
`

export const ARbutton = styled.button`
    z-index: 2;
    border-radius: 12px;
    height: 24px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    background: linear-gradient(160deg, rgb(162, 28, 175), rgb(234, 88, 12));
    color: white;
    font-weight: bold;
    font-family: 'Space Grotesk', monospace, sans-serif;
    border: 0px;
    cursor: pointer;
    padding: 0 8px;
`

export const VRbutton = styled.button`
    z-index: 2;
    border-radius: 12px;
    height: 24px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    background: linear-gradient(160deg, rgb(162, 28, 175), rgb(234, 88, 12));
    color: white;
    font-weight: bold;
    font-family: 'Space Grotesk', monospace, sans-serif;
    border: 0px;
    cursor: pointer;
    padding: 0 8px;
`

export const ArVrPopupContainer = styled.div`
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    height: 100%;
    width: 100%;
    gap: 24px;
`

export const ArVrPopupContent = styled.div`
    max-width: 678px;
    color: white;
    font-weight: 300;
    font-family: 'Space Grotesk', monospace, sans-serif;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.3);
    padding: 24px;
    margin: 24px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`

export const HeaderPopup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
`

export const TitlePopup = styled.div`
    flex-grow: 1;
    text-align: center;
    font-size: 24px;
    padding: 0;
    margin: 0 -72px 0 0;
`

export const CloseButtonPopup = styled.div`
    cursor: pointer;
    background: none;
    border: none;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    font-weight: 300;
    font-size: 24px;
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    line-height: 48px;
    :hover{
        background: rgba(255, 255, 255, 0.2);
    }
`

export const BodyPopup = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: left;
`

export const QRCodePopup = styled.div`

`

export const FooterPopup = styled.div`
    display: flex;
    justify-content: center;
`

export const LinkButtonPopup = styled.button`
    height: 32px;
    border-radius: 12px;
    background: rgb(239, 88, 12);
    padding: 0 8px;
    line-height: 32px;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    font-family: 'Space Grotesk', monospace, sans-serif;
    border: none;
    cursor: pointer;
`

export const AnnotationsContainer = styled.div`
    width: 100%;
    height: 100%;
    background: transparent ;
    //user-select: none;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
`

export const AnnotationContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  user-select: none;
  z-index: 3;
  background-color: #0F172A;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    background-color: lightgray;
    color: #0F172A;
  }
`

export const IFrame = styled.iframe`
    width: 100%;
    height: 100%;
    border: 0;
    position: absolute;
    left: 0;
    z-index: 1;
    user-select: none;
`

export const OpenMenuButton = styled.button`
  position: absolute;
  top: 16px;
  left: 16px;
  height: 24px;
  /* width: 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1C5183;
  border: none;
  border-radius: 4px;
  z-index: 10;
  cursor: pointer;
  background: #00000038;
  gap: 4px;
  padding: 0 4px;
  text-transform: uppercase;
  color: white;
  font-family: Space Grotesk, monospace, sans-serif;
  animation: ${props => (props.isVisible ? slideOutToLeft : slideInFromLeft)} 0.3s forwards;
`;

export const CloseMenuButton = styled.button`
  padding: 4px;
  background: linear-gradient(60deg, #A21CAF, #EA580C);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  z-index: 2;
`;

export const CloseHudButton = styled.div`
  position: absolute;
    top: -20px;
    right: 4px;
    border-radius: 4px 4px 0px 0px;
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.35);
    cursor: pointer;

    &:hover {
        background-color: #EA580C;
    }
`;

export const ExpandedSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  background-color: #3535355c;
  margin: -4px -4px;
  padding: 4px;
  animation: ${({ isExpanded }) => (isExpanded ? expand : shrink)} 0.3s forwards;
  border-radius: 8px;
  border: 2px solid #ea580c;
`;

// Tooltip for sections
export const SectionTooltip = styled.div`
  position: absolute;
  background: #EA580C;
  color: #fff;
  padding: 2px 8px;
  border-radius: 0 0 8px 8px;
  text-align: center;
  width: max-content;
  max-width: 200px;
  bottom: -20px;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'Space Grotesk', monospace, sans-serif;
  user-select: none;
`;

export const IconTooltip = styled.img`
    width: 16px;
    height: 16px;
`;

export const ButtonHover = styled.div`
    position: absolute;
    background: #252525;
    color: #fff;
    padding: 2px 8px;
    border-radius: 8px;
    text-align: center;
    width: max-content;
    max-width: 200px;
    top: -36px;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 200;
    text-transform: uppercase;
    font-family: 'Space Grotesk', monospace, sans-serif;
`;

export const IconHover = styled.img`
    width: 16px;
    height: 16px;
`;

export const AnnotationHudContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
`;

export const AnnotationHudFrame = styled.div`
  min-width: 240px;
  max-width: calc(100vw - 48px);
  width: 320px;
  pointer-events: auto;
  margin-left: auto;
  margin-right: auto;
  z-index: 11;
  display: flex;
  flex-direction: column;
`;

export const AnnotationHudWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0,0,0,0.85);
  border: 4px solid #444444; */
  border-radius: 8px;
  overflow: hidden;
`;

export const AnnotationHudHead = styled.div`
  min-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  background: #333333a6;
  color: white;
  /* border-bottom: 1px solid #444444bf; */
`;

export const AnnotationHudNumber = styled.div`
  height: 24px;
  width: 24px;
  font-size: 16px;
  background-color: #ea580c;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const AnnotationHudTitle = styled.div`
  flex-grow: 1;
`;

export const AnnotationHudHeadClose = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
  padding: 2px;
  background-color: #1b1b1b70;
  border-radius: 12px;

  &:hover {
    background-color: #1b1b1b;
  }
`;

export const AnnotationHudBody = styled.div`
  min-height: 48px;
  padding: 12px;
  font-weight: 300;
  background: #fffffff2;
  color: black;
  flex-grow: 1;
  overflow-y: auto;
`;

export const AnnotationHudFooter = styled.div`
  background: #fffffff2;
  color: black;
`;

export const AnnotationHudFooterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  gap: 8px;
`;

export const AnnotationHudFooterButton = styled.div`
  padding: 2px 12px;
  background-color: transparent;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  border: 2px solid #EA580C;
  
  &:hover {
    background-color: #EA580C;
  }
`;

export const AnnotationHudFooterPreviousButton = styled.div`
  background-color: #ea580c;
  border-radius: 4px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #1b1b1b;
  }
`;

export const AnnotationHudFooterNextButton = styled.div`
  background-color: #ea580c;
  border-radius: 4px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #1b1b1b;
  }
`;

export const AnnotationHudExternalHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 4px 0;
`;

export const ButtonPositionsContainer = styled.div`
    position: absolute;
    top: 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    z-index: 3;
`;

export const ButtonPosition  = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #00000038;
    box-shadow: 0px 0px 6px 0px #0000003b;
    cursor: pointer;
`;

export const ButtonResetPosition = styled.div`
    background: rgb(63 63 63 / 20%);
    color: white;
    height: 24px;
    border-radius: 4px;
    padding: 0 4px;
    cursor: pointer;
    line-height: 24px;
    font-size: 14px;

    &:hover {
        background-color: #3737377d;
    }
`;