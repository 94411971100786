import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import LinkButton from '../linkbutton/index.js';
import ModelPanel from '../modelpanel/index.js';
import Sidebar from '../sidebar/index.js';
import {SidebarContainer,ViewContainer,ContentWrapper,HeaderBar,UploadButton,ButtonImage,LeftButtonUpload,
    LeftButtonList,LeftContent,LeftWrapper,MetaBallContainer,RightWrapper,LeftText,ListCloseButton,
    ListContainer,ListContent,   ListFirstRow,ListHeader,ListSearchContainer,ListSearchIcon,
    ListSearchTextInput,    ListTitle,ListWrapper,MetaBallBackgroundContainer,MetaElement,
    InputEditName,EmtpyContainer,BackgroundLoop,BackgroundLoopContainer} from './dashboardElements.js';
import ModelPage from '../modelpage/index.js';
import useAuth from '../../hooks/useAuth.js';
import testmetaballvideo from '../../img/metatest3.webm';
import backloop from '../../img/backloopopen1.gif';
import uploadicon from '../../img/upload24icon.png';
import listicon from '../../img/list24icon.png';
import searchicon from '../../img/search24icon.png';
import closeicon from '../../img/close24icon.png';
import ModelList from '../modellist';
import Dialog from '../dialog/index';
import Popup from '../popup';
import UploadForm from '../uploadform';
import CircleLoader from '../circleloader/index.js';
import { MobileContext } from '../../context/MobileContext';
import { useWebSocket } from '../../context/WebSocketContext';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {

const navigate = useNavigate();
const { auth } = useAuth();
const { setAuth } = useAuth();
const isMobile = useContext(MobileContext);
const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;
// const apiUrl = 'https://test2.3dabackend.com';
//const apiUrl = "https://0146-87-17-214-243.ngrok-free.app";

// !! se testing false allora la prima, sennò la seconda
const apiGetModels = apiUrl + (process.env.REACT_APP_TESTING === 'true' ? "/forum/users/models/" : "/forum/user/models/");
// const apiGetModels = apiUrl + "/forum/users/models/";

//console.log("jwt dashboard: " + auth?.accessToken);
//console.log("auth.id: " + auth.id);
const [models, setModels] = useState([]);
const [modelsUrl, setModelsUrl] = useState([]);
const [modelsIconUrl, setModelsIconUrl] = useState([]);
const [modelsHDImageUrl, setModelsHDImageUrl] = useState([]);
const [uid_collection, setUid_collection] = useState(null);
const [selectedModel, setSelectedModel] = useState(null);
const [selectedI, setSelectedI] = useState(null);
const [JSONselectedModel, setJSONselectedModel] = useState([]);
const [isModelsUrlLoading, setIsModelsUrlLoading] = useState(true)
const [isLoading, setIsLoading] = useState(true);
const [isUploading, setIsUploading] = useState(false);
const [isListOpen, setIsListOpen] = useState(false);
const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
const [searchText, setSearchText] = useState("");

const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
const [selectedModelId, setSelectedModelId] = useState(null);
const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
const [newModelName, setNewModelName] = useState('');  
const { sendMessage, receivedMessage } = useWebSocket();
const { messages } = useWebSocket();


useEffect( () => {
    fetchData();
}, [auth?.accessToken]);

const fetchData = async () => {
    try {
      const id = auth.id;
      const response = await axios.post(apiGetModels, { idUtente: id }, {
        headers: {
          'Authorization': `Bearer ${auth?.accessToken}`,
          'id': auth.id,
        },
      });

      const isTesting = process.env.REACT_APP_TESTING === 'true';
      const responseData = isTesting ? response.data.data : response.data.message;

      console.log("apigetmodels response");
      console.log(response);

      if (responseData.message !== null) {
        console.log(responseData?.modelli);
        setModels(responseData?.modelli);

        console.log(models);
        console.log(response);

        console.log("fetchModelData");

        await fetchModelData(responseData?.modelli);

        const collectionUrl = responseData?.collection;
        const splitUrl = collectionUrl.split('/');
        const collectionuid = splitUrl[splitUrl.length - 1];
        setUid_collection(collectionuid);
        console.log(collectionuid);
        setAuth(prevAuth => ({ ...prevAuth, collectionuid }));
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 403) {
        console.log("YOU SHOULD LOGIN AGAIN");
        // navigate('/login');
      }
    }
  };

const fetchModelData = async (models) => {
    try {
      const promises = models.map((model) => {
        return axios.get(`https://api.sketchfab.com/v3/models/${model.uid}`, 
            { headers: { 'Authorization': 'Token ' + '409eb8f52291eaf0c2c824c916d6b5af' } }
        );
      });
      

      setIsLoading(true);
      const responses = await Promise.all(promises);
      const data = responses.map((response) => response.data);
      console.log("promises data: ", data);
  
      const sortedModels = models
        .map((model, index) => ({
          ...model,
          createdAt: new Date(models[index].sfDoc.createdAt), // Convert createdAt to date object
        }))
        .sort((a, b) => b.createdAt - a.createdAt); // Sort models by createdAt in descending order (newest first)
    
      const sortedModelsHDImageUrl = sortedModels.map((model) => {
        const foundModel = data.find((item) => item.uid === model.uid);
        return foundModel?.thumbnails.images[0].url;
      });
      
      const sortedModelsUrl = sortedModels.map((model) => {
        const foundModel = data.find((item) => item.uid === model.uid);
        return foundModel?.thumbnails.images[2].url;
      });
  
      const sortedModelsIconUrl = sortedModels.map((model) => {
        const foundModel = data.find((item) => item.uid === model.uid);
        return foundModel?.thumbnails.images[3].url;
      });
  
      setModels(sortedModels);
      setModelsUrl(sortedModelsUrl);
      setModelsIconUrl(sortedModelsIconUrl);
      setModelsHDImageUrl(sortedModelsHDImageUrl);
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching model data: ", error);
      console.error(error);
        if (error.response && error.response.status === 403) {
            console.log("YOU SHOULD LOGIN AGAIN")
            // navigate('/login');
        }
      setIsLoading(false); // Finished loading (with error)
    }
  };

    const handleToggleListOpen = () =>{
        setIsListOpen((current) => !current)
    }

    const handleOpenDeleteDialog = (modelId) => {
        console.log("modelId")
        console.log(modelId)
        setSelectedModelId(modelId);
        setIsDeleteDialogOpen(true);
      };
      
    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteConfirm = (uid) => {
        // Perform the axios request and delete action for the selectedModelId
        console.log(uid); // Check if the selectedModelId is correctly set
    
        axios.post(
            apiUrl + `/portus/model/delete`, 
            {
                userid: auth.id,
                uid: uid,
            }, 
            {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id,
                //'ngrok-skip-browser-warning': 'true', 
            },
            }
        ).then((response) => {
                console.log(response);
                fetchData();
                setIsDeleteDialogOpen(false);
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 403) {
                    console.log("YOU SHOULD LOGIN AGAIN")
                    // navigate('/login');
                }
                fetchData();
                setIsDeleteDialogOpen(false);
            });
    };

    const handleRenameClick = (modelId) => {
        setSelectedModelId(modelId);
        setIsRenameDialogOpen(true);
    };

    const handleRenameConfirm = () => {
        // Perform the rename action here
        console.log(selectedModelId);
        console.log(newModelName); // Check if the new model name is correctly set
      
        // Make the API call to rename the model using selectedModelId and newModelName
        // Update the model name locally after successful rename
      
        /* AXIOS CHIAMATA RENAME */
        axios.post(apiUrl + '/forum/models/rename', {
            uid: selectedModelId,
            name: newModelName
        },{
            headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id,
            //'ngrok-skip-browser-warning': 'true', 
            },
        })
        .then(response => {
            console.log(response);   
            fetchData();     
            setIsRenameDialogOpen(false); 
        })
        .catch(error => {
            console.error(error);
            if (error.response && error.response.status === 403) {
                console.log("YOU SHOULD LOGIN AGAIN")
                // navigate('/login');
            }
        });
        
    };
      
    const handleRenameCancel = () => {
        setIsRenameDialogOpen(false);
    };

    return(
        <>
        <SidebarContainer>
            <Sidebar/>
        </SidebarContainer>
        <MetaBallBackgroundContainer>
            <BackgroundLoopContainer>
                <BackgroundLoop src={backloop}/>
            </BackgroundLoopContainer>
        </MetaBallBackgroundContainer>
        <ViewContainer>
            {isDeleteDialogOpen && (
            <Dialog
                onConfirm={() => handleDeleteConfirm(selectedModelId)}
                onCancel={handleDeleteCancel}
                title={"Elimina modello"}
            >
                Sei sicuro di voler eliminare questo modello?
            </Dialog>
            )}
            {isRenameDialogOpen && (
            <Dialog
                onConfirm={handleRenameConfirm}
                onCancel={handleRenameCancel}
                title="Rinomina modello"
            >
                <InputEditName
                type="text"
                value={newModelName}
                onChange={(event) => setNewModelName(event.target.value)}
                />
            </Dialog>
            )}
                <LeftWrapper>
                <LeftContent>
                    <MetaBallContainer autoPlay name="media" loop muted playsInline>
                        <source src={testmetaballvideo} type="video/webm" />
                    </MetaBallContainer>
                    Libreria
                    <LeftButtonUpload onClick={() => setIsUploading(true)}>
                        <ButtonImage src={uploadicon}></ButtonImage>
                        Carica Modello
                    </LeftButtonUpload>
                    {isUploading && <>
                        <Popup onClose={() => setIsUploading(false)}>
                            <UploadForm selectedUser={auth.id} selectedCollection={uid_collection}/>
                        </Popup>
                        </> }
                    <LeftButtonList onClick={handleToggleListOpen} >
                        <ButtonImage src={listicon}/>
                        Visualizza in lista
                    </LeftButtonList>
                    <LeftText>
                        {isMobile ? <>Consulta tutti i modelli che hai caricato e visualizzali come anteprima o in lista</> : <>Consulta tutti i modelli che hai caricato e visualizzali come anteprima o in lista.</>}                    </LeftText>
                </LeftContent>
            </LeftWrapper>
            <RightWrapper>
                {isLoading && 
                    <CircleLoader/>
                }
                {!isLoading && (!models || models.length === 0) && 
                    <EmtpyContainer>Al momento non sono presenti modelli sulla piattaforma. Che ne dici di caricare il primo?</EmtpyContainer>}
                {!isLoading && models && models.length > 0 && selectedModel === null && (
                    <ContentWrapper>
                        {models.map((model, key) => (
                            <ModelPanel 
                            model={model} 
                            models={models}
                            key={key} 
                            modelNumber={key} 
                            modelsUrl={modelsUrl[key]} 
                            modelHDImageUrl={modelsHDImageUrl[key]} 
                            handleOpenDeleteDialog={handleOpenDeleteDialog}
                            handleRenameClick={handleRenameClick}
                            />
                        ))}
                    </ContentWrapper>
                )}
                {isListOpen &&
                <ListContainer isOpen={isListOpen}>
                    <ListWrapper>
                        <ListHeader>
                            <ListFirstRow>
                                <ListTitle>Visualizza in lista</ListTitle>
                                <ListCloseButton src={closeicon} onClick={() => setIsListOpen(false)}/>
                            </ListFirstRow>
                            <ListSearchContainer>
                                <ListSearchIcon src={searchicon}></ListSearchIcon>
                                <ListSearchTextInput 
                                    onChange={(e) => setSearchText(e.target.value)}
                                    value={searchText}
                                    />
                            </ListSearchContainer>
                        </ListHeader>
                        <ListContent>
                            {models
                            .map((model, index) => ({ model, index })) // Associate each model with its index
                            .filter(({ model }) => model.sfDoc.name.toLowerCase().includes(searchText.toLowerCase()))
                            .map(({ model, index }) => (
                                <ModelList 
                                    key={index} 
                                    models={models}
                                    model={model} 
                                    modelNumber={index} 
                                    modelsUrl={modelsUrl[index]} 
                                    modelHDImageUrl={modelsHDImageUrl[index]} 
                                    modelsIconUrl={modelsIconUrl[index]}
                                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                                    handleRenameClick={handleRenameClick}
                                />
                            ))
                            }
                        </ListContent>
                    </ListWrapper>
                </ListContainer>
                }
            </RightWrapper>
            
        </ViewContainer>
        </>
    )
}

export default Dashboard;