import React, {useState} from 'react';
import axios from 'axios';

import {ButtonUpload,DragAndDrop,ErrorContent,ErrorWrapper,UploadWindowContainer,UploadWindowWrapper,
    WindowContainer,WindowFooter,WindowHeader,UploadAreaWrapper,DragAndDropFirstRow,
    DragAndDropSecondRow,InputFile,InputFileLabel,WindowDescription} from './uploadlogoformElements.js';
import useAuth from '../../hooks/useAuth.js';

const UploadLogoForm = (props) => {

    const [error, setError] = useState(null);
    const [textareaText, setTextareaText] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const {auth} = useAuth();

    //const [progress, setProgress] = useState(null);
    if(props.selectedUser){
        console.log("vengo da DBA");
        //setDbaselectedUser(props.selectedUser);
    }

    // check se sono DBA sennò uso il mio UID nel Session Storage
    console.log("props: ", props);
    if(props.selectedUser != null){
        console.log("selected user non è null: ", props.dbaSelectedUser);
    }
    else {
        console.log("selected user è null", props.dbaSelectedUser)
    }

    // Inserire API URL in secretapi
    //const secretapi = "https://test2.3dabackend.com";
    const secretapi = `${process.env.REACT_APP_API_BASE_URL}`;

    const handleDrop = async (e) => {
        if (e.preventDefault) {
            e.preventDefault();
        }
        const file = e.dataTransfer.files[0];
        //const reader = new FileReader();

        const allowedExtensions = ["png","jpg","jpeg"];

         // check to ensure that file is defined
        if (!e.dataTransfer.files[0]) {
            return;
        }

        const fileExtension = file.name.split('.').pop();
        console.log("estensione: "+ fileExtension);
        if (!(allowedExtensions.includes(fileExtension.toLowerCase()))) {
            // If file extension is not allowed, show error message and return
            console.log('File type not allowed');
            setError("File type not allowed");
            return;
        }
        
        // RIMUOVERE FILES E METTERE FILE SE NECESSARIO e tutto si avvierà al primo file inserito.
        // se tutti i file sono ok allora fetch axios
        try {
            const formData = new FormData();
            formData.append('default', false);
            formData.append('logo', file);

            // se sono DBA sono arrivato su questo componente passandomi l'id dell'utente
            // a cui voglio assegnare questo modello.
            if(props.selectedUser === null){  
                formData.append("idUtente",props.dbaSelectedUser)
            }
            else {
                formData.append("idUtente",props.dbaSelectedUser)
            }

            /*
            SE RIUSCIAMO A METTERE LOADING + aggiungere campo config dopo formData nella await dopo.

            const config = {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                },
            };
            */

            // PORTUS MICROSERVICE
            console.log(props.dbaSelectedUser)
            // qui fetcho con axios
            const response = await axios.post(secretapi + "/forum/ftp/logo/upload", formData, { headers: {
                'Authorization': `Bearer ${auth?.accessToken}`,
                'id' : auth.id,
                "Content-Type":"multipart/form-data"
            }})
            .then(response => {
                if(response.data?.success){
                    console.log("success response: ", response);
                    // success e torna.
                    setIsSuccess(true);
                    props.onSuccess(true);
                }
                if(!response.data?.success){
                    console.log("not success response: ", response);
                    // not success e pulisci.
                    setTextareaText("Error");
                } else {
                    console.log("not success and not 200");
                }
            }).catch(error => {
                console.log(error);
            });
            console.log(response?.data);
            setTextareaText("Uploading, Please Wait");
          } catch (error) {
            setTextareaText("Error: ", error);
            console.error(error);
          }
    };

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        const allowedExtensions = ["png","jpg","jpeg"];

        const fileExtension = file.name.split('.').pop();
        console.log("estensione: "+ fileExtension);
        if (!(allowedExtensions.includes(fileExtension.toLowerCase()))) {
            // If file extension is not allowed, show error message and return
            console.log('File type not allowed');
            return;
        }

        setSelectedFile(file);
      
        handleDrop({dataTransfer: {files:[file]}});

      };

    const handleUploadClick = () => {
        if (selectedFile) {
          handleDrop({ dataTransfer: { files: selectedFile } });
        }
      };

    return(
        <UploadWindowContainer>
            <UploadWindowWrapper>
                
                { isSuccess ? (<> Immagine caricata con successo </>) : (
                <>
                <WindowDescription>Carica una nuova immagine del profilo {props.selectedUser} {props.idLogoChange}</WindowDescription>
                <WindowContainer>
                    { !uploading ? (
                        <>
                            <UploadAreaWrapper>
                                <DragAndDrop onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} disabled={uploading}>
                                <DragAndDropFirstRow>Trascina il modello</DragAndDropFirstRow> 
                                <DragAndDropSecondRow>oppure</DragAndDropSecondRow> 
                                <InputFile type="file" name="fileinput" id="fileinput" onChange={handleFileSelect} disabled={uploading} />
                                <InputFileLabel htmlFor="fileinput">Carica File</InputFileLabel>
                                {error}
                                </DragAndDrop>
                            </UploadAreaWrapper>
                        </>
                    ) : (
                        <> 
                            Uploading 
                        </>
                    )}
                    <ErrorWrapper>
                        <ErrorContent></ErrorContent>
                    </ErrorWrapper>
                </WindowContainer>
                </>
                )}
            </UploadWindowWrapper>
        </UploadWindowContainer>
    )
}

export default UploadLogoForm;