import styled from 'styled-components';

export const ModelPageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
`

export const ContentWrapper = styled.div`
height: 456px;
width: 720px;
background: #111111;
color: #eeeeee;
display: flex;
flex-direction: column;
align-content: center;
align-items: center;
border-radius: 16px;
margin: 16px;
cursor: pointer;
`

export const ThumbnailWrapper = styled.div`
width: 256px;
height: 144px;
`

export const ThumbnailContent = styled.img`
border-radius: 8px 8px 0 0;
`  

export const TitleWrapper = styled.div`
display: flex;
flex-direction: row;
padding: 8px 0px;
height:22px;
`

export const TitleContent = styled.div`
height: 22px;
background: #222;
width: 200px;
border-radius: 11px;
word-break: break-word;
overflow: hidden;
font-size: 14px;
font-weight: bold;
`

export const ButtonWrapper = styled.div`
display:flex;
flex-direction: row;
flex-wrap: nowrap;
align-content: center;
justify-content: space-evenly;
align-items: center;
width: 100%;
`

export const OutputTestButton = styled.button`
width: 78px;
height: 28px;
font-size: 11px;
font-weight: bold;
text-transform: uppercase;
border-radius: 4px;
border: none;
cursor: pointer;
`

export const OutputProdButton = styled.button`
width: 78px;
height: 28px;
font-size: 11px;
font-weight: bold;
text-transform: uppercase;
border-radius: 4px;
border: none;
cursor: pointer;
`

export const AnalyticsButton = styled.button`
width: 78px;
height: 28px;
font-size: 11px;
font-weight: bold;
text-transform: uppercase;
border-radius: 4px;
border: none;
cursor: pointer;
`
