import React from 'react';
import {ModalOverlay,ModalContent,ModalTitle,ModalButtons} from './confirmpopupElements';

const ConfirmPopup = (props) => {
    const {isOpen, onClose, onConfirm, onCancel, message} = props;
    console.log("popup")

    const handleConfirm = () => {
        onConfirm();
        onClose();
    }

    const handleCancel = () => {
        onCancel();
        onClose();
    }

    return(
        <>
            {isOpen &&
                <ModalOverlay>
                    <ModalContent>
                        <ModalTitle>{message}</ModalTitle>
                        <ModalButtons>
                            <button onClick={handleCancel}>Cancel</button>
                            <button onClick={handleConfirm}>Confirm</button>
                        </ModalButtons>
                    </ModalContent>
                </ModalOverlay>
            }
        </>
    )
}

export default ConfirmPopup;