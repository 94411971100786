import React, { useEffect, useRef, useState } from 'react';
import h337 from 'heatmap.js';

const HeatmapView = ({ heatmapData, parentRef }) => {
  const heatmapRef = useRef(null);
  const [heatmapInstance, setHeatmapInstance] = useState(null);

  const max = heatmapData && heatmapData.data ? Math.max(...heatmapData.data.map(d => d.value)) : 0;

  console.log("heatmapData inside HeatmapView: ", heatmapData);
  console.log(heatmapData);

  const logTransformedData = heatmapData.data.map(d => ({
    ...d,
    value: Math.log(d.value + 1)  // Aggiungi 1 per evitare log(0)
  }));
  const threshold = 0.5 * max;  // Ad esempio, considera solo i dati che hanno un valore superiore al 50% del valore massimo
  const filteredData = heatmapData.data.filter(d => d.value > threshold);


  console.log("logTransformedData:    " ,logTransformedData);

  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, wait);
    };
  }

  {/*
    PER SCALARE I DATI IN FUTURO

    const originalWidth = 1920;  // Example original width
    const originalHeight = 1080; // Example original height

    const widthScale = heatmapRef.current.offsetWidth / originalWidth;
    const heightScale = heatmapRef.current.offsetHeight / originalHeight;

    const scaledData = logTransformedData.map(d => ({
        ...d,
        x: d.x * widthScale,
        y: d.y * heightScale
    }));
  */}

  useEffect(() => {
    if (heatmapRef.current && !heatmapInstance) {
      const instance = h337.create({
        container: heatmapRef.current,
        radius: 25,
        gradient: {
          0.25: "blue",
          0.5: "green",
          0.75: "yellow",
          1.0: "orange"
        },
        minOpacity: 0.1,
        maxOpacity: 0.4
        
      });
      setHeatmapInstance(instance);
    }
  }, [heatmapInstance]);

  useEffect(() => {
    console.log("setting heatmap data");
    if (heatmapInstance && heatmapData && heatmapData.data) {
      heatmapInstance.setData({
        max: Math.max(...filteredData.map(d => d.value)),
        data: logTransformedData,
        minOpacity: 0.1,
        maxOpacity: 0.4
      });
      console.log("Current heatmap instance:", heatmapInstance);
      
      // Create a ResizeObserver to detect container size changes
      const resizeObserver = new ResizeObserver(debounce(() => {
        console.log("Container dimensions:", heatmapRef.current.offsetWidth, heatmapRef.current.offsetHeight);
        console.log("heatmapData at resize:", heatmapData);
        console.log("heatmapInstance at resize:", heatmapInstance);
      
        if (heatmapRef.current.offsetWidth > 0 && heatmapRef.current.offsetHeight > 0) {
          try {
            heatmapInstance.setData({ max: 0, data: [] });
            console.log("Heatmap data cleared");
        
            heatmapInstance.addData(heatmapData.data);
            console.log("Heatmap data re-added");
          } catch (error) {
            console.error("Error during resize:", error);
          }
        }
      }, 150));  // 150ms debounce time
    
      
      // Start observing the heatmap container
      resizeObserver.observe(heatmapRef.current);

      // Cleanup observer on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [heatmapInstance, heatmapData]);

  return (
    <div ref={heatmapRef} style={{ flex: '1', height: '100%', width: '100%' }} />
  );
};

export default HeatmapView;
