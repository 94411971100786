import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();


    console.log("auth.roles " + auth?.roles);
    console.log("auth.user " + auth?.user);
    console.log("auth.id " + auth?.id);

    if (auth.loading) {
            return <div>Loading...</div>;
    }

    return (
        allowedRoles?.includes(auth?.roles)
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;