import styled from "styled-components";

export const ParentContainer = styled.div`
    width: 100%;
    height: 100%;
`

export const IFrame = styled.iframe`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    border: 0;
`

export const ButtonClose = styled.img`
    position: absolute;
    right: 48px;
    top: 48px;
    height: 24px;
    width: 24px;
    font-size: 16px;
    border-radius: 50%;
    cursor: pointer;
` 

export const AnnotationsContainer = styled.div`
    width: 100%;
    height: 100%;
    background: transparent ;
    user-select: none;
    position: absolute;
    top: 0px;
    overflow: hidden;
`

export const AnnotationElementContainer = styled.div`    
    width: fit-content;
    padding: 0 8px;
    height: 32px;
    background: rgb(255, 255, 255,0.75);
    border-radius: 16px;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    gap: 4px;
    font-weight: 500;

    &:hover{
        background: rgb(255, 255, 255, 1);
    }
`

export const AnnotationTitle = styled.div`
    font-size: 1rem;
`

export const AnnotationNumber = styled.div`
    font-size: 1rem;
`

export const DetailWrapper = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
`

export const DetailContainer = styled.div`
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    background: white;
    display: flex;
    flex-direction: column;
    
    transform-origin: center center; 
    transition: transform 0.5s ease-in-out; 
    transform: scale(${(props) => (props.visible ? '1' : '0')});
`

export const DetailTitle = styled.div`
    background-color: rgb(30, 41, 59);
    color: rgb(238, 238, 238);
    padding: 8px;
    font-size: 1rem;
    font-weight: 500;
`

export const DetailBody = styled.div`
    padding: 8px;
    overflow-y: auto;
    scrollbar-width: auto;
    scrollbar-color: #dddddd #ffffff;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 10px;
        border: 2px solid #ffffff;
    }
  
    flex-grow: 1;

    & img {
        max-width: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }

    & iframe {
        max-height: -webkit-fill-available;
    }
`