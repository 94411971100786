import styled from 'styled-components';

export const BoxContainer = styled.div`
  height: 172px;
  width: 200px;
  background: 
    linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.20));
  //padding: 6px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s linear;
  backdrop-filter: blur(32px);

  &:hover {
    padding: 0px;
  }

  &:hover img { 
    //transform: scale(1.1);
  }
`
export const ContentWrapper = styled.div`    
    height: calc(100% - 12px);
    width: calc(100% - 12px);
    margin: 6px;
    border-radius: 8px;
    color: #eeeeee;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: 0.2s linear;

    ${BoxContainer}:hover & {
        height: calc(100% - 0px);
        width: calc(100% - 0px);
        margin: 0px;
    }
`

export const ThumbnailWrapper = styled.div`
    height: 170px;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
`

export const ThumbnailContent = styled.img`
    height: 170px;
    min-width: 200px;
    border-radius: 8px 8px 0 0;
    transition: transform .5s ease;
    transform-origin: center center; // Specify the transform origin
    z-index: 1;
    ${BoxContainer}:hover & { 
      transform: scale(1.05);
    }
`;

export const GradientOverlayContent = styled.div`
    position: absolute;
    height: 198px;
    width: 200px;
    background: linear-gradient(0deg,rgba(0,0,0,0.2),rgba(0,0,0,0.2));
    border-radius:12px;
    z-index: 2;
    opacity: 1;    
    transition: all .5s ease;

    ${BoxContainer}:hover & {
        background: linear-gradient(0deg,rgba(0,0,0,0.5),transparent,transparent,transparent,transparent,rgba(0,0,0,0.5));
    }
`

export const TitleWrapper = styled.div`
    position: absolute;
    content: '';
    margin-top: 124px;
    padding: 8px 0px;
    width: 180px;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    z-index: 3;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
`

export const TitleContent = styled.div`
    height: 22px;
    word-break: break-word;
    overflow: hidden;
    font-size: 12px;
    line-height: 15px;
`