import styled from 'styled-components';

export const SidebarContainer = styled.div`
    height: 72px;
    width: 100%;
    background: transparent;
    color: #eeeeee;
display: flex;
`   

export const ViewContainer = styled.div`
  height: calc(100% - 72px);
  width: 100%;
  background: transparent;
  //background: linear-gradient(60deg, #2c287b, #773824);
  color: #eeeeee;
  display: flex;
  flex-direction: column;
  z-index: 1;
`
export const HeaderBarContainer = styled.div`
    border-bottom: 2px solid #1E293B;
`

export const HeaderBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 36px 0px 0px 64px;
    gap: 16px;
`

export const HeaderTitle = styled.div`
    font-weight: bold;
    font-size: 40px;
`

export const HeaderTabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 40px;
    gap: 8px;
`

export const HeaderTabElement = styled.div`
    background: ${({ isActive }) => (isActive ? '#1E293B' : 'transparent')};
    border-radius: 8px 8px 0px 0px;
    color: ${({ isActive }) => (isActive ? '#F8FAFC' : '#334155')};
    padding: 10px 12px;
    cursor: pointer;
`

export const PanelTitle = styled.div`


`

export const PanelContent = styled.div`
  border-radius: 16px;
  padding: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const PanelLineContent = styled.div`
  border-radius: 16px;
  /*padding: 12px;*/
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  & canvas {
    
    aspect-ratio: auto;
  }
`


export const BodyContainer = styled.div`
  display:flex;
  color: #F8FAFC;
  flex-grow: 1;    
  align-items: center;
  padding: 40px 64px 40px 64px;
  height: calc(100% - 225px);
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  /*height: 100%;*/
  width: 100%;
  color: #F8FAFC;
  overflow-y: auto;

  @media (max-width: 1023px) {
    /*display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;*/
  grid-template-columns: 1fr;
    height: 100%;
  }
`

export const FirstSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 24px;
  height: 100%;
      
  @media (max-width: 1023px) {
    /*display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
    height: 100%;
    flex-wrap: wrap;*/
  }
`;

export const SecondSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 24px;
  height: 100%;
  
  @media (max-width: 1023px) {
    /*display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;*/
  }
`;

export const CenterWrapper = styled.div`
  display: flex;    
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`

export const CenterContainer = styled.div`
  display: flex;    
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`

export const LargestPanel = styled.div`
  border: 1px solid rgba(255,255,255,0.08);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.08));
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
    display: flex;
    align-content: center;
    justify-content: center;
`;

export const SmallestPanel = styled.div`
  border: 1px solid rgba(255,255,255,0.08);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  background: 
    linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.08));
  box-sizing: border-box;
    display: flex;
    align-content: center;
    justify-content: center;
`;

export const SmallPanel = styled.div`
  border-radius: 16px;
  background-color: #222;
    display: flex;
    align-content: center;
    justify-content: center;
`;

export const LargePanel = styled.div`
  border: 1px solid rgba(255,255,255,0.08);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.08));
    display: flex;
    align-content: center;
    justify-content: center;
`;

export const ColumnPanel = styled.div`
  border: 1px solid rgba(255,255,255,0.08);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.08));
  grid-row: 1 / span 3;
  flex-grow: 1;    
  display: flex;
  justify-content: center;
  align-content: center;
  min-width: 300px;
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const ColumnContainer = styled.div`
  display: grid;
  gap: 24px;
  flex-direction: column;
  grid-row: 1 / span 3;

    
  @media (max-width: 1023px) {
    /*display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    flex-wrap: wrap;*/
  }
`;

export const PanelWrapper = styled.div`
  display: flex;
  backdrop-filter: blur;
  background: rgba(30, 41, 59, 0.4);
  flex-grow: 1;
  margin: 8px;
  border-radius: 8px;
`;

export const IconContainer = styled.img`
  padding: 16px 0;
  width: 36px;
  height: 36px;
`;

export const DescriptionContainer = styled.div`
  font-size: 12px;
  color: #94A3B8;
  text-transform: uppercase;
`;

export const ValueContainer = styled.div`
font-size: 28px;
color: #F8FAFC;
font-weight: bold;
`;

export const LargePanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-content: center;
  align-items: center;
  margin: -12px;
  border-radius: 8px;
  overflow: hidden;
`;

export const LeftPanelColumn = styled.div`
  display: flex;
  flex-grow: 1;
  background: #ddd;
    height: 100%;
    max-width: 160px;
    width: 50%;
    overflow: hidden;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
`;

export const RightPanelColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
`;

export const LargePanelName = styled.div`
  color: #94A3B8;
  font-size: 16px;
`;

export const LargePanelDescription = styled.div`
  font-size: 14px;
`;

export const LargePanelValueRow = styled.div`
  display: flex;
  flex-direction: row;
    gap: 8px;
`;

export const LargePanelValueNumber = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const LargePanelValueName = styled.div`
  font-size: 12px;
  color: #94A3B8;
`;

export const LargeImagePanel = styled.img`
  min-width: 72px;
    flex-grow: 1;
    height: 180px;
`;

export const ModelBoxContainer = styled.div`
  display: flex;
  gap: 24px;
    flex-wrap: wrap;
    grid-column: 1 / span 2;
    align-content: flex-start;
`;

export const AnalyticsDetailContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column: 1 / span 2;  
  position: relative;
  gap: 16px;
  height: 100%;
  width: 100%;
    align-items: center;
    align-content: center;
`;

export const DetailFirstSectionContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;  
  gap: 4px;
`;

export const SketchFabFrameContainer = styled.div`
  display: flex;
  flex-grow: 1;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    height: calc(100% - 28px);
`;

export const SecondSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  flex-grow: 1;
  gap: 16px;
`;

export const DetailSecondSectionContainer = styled.div`
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
}
`;

export const HeatmapContainer = styled.div`
    position: absolute;
    top: 28px;
    left: 0;
    border: none;
    width: 100%;
    height: calc(100% - 28px);
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const HeatmapWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export const HeatmapButtonContainer = styled.div`
  display: flex;
    z-index: 12;
    position: absolute;
    bottom: 0;
    right: 0;
    align-items: center;
    color: #2b2b2b;
    font-weight: bold;
`;

export const HeatmapToggle = styled.div`
  display: flex;
  background-color: #2b2b2b;
  padding: 0 8px;
  gap: 8px;
  height: 32px;
  margin: 8px;
  border-radius: 12px;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: #F8FAFC;
  font-weight: normal;
`;

export const HeatmapView = styled.div`
  position: relative;
  width: 500px;
  height: 500px;
`

export const ThumbnailContent = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;

`;

export const ViewsChartContainer  = styled.div`
border: 1px solid rgba(255,255,255,0.08);
border-radius: 16px;
backdrop-filter: blur(32px);
background: linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.08));
grid-column: 1 / span 2;
grid-row: 1 / span 2;
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: 240px;
`;

export const BackButton = styled.div` 
  height: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-content: center;
  align-items: center;
  z-index: 9999;
`

export const BackButtonIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`