import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/landingpage';
import Login from './components/login';
import Dashboard from './components/dashboard/index.js';
import Profile from './components/profile/index.js';
import UploadPage from './components/uploadpage/index.js';
import Analytics from './components/analytics/index.js';
import AnalyticsNew from './components/analytics/indexnew.js';
import DBA from './components/dba/index.js'
import ConfigurationPage from './components/configurationpage/index.js';
import ConfigurationTest from './components/configurationtest';
import SketchfabViewer from './components/annotationstest';
import SceneTest from './components/scenetest';
import Missing from './components/missing/index.js';
import RequireAuth from './RequireAuth.js';
import './App.css';
import Layout from './Layout.js';
import OutputPreview from './components/outputpreview';

function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Default route */}
        <Route path="/" element={<LandingPage/>} />

        {/* Public routes */}
        <Route path='login' element={<Login />} />
        <Route path='configurations' element={<ConfigurationPage />} />

        {/* Protected routes */}
        <Route element={<RequireAuth  allowedRoles={[1,2]}/>}>
          <Route path='/dashboard' element={<Dashboard  />} />
        </Route>
        <Route element={<RequireAuth  allowedRoles={[1,2]}/>}>
          <Route path='/upload' element={<UploadPage />} />
        </Route>
        <Route element={<RequireAuth  allowedRoles={[1,2]}/>}>
          <Route path='/profile' element={<Profile />} />
        </Route>
        <Route element={<RequireAuth  allowedRoles={[1,2]}/>}>
          <Route path='/analytics' element={<AnalyticsNew />} />
        </Route>
        <Route element={<RequireAuth  allowedRoles={[1,2]}/>}>
          <Route path='/analyticsold' element={<Analytics />} />
        </Route>
        <Route element={<RequireAuth  allowedRoles={[1,2]}/>}>
          <Route path='/annotationstest' element={<SketchfabViewer />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
          <Route path='/output/:authId/:modelUid/:mode/:modelName' element={<OutputPreview />} />
        </Route>
        <Route element={<RequireAuth  allowedRoles={[3]}/>}>
          <Route path='/dba' element={<DBA />} />
        </Route>
        
        <Route element={<RequireAuth  allowedRoles={[1,2,3]}/>}>
          <Route path='/configurationtest' element={<ConfigurationTest />} />
        </Route>
        <Route element={<RequireAuth  allowedRoles={[1,2,3]}/>}>
          <Route path='/scenetest' element={<SceneTest />} />
        </Route>

        {/* Catch all 
        <Route path="*" element={<Missing/>} />
        */}
      </Route>
    </Routes> 
  );
}

export default App;