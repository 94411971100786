import React, {useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';
import AnalyticsDetail from '../analyticsdetail/index.js';
import Sidebar from '../sidebar/index.js';
import { SidebarContainer, ViewContainer, HeaderBarContainer, FirstSection, SecondSection, 
        SmallPanel, HeaderBarWrapper,
        PanelTitle, PanelContent, HeaderTabsContainer, HeaderTabElement, HeaderTitle, BodyContainer, 
        TwoThirdColumn, LargePanel, LargestPanel, OneThirdColumn, HalfColumn, ColumnPanel,
        SmallestPanel, RowContainer, ColumnContainer,PanelWrapper,DescriptionContainer,
        IconContainer,ValueContainer, LargePanelDescriptionm, LargePanelDescription,
        LargePanelName, LargePanelValueName,LargePanelValueNumber,LargePanelValueRow,LeftPanelColumn,
        RightPanelColumn, LargeImagePanel,LargePanelWrapper, ModelBoxContainer,AnalyticsDetailContainer,
        DetailFirstSectionContainer,SecondSectionWrapper,SketchFabFrameContainer,DetailSecondSectionContainer,
        HeatmapButtonContainer,HeatmapContainer,HeatmapToggle,HeatmapWrapper,ThumbnailContent,ViewsChartContainer,
    BackButton,BackButtonIcon,ContentWrapper,CenterWrapper } from './analyticsElements.js';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import useAuth from '../../hooks/useAuth.js';
import axios from 'axios';
import h337 from 'heatmap.js';
import ModelBox from '../modelbox/index.js';

import backicon from '../../img/back24icon.png'
import analyticsaricon from '../../img/analyticsar36icon.png'
import analyticsvricon from '../../img/analyticsvr36icon.png'
import analyticsinteractionicon from '../../img/analyticsinteraction36icon.png'
import analyticstimeicon from '../../img/analyticstime36icon.png'
import analyticsviewsicon from '../../img/analyticsviews36icon.png' 
import HeatmapView from '../heatmapview/index.js';

let cancelTokenHeatmapSource;

const Analytics = (props) => {
    //const apiUrl = 'https://test2.3dabackend.com';
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`
    const apiGetModels = apiUrl + "/forum/user/models/";
    const SFtoken = '409eb8f52291eaf0c2c824c916d6b5af';
    const { auth } = useAuth();
    const { setAuth } = useAuth();
    const location = useLocation();
    const [models, setModels] = useState(null);
    const [modelsUrl, setModelsUrl] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedI, setSelectedI] = useState(null);
    const [uid_collection, setUid_collection] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const heatmapRef = useRef(null);
    const parentRef = useRef(null);
    const [heatmapInstance, setHeatmapInstance] = useState(null);
    const [heatmapVisible, setHeatmapVisible] = useState(true);
    const [heatmapData, setHeatmapData] = useState(null);
    const [selectedTab, SetSelectedTab] = useState('1');
    const [selectedModelHDImage, SetSelectedModelHDImage] = useState(null)
    const [ModelsIconUrl, setModelsIconUrl] = useState(null);
    const [ModelsHDImageUrl,setModelsHDImageUrl] = useState(null);
    const [modelHDUrl, setModelHDUrl] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [fromModelPanel, setFromModelPanel] = useState(false);
    const [chartBarLoaded, setChartBarLoaded] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);
    const [modelchartBarLoaded, setModelChartBarLoaded] = useState(false);
    const [modelchartData, setModelChartData] = useState(null);
    const [modelchartOptions, setModelChartOptions] = useState(null);
    const [opacity, setOpacity] = useState(1); 

    const [MaxInteractionsModel, setMaxInteractionsModel] = useState("loading");
    const [MaxViewsModel, setMaxViewsModel] = useState("loading");
    const [MaxViewsModelAr, setMaxViewsModelAr] = useState("loading");
    const [MaxViewsModelVr, setMaxViewsModelVr] = useState("loading");
    const [MeanVisualizationTime, setMeanVisualizationTime] = useState("loading");
    const [TotViews, setTotViews] = useState("loading");
    const [TotViewsAr, setTotViewsAr] = useState("loading");
    const [TotViewsVr, setTotViewsVr] = useState("loading");
    const [TotInteractions, setTotInteractions] = useState("loading");

    const [singlemodelSumElapsedTime, setSinglemodelSumElapsedTime] = useState(0);
    const [singlemodelAvgElapsedTime, setSinglemodelAvgElapsedTime] = useState(0);
    const [singlemodelInteractions, setSinglemodelInteractions] = useState(0);
    const [singlemodelViews, setSinglemodelViews] = useState(0);
    const [singlemodelViewsAr, setSinglemodelViewsAr] = useState(0);
    const [singlemodelViewsVr, setSinglemodelViewsVr] = useState(0);
    const [singlemodelViewsID, setSinglemodelViewsID] = useState(0);
    const [singlemodelViewsArID, setSinglemodelViewsArID] = useState(0);
    const [singlemodelViewsVrID, setSinglemodelViewsVrID] = useState(0);
    const [singlemodelViewsElement, setSinglemodelViewsElement] = useState(null);
    const [singlemodelViewsElementIndex, setSinglemodelViewsElementIndex] = useState(null);
    const [singlemodelViewsArElement, setSinglemodelViewsArElement] = useState(null);
    const [singlemodelViewsArElementIndex, setSinglemodelViewsArElementIndex] = useState(null);
    const [singlemodelViewsVrElement, setSinglemodelViewsVrElement] = useState(null);
    const [singlemodelViewsVrElementIndex, setSinglemodelViewsVrElementIndex] = useState(null);
    const [singlemodelAvgLoadTime, setSinglemodelAvgLoadTime] = useState(0);
    const [singlemodelAvgInteractions, setSinglemodelAvgInteractions] = useState(0);
    const [noAnalytics, setNoAnalytics] = useState(false);

    const [analyticsResponse, setAnalyticsResponse] = useState(null);
    const [maxViewsModelDetails, setMaxViewsModelDetails] = useState(null);
    const [maxViewsModelArDetails, setMaxViewsModelArDetails] = useState(null);
    const [maxViewsModelVrDetails, setMaxViewsModelVrDetails] = useState(null);

    const SmallestPanelData = [
        { icon: analyticsviewsicon, description: "Visualizzazioni Totali", value: singlemodelViews || 0 },
        { icon: analyticsaricon, description: "Visualizzazioni Totali AR", value: singlemodelViewsAr || 0 },
        { icon: analyticsvricon, description: "Visualizzazioni Totali VR", value: singlemodelViewsVr || 0 },
        { icon: analyticstimeicon, description: "Tempo medio di caricamento", value: (singlemodelAvgLoadTime/1000).toFixed(2) || 0 },
        { icon: analyticsinteractionicon, description: "Interazioni Totali", value: singlemodelInteractions || 0 },
        { icon: analyticsinteractionicon, description: "Interazioni medie", value: (singlemodelInteractions/singlemodelViews) || 0 },
        { icon: analyticstimeicon, description: "Tempo totale di visualizzazione", value: ((singlemodelSumElapsedTime / 1000).toFixed(2)) || 0 },
        { icon: analyticstimeicon, description: "Tempo medio di visualizzazione", value: (((singlemodelSumElapsedTime/singlemodelViews)/1000).toFixed(2)) || 0 }
    ];

    const fetchMonthlyViews = async () => {
        console.log("getChartData 3")
        const currentYear = new Date().getFullYear();
        const monthlyViews = [];
    
        for (let month = 0; month < 12; month++) {
            const startDate = new Date(currentYear, month, 1).toISOString();
            const endDate = new Date(currentYear, month + 1, 0).toISOString();
            console.log("getChartData : ", startDate);
            try {
                const response = await axios.post(apiUrl + "/tacito/analytics", {
                    id_utente: auth.id,
                    start_date: startDate,
                    end_date: endDate,
                }, {
                    headers: {
                        'Authorization': `Bearer ${auth?.accessToken}`,
                        'id': auth.id,
                    }
                });
    
                if (response.data && response.data.data && response.data.data.vis_tot) {
                    monthlyViews.push(response.data.data.vis_tot?.sum_views || 0);
                } else {
                    monthlyViews.push(0);
                }
            } catch (error) {
                console.error(`Error fetching views for month ${month + 1}:`, error);
                monthlyViews.push(0);
            }
        }
    
        return monthlyViews;
    }
    
    // Usage
    const getChartData = async () => {
        console.log("getChartData 2")
        const monthlyData = await fetchMonthlyViews();
    
        const data = {
            labels: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
            datasets: [
                {
                    label: 'Visualizzazioni per mese',
                    data: monthlyData,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }
            ]
        };
    
        const options = {
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };
    
        setChartData(data);
        setChartOptions(options);
        setChartBarLoaded(true)
    }

    useEffect(() => {
        console.log("getChartData 1")
        getChartData();
    }, []);

    const fetchMonthlyModelViews = async (selectedModelId) => {
        console.log("getmodelChartData 3")
        const monthlyModelViews = [];
        const currentYear = new Date().getFullYear();
    
        for (let month = 0; month < 12; month++) {
            const startDate = new Date(currentYear, month, 1).toISOString();
            const endDate = new Date(currentYear, month + 1, 0).toISOString(); // Last day of the month
    
            try {
                const response = await axios.post(apiUrl + "/tacito/model/sum", {
                    id_utente: auth.id,
                    start_date: startDate,
                    end_date: endDate,
                    id_modello: selectedModel._id,
                    fields: {
                        /*start_date: startDate,
                        end_date: endDate,
                        id_utente: auth.id,*/
                        id_modello: selectedModel._id
                    }}, {
                        headers: {
                            'Authorization': `Bearer ${auth?.accessToken}`,
                            'id': auth.id,
                        }
                    }
                );
                console.log("/tacito/model/sum singolo mese " + month +": ",response.data);
    
                if (response.data && response.data.data) {
                    monthlyModelViews.push(response.data.data?.sum_views);
                } else {
                    monthlyModelViews.push(0);
                }
            } catch (error) {
                console.error(`Error fetching views for month ${month + 1} for model ${selectedModelId}:`, error);
                monthlyModelViews.push(0);
            }
        }
    
        return monthlyModelViews;
    }

    // Usage
    const getModelChartData = async () => {
        console.log("getChartData 2")
        const monthlyData = await fetchMonthlyModelViews();
    
        const data = {
            labels: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
            datasets: [
                {
                    label: 'Visualizzazioni per mese',
                    data: monthlyData,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }
            ]
        };
    
        const options = {
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };
    
        setModelChartData(data);
        setModelChartOptions(options);
        setModelChartBarLoaded(true)
    }
    
    const RenderSmallestPanel = ({ icon, description, value }) => (
        <SmallestPanel>
            <PanelWrapper>
                <PanelContent>
                    <IconContainer src={icon} />
                    <DescriptionContainer>{description}</DescriptionContainer>
                    <ValueContainer>{value}</ValueContainer>
                </PanelContent>
            </PanelWrapper>
        </SmallestPanel>
    )

    const fetchData = async () => {

        try{
            const id = auth.id;
            const response = await axios.post(apiGetModels, { idUtente: id } ,{
                    headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id, }
                }
            );
            // quando non ci sono modelli sarà comunque success : true
            // ma nel message ci sarà empty : true / false

            // se c'è errore success : false
            console.log("apigetmodels response");
            console.log(response);
            /*
            if(location.state && location.state.models){
                setModels(location.state.models);
            } else {
                setModels(response.data.message.modelli);
            }
            */

            setModels(response.data.message.modelli)

            
            if ( response.data.message !== null){

                console.log("fetchData models ", models);
                console.log("fetchData models ", response.data.message.modelli);
                console.log("fetchData response ",response);

                console.log("fetchModelData");
                // await fetchModelData(response.data.message.modelli);
                {/*
                const collectionUrl = response.data.message.collection;
                const splitUrl = collectionUrl.split('/');
                const collectionuid = splitUrl[splitUrl.length - 1];
                setUid_collection(collectionuid);
                console.log(collectionuid)
            setAuth(prevAuth => ({ ...prevAuth, collectionuid }));*/}
            } else {
                console.log(response);
            }

        } catch (error) {
            console.error(error);
        }

    }

    const fetchModelData = async () => {
        console.log("fetchModelData models: ", models);
        try {
          const promises = models.map((model) => {
            return axios.get(`https://api.sketchfab.com/v3/models/${model.uid}`, { headers: { 'Authorization': 'Token ' + SFtoken } });
          });
          
    
          setIsLoading(true);
          const responses = await Promise.all(promises);
          const data = responses.map((response) => response.data);
          console.log("promises data: ", data);
      
          const sortedModels = models
            .map((model, index) => ({
              ...model,
              createdAt: new Date(models[index].sfDoc.createdAt), // Convert createdAt to date object
            }))
            .sort((a, b) => b.createdAt - a.createdAt); // Sort models by createdAt in descending order (newest first)
        
          const sortedModelsHDImageUrl = sortedModels.map((model) => {
            const foundModel = data.find((item) => item.uid === model.uid);
            return foundModel?.thumbnails.images[0].url;
          });
          
          const sortedModelsUrl = sortedModels.map((model) => {
            const foundModel = data.find((item) => item.uid === model.uid);
            return foundModel?.thumbnails.images[2].url;
          });
      
          const sortedModelsIconUrl = sortedModels.map((model) => {
            const foundModel = data.find((item) => item.uid === model.uid);
            return foundModel?.thumbnails.images[3].url;
          });
      
          setModelsUrl(sortedModelsUrl);
          setModelsIconUrl(sortedModelsIconUrl);
          setModelsHDImageUrl(sortedModelsHDImageUrl);
          console.log("sortedModelsUrl: ", sortedModelsUrl);

        const modelIndex = sortedModels.findIndex(model => model._id === singlemodelViewsID);
            if (modelIndex !== -1) { // -1 means the model was not found in the array
            setSinglemodelViewsElementIndex(modelIndex);
            console.log("Model with the specific _id found: ", singlemodelViewsID);
            } else {
            console.log("Model with the specific _id not found: ", singlemodelViewsID);
        }

        const modelArIndex = sortedModels.findIndex(model => model._id === singlemodelViewsArID);
        if (modelArIndex !== -1) { // -1 means the model was not found in the array
            setSinglemodelViewsArElementIndex(modelArIndex);
            console.log("Model with the specific _id found");
            } else {
            console.log("Model with the specific _id not found");
        }

        const modelVrIndex = sortedModels.findIndex(model => model._id === singlemodelViewsVrID);
            if (modelVrIndex !== -1) { // -1 means the model was not found in the array
                setSinglemodelViewsVrElementIndex(modelVrIndex);
                console.log("Model with the specific _id found");
            } else {
                console.log("Model with the specific _id not found");
            }
      
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching model data: ", error);
          setIsLoading(false); // Finished loading (with error)
        }
    }
    
    const sendAnalyticsRequest = async () => {
        const url = apiUrl + '/tacito/analytics'; 
    
        try {
            const now = new Date();
            const formattedNow = now.toISOString();
        const response = await axios.post(url, {
            id_utente: auth.id,
            start_date: '2023-01-01',
            end_date: formattedNow, 
        }, {
            headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id },
            validateStatus: function (status) {
                return status >= 200 && status < 300; // default
              },
          });
    
        console.log('Analytics response:', response.data);
          if(response.data){
            console.log("Last Year Analytics Response data.data: ", response.data.data);
            console.log("-----------DATA-----------");
            console.log("response.data.data.max_interactions_model.total: ", response.data.data.max_interactions_model.total);
            console.log("response.data.data.max_views_model.total: ", response.data.data.max_views_model.total);
            console.log("response.data.data.max_views_model_ar.total: ", response.data.data.max_views_model_ar.total);
            console.log("response.data.data.max_views_model_vr.total: ", response.data.data.max_views_model_vr.total);
            console.log("response.data.data.mean_vis_time.sum_elapsed_time: ", response.data.data.mean_vis_time?.sum_elapsed_time ?? "not available");
            console.log("response.data.data.vis_tot.sum_views: ", response.data.data.vis_tot.sum_views);
            console.log("response.data.data.vis_tot.sum_views_ar: ", response.data.data.vis_tot.sum_views_ar);
            console.log("response.data.data.vis_tot.sum_interactions: ", response.data.data.vis_tot.sum_interactions);
            console.log("response.data.data.max_views_model._id: ", response.data.data.max_views_model._id);
            console.log("response.data.data.max_views_model_ar._id: ", response.data.data.max_views_model._id);
            console.log("response.data.data.max_views_model_vr._id: ", response.data.data.max_views_model._id);
            console.log("response.data.data.mean_vis_time.avg_view_time: ", response.data.data.mean_vis_time.avg_view_time);

            // Update the state with the response data
            setMaxInteractionsModel(response.data.data.max_interactions_model.total || "not available");
            setMaxViewsModel(response.data.data.max_views_model.total || "not available");
            setMaxViewsModelAr(response.data.data.max_views_model_ar.total || "not available");
            setMaxViewsModelVr(response.data.data.max_views_model_vr.total || "not available");
            setMeanVisualizationTime((response.data.data.mean_vis_time?.avg_view_time).toFixed(1) || "not available");
            setTotViews(response.data.data.vis_tot.sum_views || 0);
            setTotViewsAr(response.data.data.vis_tot.sum_views_ar || 0);
            setTotViewsVr(response.data.data.vis_tot.sum_views_vr || 0);
            setTotInteractions(response.data.data.vis_tot.sum_interactions || 0);
            setSinglemodelViewsID(response.data.data.max_views_model._id || "not available");
            setSinglemodelViewsArID(response.data.data.max_views_model_ar._id || "not available");
            setSinglemodelViewsVrID(response.data.data.max_views_model_vr._id || "not available");

            setAnalyticsResponse(response.data);
            console.log("setanalyticsResponse: ", analyticsResponse);
            console.log("setSinglemodelViewsID: ", singlemodelViewsID);
            console.log(singlemodelViewsID)
            console.log(models)
            setSinglemodelViewsElement(models.find(model => model._id === response.data.data.max_views_model._id))
            setSinglemodelViewsArElement(models.find(model => model._id === response.data.data.max_views_model_ar._id))
            setSinglemodelViewsVrElement(models.find(model => model._id === response.data.data.max_views_model_vr._id))
            console.log("setSinglemodelViewsElement: ", singlemodelViewsElement);

            
            setTimeout(() => { fetchModelData();},1000)
           

          } else { console.log("no analytics"); }


        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
            if(error.response.data.success === false){
                //setNoAnalytics(true);
                console.log("no analytics");
            }
            } else if (error.request) {
                console.error('No response was received', error.request);
            } else {
                console.error('Error', error.message);
            }
        }

    }

    const sendAnalyticsModelAvgRequest = async () => {
        if (!selectedModel) {
            console.error("selectedModel is null or undefined.");
            return;
        }
        const url = apiUrl + '/tacito/model/avg';
        const now = new Date();
        const formattedNow = now.toISOString();

        try {
            console.log("id modello attuale")
            const now = new Date();
            const formattedNow = now.toISOString();
            const response = await axios.post(apiUrl + '/tacito/model/avg', {
                start_date:"2023-01-01",
                end_date:formattedNow,
                fields:{
                    id_modello:selectedModel._id
                }
            }, {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
              });
        
            console.log('/model/avg response:', response.data); 
            setSinglemodelAvgInteractions(response.data.data.avg_int.toFixed(1) || "not available")
            setSinglemodelAvgLoadTime(response.data.data.avg_lt.toFixed(1) || "not available")
            } catch (error) {
            console.error('Error sending analytics request:', error); // Handle any errors that occur
            }

    try {
        const now = new Date();
        const formattedNow = now.toISOString();
        const response = await axios.post(apiUrl + '/tacito/model/sum', {
            start_date:"2023-01-01",
            end_date:formattedNow,
            fields:{
                id_modello:selectedModel._id
            }
            
        }, {
            headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
          });
    
        console.log('/model/sum response totale:', response.data);
        setSinglemodelInteractions(response.data.data?.sum_interactions)
        setSinglemodelViews(response.data.data?.sum_views)
        setSinglemodelViewsAr(response.data.data?.sum_views_ar)
        setSinglemodelViewsVr(response.data.data?.sum_views_vr)

        } catch (error) {
        console.error('Error sending analytics request:', error); // Handle any errors that occur
        }

        try {
            const now = new Date();
            const formattedNow = now.toISOString();
            const response = await axios.post(apiUrl + '/tacito/session/sum', {
                start_date:"2023-01-01",
                end_date:formattedNow,
                id_modello:selectedModel._id,
                fields:{
                    id_modello:selectedModel._id
                }
            }, {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
              });
        
            // !! ERA sum_elapsed_time DA RICONFRONTARE con sum_view_time !!

            console.log('/session/sum response:', response.data);
            setSinglemodelSumElapsedTime(response.data.data?.sum_view_time)
            } catch (error) {
            console.error('Error sending analytics request:', error); // Handle any errors that occur
            }

            
        try {
            const now = new Date();
            const formattedNow = now.toISOString();
            const response = await axios.post(apiUrl + '/tacito/session/avg', {
                start_date:"2023-01-01",
                end_date:formattedNow,
                id_modello:selectedModel._id,
                fields:{
                    id_modello:selectedModel._id
                }  
            }, {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
            });
            console.log('/session/avg response totale:', response.data);
            setSinglemodelAvgElapsedTime(response.data.data?.avg_view_time)
            } catch (error) {
            console.error('Error sending analytics request:', error); // Handle any errors that occur
            }

            try {
                const now = new Date();
                const formattedNow = now.toISOString();
                const response = await axios.post(apiUrl + '/tacito/heatmap/map', {
                    start_date:"2023-06-01T12:54:13.927Z",
                    end_date:formattedNow,
                    id_modello:selectedModel._id,
                fields:{
                    id_modello:selectedModel._id
                }
                }, {
                    headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
                });
                console.log('/heatmap/map response:', response.data.data); 
                console.log("/heatmap/map selectedModel._id", selectedModel._id);
                console.log(selectedModel._id);
                setHeatmapData(response.data.data?.[0]);
                console.log("heatmapData response");
                console.log(heatmapData);
            } catch (error) {
                console.error('Error sending analytics request:', error); // Handle any errors that occur
            }
    }

    const switchToSingleModel = (id,i) => {
        // Cancel any ongoing requests
        if (cancelTokenHeatmapSource) {
            cancelTokenHeatmapSource.cancel('Switching model, canceling previous requests.');
        }

        setHeatmapData(null);

        console.log("id: " + id);
        console.log(id);
        console.log("i: " + i);
        setSelectedModel(id);
        setSelectedI(i);
        setModelHDUrl(ModelsHDImageUrl[i]);
        if (selectedModel) {
            sendAnalyticsModelAvgRequest();
        }
        getModelChartData();
    }
    
    const switchToAllModels = () => {
        console.log("switching to all models");
        setSelectedModel(false);

        if (!dataLoaded) {
            // Make Axios calls to fetch models and HD Images
            // ...

            fetchModelData();

            setDataLoaded(true); // Set data as loaded
        }
        setSelectedI(null);
    }

    useEffect(() => {
        console.log("useEffect 1");
        // If coming from ModelPanel or ModelList and models are already set
        if (location.state && location.state.models) {
            console.log("useEffect 1.a");
            setModels(location.state.models);
            fetchModelData(location.state.models);
        } else {
            console.log("useEffect 1.b");
            // If not coming from ModelPanel or ModelList
        }
        console.log("useEffect 1 models: ",models)
    }, []);

    useEffect(() => {
        console.log("useEffect 2");
        if (selectedModel) {
            console.log("useEffect 2.a");
            sendAnalyticsModelAvgRequest();
            getModelChartData();
        }
    }, [selectedModel]);

    useEffect(() => {
        // Call fetchData when the component mounts
        fetchData();
    }, []);

    useEffect(() => {
        if (models) {
            sendAnalyticsRequest()
        }
    }, [models]);
    
    

    useEffect(() => {
        console.log("useEffect 3");
        if (heatmapRef.current && selectedModel && heatmapData) {
            console.log("useEffect 3.a");
            const width = parentRef.current.offsetWidth;
            const height = parentRef.current.offsetHeight;

            heatmapRef.current.style.width = `${width}px`;
            heatmapRef.current.style.height = `${height}px`;

            const instance = h337.create({
                container: heatmapRef.current,
                radius: 50
            });

            instance.addData(heatmapData);
        }
    }, [selectedModel, heatmapData]);
    
    {/*useEffect(() => {
        if (heatmapRef.current && selectedModel && heatmapData) {
            console.log("Heatmap instance is current!");
    
            const width = parentRef.current.offsetWidth;
            const height = parentRef.current.offsetHeight;
    
            heatmapRef.current.style.width = `${width}px`;
            heatmapRef.current.style.height = `${height}px`;
            
            const instance = h337.create({
                container: heatmapRef.current,
                radius: 50
            });
    
            // Add data from heatmapData to the heatmap instance
            instance.addData(heatmapData);
        }
    }, [selectedModel, heatmapData]);    

    useEffect(() => {
        // Always call sendAnalyticsRequest when the component is mounted
        fetchData();
        sendAnalyticsRequest();
    
        // If fromModelPanel is false or dataLoaded is true, fetch data
        if (!fromModelPanel || dataLoaded) {
            setDataLoaded(true); // Set data as loaded
        }
    
        // If selectedModel is truthy, call sendAnalyticsModelAvgRequest
        if (selectedModel) {
            sendAnalyticsModelAvgRequest();
        }
    }, []);*/}

    
    useEffect(() => {
        // Check if state is passed from the ModelPanel component
        if (location.state && location.state.selectedModel) {
        console.log("location.state in analytics: ", location.state)
          setModels(location.state.models);
          setSelectedModel(location.state.selectedModel);
          SetSelectedTab(location.state.selectedTab);
          SetSelectedModelHDImage(location.state.selectedModelHDImage);
          setFromModelPanel(location.state.fromModelPanel);
          setModelHDUrl(location.state.selectedModelHDImage);
          console.log("location.state.selectedModelHDImage: " + location.state.selectedModelHDImage);
          console.log(location.state.selectedModelHDImage);
        }
      }, [location]);
    

    return(
        <>
        <SidebarContainer>
            <Sidebar/>
        </SidebarContainer>
        <ViewContainer>
            {noAnalytics ? (  
            <>
                <HeaderBarContainer>
                    <HeaderBarWrapper>
                        <HeaderTitle>Analytics</HeaderTitle>
                    </HeaderBarWrapper>
                </HeaderBarContainer>
                <BodyContainer>
                    Analitiche non disponibili. Hai già caricato un modello?
                </BodyContainer>
            </>
            ) : (
            <>
            <HeaderBarContainer>
                <HeaderBarWrapper>
                    <HeaderTitle>Analytics</HeaderTitle>
                    <HeaderTabsContainer>
                        <HeaderTabElement isActive={selectedTab == '1'} onClick={() => SetSelectedTab("1")}>Generali</HeaderTabElement>
                        <HeaderTabElement isActive={selectedTab == '2'} onClick={() => SetSelectedTab("2")}>Modelli</HeaderTabElement>
                    </HeaderTabsContainer>
                </HeaderBarWrapper>
            </HeaderBarContainer>
            <BodyContainer>
            {selectedTab == '1' ? (<>
                <ContentWrapper>
                <FirstSection>
                    <LargestPanel>
                        <PanelWrapper>
                            <PanelContent>
                                {chartBarLoaded && (
                                    <Line  data={chartData} options={chartOptions}  />
                                )}
                            </PanelContent>
                        </PanelWrapper>
                    </LargestPanel>
                    <ColumnContainer>
                        <SmallestPanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <IconContainer src={analyticsviewsicon}/>
                                    <DescriptionContainer>Visualizzazioni Totali</DescriptionContainer>
                                    <ValueContainer>{TotViews}</ValueContainer>
                                </PanelContent>
                            </PanelWrapper>
                        </SmallestPanel>
                        <SmallestPanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <IconContainer src={analyticsaricon}/>
                                    <DescriptionContainer>Visualizzazioni AR</DescriptionContainer>
                                    <ValueContainer>{TotViewsAr}</ValueContainer>
                                </PanelContent>
                            </PanelWrapper>
                        </SmallestPanel>
                        <SmallestPanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <IconContainer src={analyticsvricon}/>
                                    <DescriptionContainer>Visualizzazioni VR</DescriptionContainer>
                                    <ValueContainer>{TotViewsVr}</ValueContainer>
                                </PanelContent>
                            </PanelWrapper>
                        </SmallestPanel>
                    </ColumnContainer>
                        <SmallestPanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <IconContainer src={analyticstimeicon}/>
                                    <DescriptionContainer>Tempo Medio Visualizzazione</DescriptionContainer>
                                    <ValueContainer>{Number((MeanVisualizationTime * 0.001).toFixed(2))} s</ValueContainer>
                                </PanelContent>
                            </PanelWrapper>
                        </SmallestPanel>
                        <SmallestPanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <IconContainer src={analyticsinteractionicon}/>
                                    <DescriptionContainer>Interazioni Totali</DescriptionContainer>
                                    <ValueContainer>{TotInteractions}</ValueContainer>
                                </PanelContent>
                            </PanelWrapper>
                        </SmallestPanel>
                </FirstSection>
                <SecondSection>
                    <ColumnContainer>
                        {singlemodelViewsElement && (
                            <LargePanel>
                                <PanelWrapper>
                                    <PanelContent>
                                        <LargePanelWrapper>
                                            <LeftPanelColumn>
                                                <LargeImagePanel src={modelsUrl[singlemodelViewsElementIndex]} />
                                            </LeftPanelColumn>
                                            <RightPanelColumn>
                                                <LargePanelName>Modello più visualizzato</LargePanelName>
                                                <LargePanelDescription>{singlemodelViewsElement.sfDoc.name}</LargePanelDescription>
                                                <LargePanelValueRow>
                                                    <LargePanelValueNumber>{MaxViewsModel}</LargePanelValueNumber>
                                                    <LargePanelValueName>visualizzazioni</LargePanelValueName>
                                                </LargePanelValueRow>
                                            </RightPanelColumn>
                                        </LargePanelWrapper>
                                    </PanelContent>
                                </PanelWrapper>
                            </LargePanel>
                        )}
                        {singlemodelViewsArElement && (
                        <LargePanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <LargePanelWrapper>
                                        <LeftPanelColumn>
                                            <LargeImagePanel  src={modelsUrl[singlemodelViewsArElementIndex]} />
                                        </LeftPanelColumn>
                                        <RightPanelColumn>
                                            <LargePanelName>Più visualizzato / AR</LargePanelName>
                                            <LargePanelDescription>{singlemodelViewsArElement.sfDoc.name}</LargePanelDescription>
                                            <LargePanelValueRow>
                                                <LargePanelValueNumber>{MaxViewsModelAr}</LargePanelValueNumber>
                                                <LargePanelValueName>visualizzazioni</LargePanelValueName>
                                            </LargePanelValueRow>
                                        </RightPanelColumn>
                                    </LargePanelWrapper>
                                </PanelContent>
                            </PanelWrapper>
                        </LargePanel>
                        )}
                        <LargePanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <LargePanelWrapper>
                                        <LeftPanelColumn>
                                            <LargeImagePanel  src={modelsUrl[singlemodelViewsVrElementIndex]} />
                                        </LeftPanelColumn>
                                        <RightPanelColumn>
                                            <LargePanelName>Più visualizzato / VR</LargePanelName>
                                            <LargePanelDescription>Nome del modello</LargePanelDescription>
                                            <LargePanelValueRow>
                                                <LargePanelValueNumber>{MaxViewsModelVr}</LargePanelValueNumber>
                                                <LargePanelValueName>visualizzazioni</LargePanelValueName>
                                            </LargePanelValueRow>
                                        </RightPanelColumn>
                                    </LargePanelWrapper>
                                </PanelContent>
                            </PanelWrapper>
                        </LargePanel>
                    </ColumnContainer>
                    <ColumnPanel>
                        <PanelWrapper>
                            <PanelContent></PanelContent>
                        </PanelWrapper>
                    </ColumnPanel>
                </SecondSection>
            </ContentWrapper>
            </>) : selectedTab == '2' ? (
                <>
            {!selectedModel   ? (
                <CenterWrapper>
                    <ModelBoxContainer> 
                        {models.map((model, key) => (
                            <ModelBox model={model} key={key} modelNumber={key} modelsUrl={modelsUrl[key]} handleClick={switchToSingleModel} />
                        ))}
                    </ModelBoxContainer>
                </CenterWrapper>
            ) : (
            <>
            <CenterWrapper>
                <BackButton onClick={() => switchToAllModels()}>
                    <BackButtonIcon  src={backicon}></BackButtonIcon>
                    Torna ai modelli
                    
                </BackButton>
                <AnalyticsDetailContainer>
                    <DetailFirstSectionContainer>
                        <SketchFabFrameContainer ref={parentRef}>
                            <ThumbnailContent src={modelHDUrl}></ThumbnailContent>
                        </SketchFabFrameContainer>
                        <HeatmapButtonContainer>
                            <HeatmapToggle>
                                <input 
                                    type="range" 
                                    min="0" 
                                    max="1" 
                                    step="0.01" 
                                    value={opacity} 
                                    onChange={(e) => setOpacity(e.target.value)} 
                                />
                                Heatmap
                            </HeatmapToggle>
                        </HeatmapButtonContainer>
                        {heatmapVisible && (
                            <HeatmapContainer>
                                <HeatmapWrapper style={{ opacity: opacity }}>
                                    {heatmapData && (<HeatmapView heatmapData={heatmapData}  parentRef={parentRef}/>)}
                                </HeatmapWrapper>
                            </HeatmapContainer>
                        )}
                    </DetailFirstSectionContainer>
                    <DetailSecondSectionContainer>
                        <SecondSectionWrapper>
                            <ViewsChartContainer>
                                <PanelWrapper>
                                    <PanelContent>
                                        {modelchartBarLoaded && (
                                            <Line  data={modelchartData} options={modelchartOptions}  />
                                        )}
                                    </PanelContent>
                                </PanelWrapper>
                            </ViewsChartContainer>
                            {SmallestPanelData.map(panel => <RenderSmallestPanel {...panel} />)}
                        </SecondSectionWrapper>
                    </DetailSecondSectionContainer>                 
                </AnalyticsDetailContainer>
                </CenterWrapper>
            </>)}
            </>
            ) : <>
            </>}
            </BodyContainer>
            

            </>)}
        </ViewContainer>
        </>
    )
}

export default Analytics;