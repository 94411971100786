import React, {useState, useEffect, useRef} from 'react';
import useAuth from '../../hooks/useAuth.js';
import axios from 'axios';
import { Line } from 'react-chartjs-2';

import backicon from '../../img/back24icon.png'
import analyticsaricon from '../../img/analyticsar36icon.png'
import analyticsvricon from '../../img/analyticsvr36icon.png'
import analyticsinteractionicon from '../../img/analyticsinteraction36icon.png'
import analyticstimeicon from '../../img/analyticstime36icon.png'
import analyticsviewsicon from '../../img/analyticsviews36icon.png' 

import { FirstSection, SecondSection, PanelContent, LargePanel, LargestPanel,
    SmallestPanel, ColumnContainer,PanelWrapper,DescriptionContainer,
    IconContainer,ValueContainer, LargePanelDescription,
    LargePanelName, LargePanelValueName,LargePanelValueNumber,LargePanelValueRow,LeftPanelColumn,
    RightPanelColumn, LargeImagePanel,LargePanelWrapper, ContentWrapper,PanelLineContent, LargePanelLink 
} from './analyticsaccountElements.js';

const AnalyticsAccount = (props) => {
    // const apiUrl = 'https://0146-87-17-214-243.ngrok-free.app'
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;
    console.debug('AnalyticsAccount props: ', props);

    const { auth } = useAuth();
    
    const [isLoading, setIsLoading] = useState(true);
    
    const [chartBarLoaded, setChartBarLoaded] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);

    const fetchMonthlyViews = async () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        const monthlyViews = [];
    
        for (let month = 0; month <= currentMonth; month++) {
            const startDate = new Date(currentYear, month, 1).toISOString();
            const endDate = new Date(currentYear, month + 1, 0).toISOString();
            const id = auth.id;
            console.debug("getChartData : ", startDate);
            try {
                const response = await axios.post(apiUrl + "/tacito/analytics/", {
                    id_utente: id,
                    start_date: startDate,
                    end_date: endDate
                }, {
                    headers: {
                        'Authorization': `Bearer ${auth?.accessToken}`,
                        'id': id,
                        '3d-portlab-api-key': '22646265-e26f-4158-92c6-db812bdf9524'
                    }
                });
    
                if (response.data && response.data.data && response.data.data.vis_tot) {
                    monthlyViews.push(response.data.data.vis_tot?.sum_views || 0);
                } else {
                    monthlyViews.push(0);
                }
            } catch (error) {
                if (error.response && error.response.status !== 404) {
                    console.error(`Error fetching views for month ${currentMonth + 1}: `, error);
                }
                monthlyViews.push(0);
            }
        }
    
        return monthlyViews;
    }
    
    const getChartData = async () => {
        const monthlyData = await fetchMonthlyViews();
    
        const data = {
            labels: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
            datasets: [
                {
                    label: 'Visualizzazioni per mese',
                    data: monthlyData,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }
            ]
        };
    
        const options = {
            scales: {
                y: {
                    beginAtZero: true
                }
            },
            mantainAspectRatio: false
        };
    
        setChartData(data);
        setChartOptions(options);
        setChartBarLoaded(true)
    }

    useEffect(() => {
        getChartData();
    }, [])

    return (
        <>
            <ContentWrapper>
                <FirstSection>
                    <LargestPanel>
                        <PanelWrapper>
                            <PanelLineContent>
                                {chartBarLoaded ? (
                                    <Line  data={chartData} options={chartOptions}  />
                                ) : (<>Loading</>)}
                            </PanelLineContent>
                        </PanelWrapper>
                    </LargestPanel>
                    <ColumnContainer>
                        <SmallestPanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <IconContainer src={analyticsviewsicon}/>
                                    <DescriptionContainer>Visualizzazioni Totali</DescriptionContainer>
                                    <ValueContainer>{props.data.totalSumViews ?? "loading"}</ValueContainer>
                                </PanelContent>
                            </PanelWrapper>
                        </SmallestPanel>
                        <SmallestPanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <IconContainer src={analyticsaricon}/>
                                    <DescriptionContainer>Visualizzazioni AR</DescriptionContainer>
                                    <ValueContainer>{props.data.totalSumViewsAr ?? "loading"}</ValueContainer>
                                </PanelContent>
                            </PanelWrapper>
                        </SmallestPanel>
                        <SmallestPanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <IconContainer src={analyticsvricon}/>
                                    <DescriptionContainer>Visualizzazioni VR</DescriptionContainer>
                                    <ValueContainer>{props.data.totalSumViewsVr ?? "loading"}</ValueContainer>
                                </PanelContent>
                            </PanelWrapper>
                        </SmallestPanel>
                    </ColumnContainer>
                        <SmallestPanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <IconContainer src={analyticstimeicon}/>
                                    <DescriptionContainer>Tempo Medio Visualizzazione</DescriptionContainer>
                                    <ValueContainer>{Number((props.data.meanVisualizationTime/props.data.totalSumViews * 0.001).toFixed(2)) ?? "loading"} s</ValueContainer>
                                </PanelContent>
                            </PanelWrapper>
                        </SmallestPanel>
                        <SmallestPanel>
                            <PanelWrapper>
                                <PanelContent>
                                    <IconContainer src={analyticsinteractionicon}/>
                                    <DescriptionContainer>Interazioni Totali</DescriptionContainer>
                                    <ValueContainer>{props.data.totalSumInteractions ?? "loading"}</ValueContainer>
                                </PanelContent>
                            </PanelWrapper>
                        </SmallestPanel>
                </FirstSection>
                <SecondSection>
                    <ColumnContainer>
                            <LargePanel>
                                <PanelWrapper>
                                    {props.data.mostViewedModelIndex !== -1 ? (
                                    <PanelContent>
                                        <LargePanelWrapper>
                                            <LeftPanelColumn>
                                                <LargeImagePanel src={props.data.secondaryData[props.data.mostViewedModelIndex].thumbnails.images[2].url} />
                                            </LeftPanelColumn>
                                            <RightPanelColumn>
                                                <LargePanelName>Modello più visualizzato</LargePanelName>
                                                <LargePanelDescription>{props.data.sortedModels[props.data.mostViewedModelIndex].sfDoc.name}</LargePanelDescription>
                                                <LargePanelValueRow>
                                                    <LargePanelValueNumber>{props.data.modelPreliminaryData[props.data.mostViewedModelIndex]?.sum_views ?? "loading"}</LargePanelValueNumber>
                                                    <LargePanelValueName>visualizzazioni</LargePanelValueName>
                                                </LargePanelValueRow>
                                                <LargePanelLink onClick={() => props.setSelectedModel(props.data.mostViewedModelIndex)}> vai alle analitiche del modello </LargePanelLink>
                                            </RightPanelColumn>
                                        </LargePanelWrapper>
                                    </PanelContent>
                                    ) : (
                                    <PanelContent>
                                        <LargePanelWrapper>
                                            <LeftPanelColumn>
                                                <LargeImagePanel />
                                            </LeftPanelColumn>
                                            <RightPanelColumn>
                                                <LargePanelName>Modello più visualizzato</LargePanelName>
                                                <LargePanelDescription>No Data</LargePanelDescription>
                                                <LargePanelValueRow>
                                                    <LargePanelValueNumber>0</LargePanelValueNumber>
                                                    <LargePanelValueName>visualizzazioni</LargePanelValueName>
                                                </LargePanelValueRow>
                                            </RightPanelColumn>
                                        </LargePanelWrapper>
                                    </PanelContent>
                                    )}
                                </PanelWrapper>
                            </LargePanel>
                        <LargePanel>
                            <PanelWrapper>
                                    {props.data.mostViewedArModelIndex !== -1 ? (
                                <PanelContent>
                                    <LargePanelWrapper>
                                        <LeftPanelColumn>
                                            <LargeImagePanel  src={props.data.secondaryData[props.data.mostViewedArModelIndex].thumbnails.images[2].url} />
                                        </LeftPanelColumn>
                                        <RightPanelColumn>
                                            <LargePanelName>Più visualizzato / AR</LargePanelName>
                                            <LargePanelDescription>{props.data.sortedModels[props.data.mostViewedArModelIndex].sfDoc.name}</LargePanelDescription>
                                            <LargePanelValueRow>
                                                <LargePanelValueNumber>{props.data.modelPreliminaryData[props.data.mostViewedArModelIndex]?.sum_views_ar ?? "loading"}</LargePanelValueNumber>
                                                <LargePanelValueName>visualizzazioni</LargePanelValueName>
                                            </LargePanelValueRow>
                                            <LargePanelLink onClick={() => props.setSelectedModel(props.data.mostViewedArModelIndex)}> vai alle analitiche del modello </LargePanelLink>
                                        </RightPanelColumn>
                                    </LargePanelWrapper>
                                </PanelContent>
                                    ) : (
                                    <PanelContent>
                                        <LargePanelWrapper>
                                            <LeftPanelColumn>
                                                <LargeImagePanel />
                                            </LeftPanelColumn>
                                            <RightPanelColumn>
                                                <LargePanelName>Più visualizzato / AR</LargePanelName>
                                                <LargePanelDescription>No Data</LargePanelDescription>
                                                <LargePanelValueRow>
                                                    <LargePanelValueNumber>0</LargePanelValueNumber>
                                                    <LargePanelValueName>visualizzazioni</LargePanelValueName>
                                                </LargePanelValueRow>
                                            </RightPanelColumn>
                                        </LargePanelWrapper>
                                    </PanelContent>
                                    )}
                            </PanelWrapper>
                        </LargePanel>
                        <LargePanel>
                            <PanelWrapper>
                                    {props.data.mostViewedVrModelIndex !== -1 ? (
                                <PanelContent>
                                    <LargePanelWrapper>
                                        <LeftPanelColumn>
                                            <LargeImagePanel  src={props.data.secondaryData[props.data.mostViewedVrModelIndex].thumbnails.images[2].url} />
                                        </LeftPanelColumn>
                                        <RightPanelColumn>
                                            <LargePanelName>Più visualizzato / VR</LargePanelName>
                                            <LargePanelDescription>{props.data.sortedModels[props.data.mostViewedVrModelIndex].sfDoc.name}</LargePanelDescription>
                                            <LargePanelValueRow>
                                                <LargePanelValueNumber>{props.data.modelPreliminaryData[props.data.mostViewedVrModelIndex]?.sum_views_vr ?? "loading"}</LargePanelValueNumber>
                                                <LargePanelValueName>visualizzazioni</LargePanelValueName>
                                            </LargePanelValueRow>
                                            <LargePanelLink onClick={() => props.setSelectedModel(props.data.mostViewedVrModelIndex)}> vai alle analitiche del modello </LargePanelLink>
                                        </RightPanelColumn>
                                    </LargePanelWrapper>
                                </PanelContent>
                                    ) : (
                                        <PanelContent>
                                        <LargePanelWrapper>
                                            <LeftPanelColumn>
                                                <LargeImagePanel />
                                            </LeftPanelColumn>
                                            <RightPanelColumn>
                                                <LargePanelName>Più visualizzato / VR</LargePanelName>
                                                <LargePanelDescription>No Data</LargePanelDescription>
                                                <LargePanelValueRow>
                                                    <LargePanelValueNumber>0</LargePanelValueNumber>
                                                    <LargePanelValueName>visualizzazioni</LargePanelValueName>
                                                </LargePanelValueRow>
                                            </RightPanelColumn>
                                        </LargePanelWrapper>
                                    </PanelContent>
                                    )}
                            </PanelWrapper>
                        </LargePanel>
                    </ColumnContainer>
                </SecondSection>
            </ContentWrapper>
        </>
    )
}

export default AnalyticsAccount;