import styled from 'styled-components';


export const UploadWindowContainer = styled.div`
display: flex;
background: rgba(30, 41, 59, 0.4);
border-radius: 12px;
font-family: 'Space Grotesk', sans-serif;
`

export const UploadWindowWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 24px;
width: 100%;
gap: 8px;
`

export const WindowHeader = styled.div`
height: 32px;
flex-grow: 0;
color: #F8FAFC;
font-size: 24px;
text-align: left;
`

export const WindowDescription = styled.div`
    color: #94A3B8;
    font-size: 16px;
    text-align: left;
    font-weight: normal;
    max-width: 340px;
`

export const WindowContainer = styled.div`
color: #94A3B8;
font-size: 16px;
text-align: left;
font-weight: normal;
    max-width: 340px;
`

export const ErrorWrapper = styled.div`
text-align: center;
flex-grow: 0;
`

export const ErrorContent = styled.h4`

`

export const WindowFooter = styled.div`
//height: 48px;
width: 100%;
flex-grow: 0;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
`

export const ButtonUpload = styled.button`
height: 32px;
width: 80px;
border-radius: 8px;
background: linear-gradient(60deg,#C83F3B,#E5AA47);
border: none;
font-weight: bold;
font-size: 11px;
text-transform: uppercase;
cursor: pointer;
`

export const UploadAreaWrapper = styled.div`
    background-color: #4755693D;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23475569FF' stroke-width='4' stroke-dasharray='8%2c 14' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-content: center;
    justify-content: center;
`

export const DragAndDropFirstRow = styled.div`
color: #F8FAFC;
    font-weight: 400;
`

export const DragAndDropSecondRow = styled.div`
    color: #94A3B8;
    font-weight: 400;
`

export const InputFileLabel = styled.label`
    text-transform: uppercase;
    font-size: 14px;
    color: #F8FAFC;
    padding: 8px 24px;
    background: linear-gradient(135deg,rgba(249, 115, 22, 1),rgba(194, 65, 12, 1));
    border-radius: 22px;
    cursor: pointer;
`

export const InputFile = styled.input`
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`

export const DragAndDrop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    border-radius: 4px;
    margin: 2px;

    &:hover {
        background-color: #090d205e;
    }
`
