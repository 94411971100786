import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SidebarContainer = styled.div`
height: 72px;
background: transparent;
display: flex;
`   

export const ViewContainer = styled.div`
height: calc(100vh - 72px);
flex-grow: 1;
background: transparent;
//background: linear-gradient(60deg, #2c287b, #773824);
color: #eeeeee;
display: flex;
flex-direction: column;
`

export const HeaderBar = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 40px 0 24px 64px;
  gap: 12px;
  align-items: center;
  flex-grow: 0;
`

export const BackButton = styled(Link)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export const BackButtonIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export const ModelTitle = styled.div`
  font-size: 32px;
  font-weight: bold;

  & input {
    font-size: 32px;
    font-weight: bold;
    background: transparent;
    border: 0px none;
    color: #ddd;    
    font-family: 'Space Grotesk', sans-serif;
    padding: 0px;
  }
`

export const ModelDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  gap: 8px;
`

export const ModelDetailRow = styled.div`
  font-size: 14px;
`

export const LargeButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`

export const LargeButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 16px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: 
    linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.10));
  backdrop-filter: blur(32px);
`

export const LargeButtonElement = styled.div`
  height: 48px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  opacity: 64%;
  cursor: pointer;

  &:hover{
    opacity: 1;
  }
`

export const LargeIconButton = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`

export const PreviewButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;    
  align-items: center;
`

export const PreviewButtonElement = styled(Link)`
  display: flex;
  width: 310px;
  height: 44px;
  cursor: pointer;    
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 6px;
  border-radius: 22px;
  text-decoration: none;
  color: white;
`

export const EmbedButtonElement = styled.div`
  display: flex;
  width: 310px;
  height: 44px;
  cursor: pointer;    
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 6px;
  border-radius: 22px;
  text-decoration: none;
  color: white;
`

export const QRButtonElement = styled.div`
  display: flex;
  width: 310px;
  height: 44px;
  cursor: pointer;    
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 6px;
  border-radius: 22px;
  text-decoration: none;
  color: white;
`

export const PremiumButtonElement = styled.div`
  display: flex;
  width: 310px;
  height: 44px;
  cursor: pointer;    
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 6px;
  border-radius: 22px;
  text-decoration: none;
  color: white;
  background-color: rgba(255,255,255,0.08);
`

export const QRButtonHeader = styled.div`
  display: flex;
  gap: 8px;
  align-content: center;
  justify-content: center;
  align-items: center;
`

export const QRButtonHalfContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 310px;
  height: 44px; 
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 22px;
  text-decoration: none;
  color: white;
`

export const QRButtonHalfLeft = styled.div`
  display: flex;
  width: 155px;
  height: 44px;
  cursor: pointer;    
  justify-content: center;
  align-items: center;
  align-content: center;
  text-decoration: none;
  color: white;
  border-radius: 22px 0 0 22px;
  background: rgba(255 255 255 / 10%);
  gap: 16px;

  &:hover{
    background: linear-gradient(135deg,rgb(228 83 23),rgb(167 32 162));  }
`

export const QRButtonHalfRight = styled.div`
  display: flex;
  width: 155px;
  height: 44px;
  cursor: pointer;    
  justify-content: center;
  align-items: center;
  align-content: center;
  text-decoration: none;
  color: white;
  border-radius: 0 22px 22px 0;
  background: rgba(255 255 255 / 10%);
  gap: 16px;

&:hover{
  background: linear-gradient(135deg,rgb(228 83 23),rgb(167 32 162));}
`

export const CodeButtonHalfLeft = styled.div`
  display: flex;
  width: 155px;
  height: 44px;
  cursor: pointer;    
  justify-content: center;
  align-items: center;
  align-content: center;
  text-decoration: none;
  color: white;
  border-radius: 22px 0 0 22px;
  background: rgba(255 255 255 / 10%);
  gap: 16px;
`

export const CodeButtonHalfRight = styled.div`
  display: flex;
  width: 155px;
  height: 44px;
  cursor: pointer;    
  justify-content: center;
  align-items: center;
  align-content: center;
  text-decoration: none;
  color: white;
  border-radius: 0 22px 22px 0;
  background: rgba(255 255 255 / 10%);
  gap: 16px;
`

export const PreviewButtonIcon = styled.img`
  width: 24px;
  height: 24px;
`

export const ContentWrapper = styled.div`
display:flex;
flex-direction: row;
flex-grow: 1;
`

export const ContentSection = styled.div`
display:flex;
flex-direction: row;
flex-grow: 1;
padding: 0 64px 40px 64px;
gap: 24px;
`

export const PreviewWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: 
    linear-gradient(135deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.05));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border-radius: 24px;
  backdrop-filter: blur(32px);
  overflow: hidden;
`

export const PreviewContainer = styled.div`
background: #111111;
color: white;
flex-grow: 0;
`

export const FrameContainer = styled.div`
display: flex;
flex-grow: 1;
margin: 12px;
border-radius: 16px;
width: calc(100% - 24px);
overflow: hidden;
align-content: center;
justify-content: center;
align-items: center;
`

export const QRCodeContainer = styled.div`
  display:flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  gap: 16px;
  flex-direction: column;
  background: rgba(0,0,0,0.67);
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  border-radius: 24px;
  padding: 8px;
  z-index: 12;
  overflow-wrap: anywhere;
`

export const QRCodeWrapper = styled.div`
  display:flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  background: linear-gradient(90deg,rgb(167 32 162),rgb(228 83 23));
`

export const QRCloseButton = styled.div`
  background: #334155;
  height: 32px;
  color: #F8FAFC;
  font-family: 'Space Grotesk', sans-serif;
  text-transform: uppercase;
  padding: 0 16px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 16px;
  line-height: 32px;
`

export const QRdirectButton = styled.a`
  background: #e3521d;
  height: 32px;
  color: #F8FAFC;
  font-family: 'Space Grotesk', sans-serif;
  text-transform: uppercase;
  padding: 0 16px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 16px;
  line-height: 32px;
`

export const CodeIcon = styled.img`
width: 24px;
height: 24px;
`

export const QRIcon = styled.img`
width: 32px;
height: 32px;
`

export const QRHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const PremiumTag = styled.div`
  height: 18px;
  width: 120px;
  border-radius: 10px;
  background: linear-gradient(160deg,#A21CAF,#EA580C);
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`

export const SectionTitle = styled.h2`
display:flex;

`

export const CategorySection = styled.div`


`

export const ConfigurationEasyToggle = styled.div`
  height: 47px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #334054;
`

export const CategoryTitle = styled.div`
display:flex;
font-size: 1.4rem;
font-weight: bold;
padding-left: 8px;
text-transform: uppercase;
`

export const InputContainer = styled.div`
  display:flex;
  min-height: 74px;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin: 0px 20px 0px 32px;
  border-bottom: 1px solid #334155;
`

export const InputLeftSection = styled.div`

`

export const InputDescription = styled.div`
  font-weight: 14px;
  color: #94A3B8;
`
export const TextAreaInputContainer = styled.div`
  display:flex;
  min-height: 74px;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin: 0px 20px 0px 32px;
  border-bottom: 1px solid #334155;
  gap: 8px;
`

export const TextareaInputLabel = styled.div`

`

export const TextareaInputButtonContainer = styled.div`
  width: 140px;
  height: 140px;
  margin: 8px;
  border-radius: 8px;
  background-color: #475569;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
  align-items: center;
  align-content: center;
`

export const TextareaSingleInputButton = styled.div`
  width: 38px;
  height: 38px;
  background-color: #334155;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const TextareaSingleEmptyButton = styled.div`
  width: 38px;
  height: 38px;
`

export const TextareaInput = styled.textarea`
  background: #334155;
  color: #eee;
  width: calc(100% - 32px);
  min-height: 32px;
  border: none;
  border-radius: 8px;
  outline: none;
  resize: none;
  margin-bottom: 16px;
  padding: 16px;
  font-size: 16px;
`

export const TextAreaSelectedBox = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 4px;
  background: #EA580C;
`

export const HeaderConfigSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 32px;
`

export const HeaderConfigTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`

export const CloseButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export const HeaderConfigToggle = styled.div`
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #334155;
  border-radius: 12px;
`

export const ConfigToggleElementLeft = styled.div`
  display: flex;
  height: 32px;
  border-radius: 10px;
  margin: 0px 2px;
  flex-grow: 1;
  color: ${props => (props.isActive ? '#F8FAFC' : '#94A3B8')};
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${props => (props.isActive ? '#4F46E5' : 'transparent')};
  font-weight: ${props => (props.isActive ? 'bold' : 'normal')};

  &:active {
    background: #4F46E5;
    font-weight: bold;
    color: #F8FAFC;
  }
`;

export const ConfigToggleElementRight = styled.div`
  display: flex;
  height: 32px;
  border-radius: 10px;
  margin: 0px 2px;
  flex-grow: 1;
  color: ${props => (props.isActive ? '#F8FAFC' : '#94A3B8')};
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${props => (props.isActive ? '#EA580C' : 'transparent')};
  font-weight: ${props => (props.isActive ? 'bold' : 'normal')};

  &:active {
    background: #EA580C;
    font-weight: bold;
    color: #F8FAFC;
  }
`;


export const CentralConfigSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background: #1E293B;
  flex-grow: 1;
  height: calc(100% - 144px);    

  scrollbar-width: auto;
  scrollbar-color: #334155 #1e293b;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #1e293b;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #334155;
    border-radius: 10px;
    border: 2px solid #1e293b;
  }
  
`

export const TabWrapper = styled.div`
  opacity: 1;
  position: absolute;
  right: 0px;
  top: 72px;
  display:flex;
  flex-direction: column;
  z-index: 4;
  width: 480px;
  height: calc(100vh - 72px);
  background-color: #0F172A;
    justify-content: space-between;
`

export const FooterConfigSection = styled.div`
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FooterButtonConfigCOntainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  gap: 12px;
`

export const SaveConfigButton = styled.div`
  background: #475569;
  height: 44px;
  border-radius: 22px;
  flex-grow: 1;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-transform: uppercase;
`

export const PublishConfigButton = styled.div`
  background: ${({mode}) => ( mode === 'test' ? '#4f46e5' : '#EF580C')};
  height: 44px;
  border-radius: 22px;
  flex-grow: 1;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-transform: uppercase;
`

export const InputLabel = styled.div`
  display:flex;
  font-size: 16px;
`

export const InputButton = styled.div`
display:flex;

`

export const InputToggle = styled.button`

`

export const TabContentView = styled.div`
  height: calc(100% - 64px);
  flex-grow: 1;
  overflow-y: scroll;

  scrollbar-width: auto;
  scrollbar-color: #334155 #1e293b;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #1e293b;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #334155;
    border-radius: 10px;
    border: 2px solid #1e293b;
  }
  
`

export const TabSelectorContainer = styled.div`
  height: 64px;
  width: calc(100% - 80px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  overflow: hidden; /* Hide the scrollbar */
  white-space: nowrap;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

export const HorizontalTabContainer = styled.div`
  display: flex;
  flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const HorizontalLeftArrow = styled.div`    
height: 32px;
width: 32px;
background: #475569;
border-radius: 4px;
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
margin-left: 8px;
cursor: pointer;
`

export const HorizontalRightArrow = styled.div`    
height: 32px;
width: 32px;
background: #475569;
border-radius: 4px;
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
margin-right: 8px;
cursor: pointer;
`

export const TabName = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s ease-in-out;
  height: 32px;

  background-color: ${props => (props.isActive ? "#475569" : "transparent")};
  color: ${props => (props.isActive ? "#F8FAFC" : "#64748B")};

  &:hover {
    background-color: ${props => (props.isActive ? "#475569" : "#475569")};
  }

  &:nth-child(1) {
    margin-left: 8px;
  }
  
  &:last-child {
    margin-right: 8px;
  }

  &:active {
    background: #475569;
    color: #f8fafc;
    scroll-margin-start: 20px; // Adjust this value to provide space for the tab to fully show
  }
`;