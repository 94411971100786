import React from 'react';

import { PopupContainer, PopupWrapper, ButtonClose } from './popupElements';
import closeicon from  '../../img/close24icon.png';

const Popup = ({ onClose, children }) => {
  return (
    <PopupContainer>
      <PopupWrapper>
        <ButtonClose onClick={onClose} src={closeicon}></ButtonClose>
        {children}
      </PopupWrapper>
    </PopupContainer>
  );
};

export default Popup;