import React, { useEffect, useState, useRef } from 'react';

import {ParentContainer,ButtonContainer,AnnotationsContainer,AnnotationElementContainer,AnnotationTitle,AnnotationNumber,
    AnnotationInteractionButton,AnnotationInteractionContainer,AnnotationInteractionWrapper,IFrame,BackButton,
  AnnotationContainer,RightClickOverlay} from "./scenetestElements.js"

const SceneTest = () => {
  
const iframeRef = useRef(null);
const AnnotationsContainerRef = useRef(null);
const [apiInstance, setApiInstance] = useState(null);
const url = "ccc6fea53b3d4a8eb053d1c804513e88";
const [isLoading, setIsLoading] = useState(true);
const [isLoaded, setIsLoaded] = useState(null);
const lastClickTime = useRef(0);
const [isCameraMoving, setIsCameraMoving] = useState(null);

// Additional State for Materials
const [nodes, setNodes] = useState([]);
const [materials, setMaterials] = useState([]);
const [annotations, setAnnotations] = useState([]);
const [selectedNode, setSelectedNode] = useState(null);
const [selectedMaterial, setSelectedMaterial] = useState(null);
//const [selectedCustomMaterial, setSelectedCustomMaterial] = useState(null);
const [selectedAnnotation, setSelectedAnnotation] = useState(null);

// Custom Materials (Blue, Red, Green)
const [customMaterialRGBID, setCustomMaterialRGBID] = useState({ blue: null, red: null, green: null });
const [contextMenu, setContextMenu] = useState({
  visible: false,
  x: 0,
  y: 0,
});

const interactionButtons = ['A', 'B', 'C', 'D'];
const annotationData = [
  {
    position: [0.77, 0.36, 0.55],
    eye: {"0": 0.7498, "1": -0.2883, "2": 1.1387},
    target: {"0": 0.7484, "1": 0.1841, "2": 0.7960},
    title: 'Tavolo',
    id: 'T1'
  },
  {
    position: [0.7250, 1.8205, 0.5281],
    eye: {"0": 0.7456, "1": 0.7051, "2": 1.0499},
    target: {"0": 0.7458, "1": 0.7137, "2": 1.0449},
    title: 'Tavolino',
    id: 'T2'
  },
  {
    position: [-1.09077, 2.2843, 0.1385],
    eye: {"0": 0.0842, "1": 1.302531181998609, "2": 0.9086},
    target: {"0": 0.0781, "1": 1.3094, "2": 0.9046},
    title: 'Piante',
    id: 'T3'
  },
  {
    position: [0.5989, 3.3351, 1.4733],
    eye: {"0": 0.8510694089165572, "1": 0.7777103966214787, "2": 1.6078246559790716},
    target: {"0": 0.8847434305260529, "1": 2.0927669351337155, "2": 1.6444066102254373},
    title: 'Mensole',
    id: 'T4'
  },
  {
    position: [2.7332000732421875, 1.3139725410190517, 1.5594341122501527],
    eye: {"0": 1.3439847280695134, "1": -0.6045208335679606, "2": 1.9732887730394493},
    target: {"0": 2.325939724357017, "1": 0.4416940435055059, "2": 1.7602685144129222},
    title: 'Muro',
    id: 'T5'
  },
  // Add more annotations as needed
];

const customMaterials = [
  { name: 'red', color: [1.0, 0.0, 0.0] },
  { name: 'green', color: [0.0, 1.0, 0.0] },
  { name: 'blue', color: [0.0, 0.0, 1.0] },
  { name: 'black', color: [0.0, 0.0, 0.0] },
  { name: 'darkGrey', color: [0.2, 0.2, 0.2] },
  { name: 'gold', color: [1.0, 0.84, 0.0] },
  { name: 'silver', color: [0.75, 0.75, 0.75] }
];

useEffect(() => {
  if ( !iframeRef.current) return;
  const client = new window.Sketchfab(iframeRef.current);

  const error = () => {
    console.error('Error initializing Sketchfab API');
  };
  
  const config = {
    
    success: function onSuccess(api) {
      api.start();

      api.addEventListener('viewerready', function () {

        setApiInstance(api);
        console.debug("viewer ready");

        api.getNodeMap(function(err, nodes) {
          if (!err) {
              window.console.log("nodemap: ", nodes); // [ ... ]
          }
        });

        customMaterials.forEach(material => {
          api.createMaterial({
            channels: {
              AlbedoPBR: {
                color: material.color
              }
            }
          }, function (err, createdMaterial) {
            if (!err) {
              setCustomMaterialRGBID(prevState => ({
                ...prevState,
                [material.name]: createdMaterial.id
              }));
            }
          });
        });

        annotationData.forEach(annotation => {
          api.createAnnotationFromWorldPosition(
            annotation.position,
            annotation.eye,
            annotation.target,
            annotation.title,
            annotation.id,
            function(err, index) {
              if (!err) {
                  // Handle success
              }
            }
          );
        });
        
        var url = getInvisiblePastilleURL();
        api.setAnnotationsTexture(url, function () {});

        api.getAnnotationList(function(err, annotationsList) {
          if (!err) {
              //window.console.log(annotationsList);
          }
          setAnnotations(annotationsList);
          //console.debug('Annotations retrieved:', annotationsList);
          runAnnotationLoop(api, annotationsList);
        });
        
        setIsLoading(false);
        setIsLoaded(true);

      });

      api.addEventListener('click', function (info) {
        console.log('click info: ', info);
        handleClick(info.position2D[0], info.position2D[1]);
      },
      {pick: "slow"});

      api.addEventListener('camerastart', function() {
        window.console.log('Camera is moving');
        setContextMenu({ visible: false });
        setIsCameraMoving(true);
      });

      api.addEventListener('camerastop', function() {
        window.console.log('Camera stopped');
        setIsCameraMoving(false);
      });
    },
    autostart: 1,
    annotation: 0,
    annotations_visible: 0,
    ui_annotations: 0,
    ui_controls: 0,
    ui_watermark: 0,
    ui_infos: 0,
    ui_hint: 0,
    ui_start: 0,
    ui_stop: 0,
    error: error
  };

  client.init(url, config);
}, [url]);

// Fetch Nodes
useEffect(() => {
  if (apiInstance) {
    apiInstance.getNodeMap(function (err, nodeMap) {
      if (!err) {
        const nodeList = Object.keys(nodeMap).map(key => {
          return { instanceID: key, ...nodeMap[key] };
        });
        setNodes(nodeList);
        //console.log("nodeList:" ,nodeList);
        console.log("nodes:" ,nodes);
        setSelectedNode(nodeList[0].instanceID); 
      }
    });

    apiInstance.getMaterialList(function (err, materialList) {
      if (!err) {
        //console.log("materialList:", materialList)
        setMaterials(materialList);
        setSelectedMaterial(materialList[0].id); // 
      }
    });
  };
}, apiInstance);

useEffect(() => {
  const handleOutsideClick = (event) => {
    console.log("handleOutsideClick");
    if (contextMenu.visible) {
      setContextMenu({ ...contextMenu, visible: false });
    }
  };

  document.addEventListener('mousedown', handleOutsideClick);

  return () => {
    document.removeEventListener('mousedown', handleOutsideClick);
  };
}, [contextMenu]);

const runAnnotationLoop = (api, annotationsList) => {
  const updateAnnotations = () => {
    const updatedAnnotations = [];
    let completed = 0;

    annotationsList.forEach((annotation, index) => {
      // Call getWorldToScreenCoordinates with a callback function
      api.getWorldToScreenCoordinates(annotation.position, (coord) => {
          // Update the annotation with the new position
          const updatedAnnotation = { ...annotation, position: coord.canvasCoord };
          updatedAnnotations[index] = updatedAnnotation;

          // Increment the completed counter
          completed += 1;

          // Check if all async operations are completed
          if (completed === annotationsList.length) {
              // Update the state with the updated annotations
              setAnnotations(updatedAnnotations);

              // Request the next frame
              requestAnimationFrame(updateAnnotations);
          }
      });
  });
};

  updateAnnotations();
};

const onAnnotationClick = (eye, target, annotationIndex) => {
  apiInstance.lookat(
      eye,
      target,
      0.5,
      () => { console.log('Camera moved to annotation position'); }
  );
  setSelectedAnnotation(annotationIndex); // Update the selected annotation
};

function computeInvisiblePastille() {
  // Create an empty SVG element with minimal size
  var s = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  s.setAttribute('version', '1.1');
  s.setAttribute('baseProfile', 'full');
  s.setAttribute('width', '1');
  s.setAttribute('height', '1');
  s.setAttribute('viewBox', '0 0 1 1');
  s.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

  // Make it base64
  var svg64 = btoa(new XMLSerializer().serializeToString(s));
  var b64Start = 'data:image/svg+xml;base64,';
  var image64 = b64Start + svg64;

  return {
    url: image64
  };
}

function getInvisiblePastilleURL() {
  return computeInvisiblePastille();
}

function resetCamera() {
  apiInstance.setCameraLookAt([1.437, -2.396, 1.915], [0.862, 0.702, 1.024], 2, function(err) {
    if (!err) {
        window.console.log('Camera moved');
        setSelectedAnnotation(null); // Reset the selected annotation
    }
  });
}

// Node Groups
const v41 = [590, 502, 494, 237];
const v42 = [1153, 1172];
const GRAMMOPHONE = [ 478, 451];
const v51 = [582,574,566,590, 502, 494, 237]
const v52 = [1153, 1172,582,574,566,590, 502, 494, 237]
const LAMPADA = [55,1218,1191,66,1145,1137,1026,1037,959,869,312,301,290,3,880,332,967,951]
const LUCI_DX = [662,654,638,630,622,614,606,598,470,462]
const PANNELLI_DX = [398,121]
const TAVOLINO = [354]
const LIBRI_TAVOLINO = [861,837,853,845,826]
const LATTINA = [1164]
const PRIMA_PIANTA = [730,732,705]
const SECONDA_PIANTA = [1207,1226,1234,1242,1258,1250]
const TERZA_PIANTA = [767,740,1266]
const DIVANO = [1080,1072,1064,1053]
const LIBRI_LAMPADA = [1107,1118]
const VALIGIA = [1099,1088]

function hideNodes(nodes) {
  nodes.forEach(node => {
    apiInstance.hide(node, err => {
      if (!err) {
        console.log('Hid node', node);
      } else {
        console.error('Error hiding node', node, err);
      }
    });
  });
}

function showNodes(nodestemp, color) {
  nodestemp.forEach(nodetemp => {
    apiInstance.show(nodetemp, err => {
      if (!err) {
        if (color) {
          console.log('Show node', nodes.find(nodeFound => nodeFound.instanceID === nodetemp));
          apiInstance.assignMaterial(nodes.find(nodeFound => nodeFound.instanceID === nodetemp), color, function (err, result) {
            if (err) {
            console.error('Error applying material:', err);
            console.error("node e color:", nodetemp, color);
            } else {
            console.log('Material applied:', result);
            }
        });
        }
        console.log('Showed node', nodetemp);
      } else {
        console.error('Error showing node', nodetemp, err);
      }
    });
  });
}

// Preset Actions
function handleMensolePreset(variant) {
  switch(variant) {
    case 'A':
        hideNodes(v42);
        hideNodes(VALIGIA)
    break;
    case 'B':
        hideNodes(v51);
        hideNodes(VALIGIA)
    break;
    case 'C':
        showNodes(v52,customMaterialRGBID["silver"]);
        showNodes(v51);
        showNodes(VALIGIA);
    break;
    case 'D':
        showNodes(v52,customMaterialRGBID["black"]);
        showNodes(v51,customMaterialRGBID["black"]);
        showNodes(VALIGIA,customMaterialRGBID["black"]);
    break;
    default:
    console.error('Invalid variant for TV preset:', variant);
  }
}

function handleTavoloPreset(variant) {
  switch(variant) {
    case 'A':
        showNodes(LIBRI_LAMPADA)
        showNodes(GRAMMOPHONE,customMaterialRGBID["silver"])
        hideNodes(DIVANO)
      break;
    case 'B':
        hideNodes(LIBRI_LAMPADA)
        hideNodes(GRAMMOPHONE)
        hideNodes(DIVANO)
      break;
    case 'C':
        showNodes(LIBRI_LAMPADA,customMaterialRGBID["blue"])
        showNodes(GRAMMOPHONE)
        showNodes(DIVANO,customMaterialRGBID["red"])
      break;
    case 'D':
        showNodes(LIBRI_LAMPADA,customMaterialRGBID["black"])
        showNodes(GRAMMOPHONE,customMaterialRGBID["gold"])
        showNodes(DIVANO,customMaterialRGBID["black"])
      break;
    default:
      break;
  }
}

function handleTavolinoPreset(variant) {
  switch(variant) {
    case 'A':
      showNodes(LATTINA,customMaterialRGBID["red"])
      showNodes(LIBRI_TAVOLINO)
      showNodes(TAVOLINO)
      break;
    case 'B':
      hideNodes(LATTINA)
      hideNodes(LIBRI_TAVOLINO)
      showNodes(TAVOLINO)
      break;
    case 'C':
      showNodes(LATTINA)
      showNodes(LIBRI_TAVOLINO)
      showNodes(TAVOLINO,customMaterialRGBID["darkgrey"])
      break;
    case 'D':
      showNodes(LATTINA,customMaterialRGBID["silver"])
      showNodes(LIBRI_TAVOLINO)
      showNodes(TAVOLINO,customMaterialRGBID["blue"])
      break;
    default:

      break;
  }
}

function handlePiantePreset(variant) {
  switch(variant) {
    case 'A':
      hideNodes(PRIMA_PIANTA)
      showNodes(SECONDA_PIANTA)
      showNodes(TERZA_PIANTA)
      break;
    case 'B':
      hideNodes(PRIMA_PIANTA)
      hideNodes(SECONDA_PIANTA)
      showNodes(TERZA_PIANTA)
      break;
    case 'C':
      showNodes(PRIMA_PIANTA,customMaterialRGBID["silver"])
      showNodes(SECONDA_PIANTA,customMaterialRGBID["silver"])
      showNodes(TERZA_PIANTA,customMaterialRGBID["silver"])
      break;
    case 'D':
      showNodes(PRIMA_PIANTA,customMaterialRGBID["blue"])
      showNodes(SECONDA_PIANTA,customMaterialRGBID["blue"])
      showNodes(TERZA_PIANTA,customMaterialRGBID["blue"])
      break;
    default:
      break;
  }
}

function handleMuroPreset(variant) {
  switch(variant) {
      case 'A':
        showNodes(PANNELLI_DX,customMaterialRGBID["silver"]);
        showNodes(LUCI_DX);
      break;
      case 'B':
        hideNodes(PANNELLI_DX);
        showNodes(LUCI_DX,customMaterialRGBID["gold"]);
      break;
      case 'C':
        hideNodes(PANNELLI_DX);
        hideNodes(LUCI_DX);
      break;
      case 'D':
        showNodes(PANNELLI_DX,customMaterialRGBID["black"]);
        showNodes(LUCI_DX,customMaterialRGBID["silver"]);
      break;
      default:
      console.error('Invalid variant for Muro preset:', variant);
  }
}

// Main Interaction Function
function interactAnnotation(annotation, variant) {
  switch(annotation) {
      case 1:
        handleTavoloPreset(variant);
        break;
      case 2:
        handleTavolinoPreset(variant);
        break;
      case 3:
        handlePiantePreset(variant);
        break;
      case 4:
        handleMensolePreset(variant);
        break;
      case 5:
        handleMuroPreset(variant);
        break;
      // Add other cases as needed
      default:
      console.error('Invalid annotation selected:', annotation);
      break;
  }
}

useEffect(() => {
  const overlay = document.querySelector('#rightClickOverlay');

  const handleRightClick = (event) => {
    const iframeBounds = iframeRef.current.getBoundingClientRect();
    if (event.clientX >= iframeBounds.left && event.clientX <= iframeBounds.right &&
        event.clientY >= iframeBounds.top && event.clientY <= iframeBounds.bottom) {
      event.preventDefault(); // Prevent the default context menu
      setContextMenu({
        visible: true,
        x: event.clientX,
        y: event.clientY
      });
      overlay.style.pointerEvents = 'auto'; // Enable pointer events temporarily
      setTimeout(() => {
        setContextMenu({ ...contextMenu, visible: false });
        overlay.style.pointerEvents = 'none'; // Reset pointer events
      }, 2000); // Close context menu after 2 seconds
    }
  };

  document.addEventListener('contextmenu', handleRightClick);

  return () => {
    document.removeEventListener('contextmenu', handleRightClick);
  };
}, [contextMenu]);

const handleClick = (x, y) => {
  const currentTime = new Date().getTime();
  const timeBetweenClicks = currentTime - lastClickTime.current;
  console.log("x e y: ", x, y);

  if (timeBetweenClicks < 500) { // 500 milliseconds for double click
    console.log('Double click detected');
    setContextMenu({ visible: true, x, y }); // Show context menu at click position
    lastClickTime.current = 0;
  } else {
    lastClickTime.current = currentTime;
    setContextMenu({ visible: false, x, y });
  }
};


  return (
    <ParentContainer>
      <ButtonContainer>
        <BackButton onClick={() => resetCamera()}>{"RESET CAMERA"}</BackButton>
      </ButtonContainer>
      <AnnotationsContainer ref={AnnotationsContainerRef}>
        <IFrame
          ref={iframeRef}
          title="Sketchfab Viewer"
          id="player__iframe"
          src=""
        />
      {!isLoading ? (
        <>
        {contextMenu.visible && (
            <div
              style={{
                position: 'absolute',
                left: `${contextMenu.x}px`,
                top: `${contextMenu.y}px`,
                border: '1px solid black',
                backgroundColor: 'white',
                padding: '10px',
                zIndex: 1000
              }}
            >
              <button onClick={() => setContextMenu({ ...contextMenu, visible: false })}>
                Close Context Menu
              </button>
              {/* Add additional context menu items here */}
            </div>
          )}
        {annotations.map((annotation, index) => (
          <AnnotationContainer
          style={{ position: 'absolute',
          transform: `translate(${(annotation.position && annotation.position[0])-12 || 0}px, ${(annotation.position && (annotation.position[1]))-12 || 0}px)`,
          }}>
            <AnnotationElementContainer
                key={index}
                isSelected={selectedAnnotation === index + 1}
                isMoving={isCameraMoving}
                onClick={() => {
                    onAnnotationClick(annotation.eye, annotation.target, index + 1);
                }}>
                <AnnotationNumber></AnnotationNumber>
                <AnnotationTitle>{annotation.name}</AnnotationTitle>
            </AnnotationElementContainer>
            {selectedAnnotation === index + 1 && (
                <AnnotationInteractionContainer>
                  <AnnotationInteractionWrapper>
                    <AnnotationInteractionButton
                      onClick={() => resetCamera()}>
                      {"<"}
                    </AnnotationInteractionButton>
                  {interactionButtons.map(button => (
                    <AnnotationInteractionButton
                      onClick={() => interactAnnotation(index + 1, button)}>
                      {button}
                    </AnnotationInteractionButton>
                  ))}
                  </AnnotationInteractionWrapper>
                </AnnotationInteractionContainer>
                )}
            </AnnotationContainer>
          ))}
          </>
      ) : (<>Loading</>)}
      </AnnotationsContainer>
   </ParentContainer>
   
  );
};

export default SceneTest;