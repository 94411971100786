import React from 'react';

import {DialogContainer, DialogWrapper, ButtonContainer, DialogTitle, ButtonConfirm, ButtonCancel
,DialogChildren} from './dialogElements';

const Dialog = ({ onConfirm, onCancel, title, children }) => {
  return (
    <DialogContainer>
        <DialogWrapper>
        <DialogTitle>{title}</DialogTitle>
        {/* Dialog Content */}
        <DialogChildren>
        {children}
        </DialogChildren>
        <ButtonContainer>
            {/* Buttons */}
            <ButtonConfirm onClick={onConfirm}>Conferma</ButtonConfirm>
            <ButtonCancel onClick={onCancel}>Annulla</ButtonCancel>
        </ButtonContainer>
        </DialogWrapper>
      
    </DialogContainer>
  );
};

export default Dialog;