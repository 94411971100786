import React from "react";
import {BackgroundContainer, LogoWhite, LoginButton, MetaBallContainer} from './landingpageElements.js'
import logo from '../../img/3dportlabwhite.png';

import metaball from '../../img/gifmetaball.gif'
import landingball from '../../img/backloopopen1.gif'

function LandingPage() {

    return(
        <BackgroundContainer>
            <MetaBallContainer metaball={landingball}>
                <LogoWhite src={logo}/>
                <LoginButton to={"/login"}>Login</LoginButton>
            </MetaBallContainer>
        </BackgroundContainer>
    )
}

export default LandingPage;