import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CardContainer = styled.div`
  height: 278px;
  width: 196px;
  background: 
    linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.20)),
    linear-gradient(135deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.08));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-sizing: border-box;
  //padding: 6px;
  color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s linear;
  backdrop-filter: blur(32px);

  &:hover {
    padding: 0px;
  }

  &:hover img { 
    //transform: scale(1.1);
  }
`

export const ContentWrapper = styled.div`    
    height: calc(100% - 12px);
    width: calc(100% - 12px);
    margin: 6px;
    border-radius: 8px;
    color: #eeeeee;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    transition: 0.2s linear;

    ${CardContainer}:hover & {
        height: calc(100% - 0px);
        width: calc(100% - 0px);
        margin: 0px;
    }
`

export const ThumbnailWrapper = styled.div`
    height: 280px;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
`

export const ThumbnailContent = styled.img`
    height: 280px;
    border-radius: 8px 8px 0 0;
    transition: transform .5s ease;
    transform-origin: center center; // Specify the transform origin
    z-index: 1;
    ${CardContainer}:hover & { 
      transform: scale(1.05);
    }
`;

export const GradientOverlayContent = styled.div`
    position: absolute;
    height: 278px;
    width: 196px;
    background: linear-gradient(39deg,rgb(139 45 51 / 50%),rgb(15 22 41 / 50%));    
    border-radius:12px;
    z-index: 2;
    opacity: 1;    
    transition: all .5s ease;
    margin-bottom: -6px;

    ${CardContainer}:hover & {
    margin-bottom: 0px;
      background: linear-gradient(0deg,rgba(0,0,0,0.5),transparent,transparent,transparent,transparent,rgba(0,0,0,0.5));
    }
`

export const TitleWrapper = styled.div`
    position: absolute;
    content: '';
    padding: 8px 0px;
    width: 180px;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    align-content: center;
    text-align: center;
    z-index: 3;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
`

export const TitleContent = styled.div`
    height: 22px;
    word-break: break-word;
    overflow: hidden;
    font-size: 12px;
    line-height: 15px;
`


export const ButtonWrapper = styled.div`
    position: absolute;
    content: '';
    width: 180px;
    margin-top: 8px;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    z-index: 3;
    & img{
        opacity: 67%;
    }

    & img:hover{
        opacity: 1;
}
`

export const ButtonContainer = styled.div`
    display: flex;
    gap: 4px;
    opacity: 0;

    ${CardContainer}:hover & { 
    opacity: 1;
    transition: .5s ease;
    }
`

export const OutputTestButton = styled.button`
width: 78px;
height: 28px;
font-size: 11px;
font-weight: bold;
text-transform: uppercase;
border-radius: 4px;
border: none;
cursor: pointer;
transform: translateZ(20px);
`

export const OutputProdButton = styled.button`
width: 78px;
height: 28px;
font-size: 11px;
font-weight: bold;
text-transform: uppercase;
border-radius: 4px;
border: none;
cursor: pointer;
transform: translateZ(20px);
`

export const SettingsButton = styled.img`
width: 24px;
height: 24px;
cursor: pointer;
`

export const AnalyticsButton = styled.img`
width: 24px;
height: 24px;
cursor: pointer;
`

export const SubmenuButton = styled.img`
width: 24px;
height: 24px;
cursor: pointer;
`

/* SUBMENU */
export const SubMenuContainer = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  right: 2px;
  top: 30px;
  width: 176px;
  background: #F8FAFC;
  border-radius: 8px;
`

export const SubMenuWrapper = styled.div`
  display:flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
`

export const SubMenuRow = styled.div`
  display:flex;
  flex-direction: row;
    align-content: center;
    align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #334155;
  cursor: pointer;
`

export const SubMenuRowLink = styled(Link)`
  display:flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #334155;
  cursor: pointer;
  text-decoration: none;
`

export const SubMenuIcon = styled.img`
    height: 24px;
    width: 24px;
`

export const SubMenuLine = styled.div`
  display:flex;
  height: 1px;
  background-color: #E2E8F0;
`
