import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SidebarWrapper = styled.div`
    display: flex;
    height: 71px;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    background: ${props => (props.bgColor ? props.bgColor : '#0f172acc')} ;
    border-bottom: 1px rgb(255 255 255 / 0.08) solid;
    z-index: 1;
`

export const LogoWrapper = styled(Link)`
    margin-left: 64px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    & img{
        height: 48px;
    }
`

export const Spacer = styled.div`
    height: 2px;
    width: 48px;
    background-color: darkgray;
    margin: 4px 0;
`

export const ButtonWrapper = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    color: #94A3B8;
    text-decoration: none;
`

export const LinkButton = styled(Link)`
    color: #94A3B8;
    text-decoration: none;
    cursor: pointer;

    &:hover{
        color: #F8FAFC;
    }

    &:active{
        color: #F8FAFC;
        font-weight: bold;
    }
`

export const ButtonImg = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: none;
    background-position: center;
    background-size: contain;
    cursor: pointer;
`

export const NavigationMenuWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 64px;
`

export const LogoutWrapper = styled.div`
    height: 60px;
    width: 60px;
`

export const LeftSideSidebar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;    
`

export const RigthSideSidebar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 64px;
    margin-right: 64px;
`