import styled from 'styled-components';

export const DialogContainer = styled.div`
position: fixed;
top: 0px;
bottom: 0px;
background: rgba(0,0,0,0.5);
width: 100vw;
height: 100vh;
display: flex;
align-content: center;
justify-content: center;
align-items: center;
z-index: 9999;
`

export const DialogWrapper = styled.div`
    min-width: 340px;
    min-height: 180px;
    background: rgb(22 31 44);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px;
`

export const ButtonConfirm = styled.div`
    background: linear-gradient(135deg,rgba(249, 115, 22, 1),rgba(194, 65, 12, 1));
    height: 32px;
    flex-grow: 1;
    color: #F8FAFC;
    font-family: 'Space Grotesk', sans-serif;
    text-transform: uppercase;
    padding: 0 16px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 16px;
    line-height: 32px;
`

export const ButtonCancel = styled.div`
    background: #334155;
    height: 32px;
    flex-grow: 1;
    color: #F8FAFC;
    font-family: 'Space Grotesk', sans-serif;
    text-transform: uppercase;
    padding: 0 16px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 16px;
    line-height: 32px;
`

export const DialogTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 16px 16px 0px 0px;
    font-weight: bold;
    text-transform: uppercase;
    background: rgba(255,255,255,0.08);
`

export const DialogChildren = styled.div`
    flex-grow: 1;
    padding: 16px;
`