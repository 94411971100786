import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import useAuth from '../../hooks/useAuth.js';
import Sidebar from '../sidebar/index.js';
import { useLocation } from 'react-router-dom';

import AnalyticsAccount from '../analyticsaccount/index.js'
import AnalyticsDetail from '../analyticsdetail/index.js';
import AnalyticsModelSelection from '../analyticsmodelselection/index.js'

import {SidebarContainer,ViewContainer,BodyContainer,HeaderBarContainer,HeaderBarWrapper,HeaderTabElement,
    HeaderTabsContainer,HeaderTitle,DatePicker,DatePickerWrapper,HeaderRowWrapper} from './analyticsnewElements.js'

const AnalyticsNew = (props) => {
    //const APIURL = 'https://test2.3dabackend.com';
    const APIURL = `${process.env.REACT_APP_API_BASE_URL}`;
    const apiGetModels = APIURL + (process.env.REACT_APP_TESTING === 'true' ? "/forum/users/models/" : "/forum/user/models/");
    const SFtoken = '409eb8f52291eaf0c2c824c916d6b5af';
    const { auth } = useAuth();
    const location = useLocation();

    console.debug('Analytics Location State: ' , location.state);

    // State variables for start and end dates
    const [startDate, setStartDate] = useState('2024-01-01');
    const [tempStartDate, setTempStartDate] = useState('2024-01-01');

    // Function to add days to a date
    const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };
 
    const tomorrow = addDays(new Date(), 1).toISOString().split('T')[0];

    const [noAnalytics, setNoAnalytics] = useState(false);
    const [goodAnalyticsData, setGoodAnalyticsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTab, setSelectedTab] = useState(
        location.state?.selectedModelNumber != null ? 'AnalyticsDetail' : 'AccountAnalytics'
    );
    const [selectedModel, setSelectedModel] = useState(location.state?.selectedModelNumber != null ? location.state?.selectedModelNumber : -1);
    const [models, setModels] = useState([]);    
    const [modelsUrl, setModelsUrl] = useState([]);

    const [tempEndDate, setTempEndDate] = useState(tomorrow);
    const [endDate, setEndDate] = useState(tomorrow);

    const [dataState, setDataState] = useState({
        sortedModels: 0,
        secondaryData: 0,
        modelPreliminaryData: 0,
        totViews: 0,
        totViewsAr: 0,
        totViewsVr: 0,
        totInteractions: 0,
        meanVisualizationTime: 0,
        mostViewedModelIndex : 0,
        mostViewedArModelIndex : 0,
        mostViewedVrModelIndex : 0,
        mostViewedModel_viewnumber : 0,
        mostViewedArModel_viewnumber : 0,
        mostViewedVrModel_viewnumber : 0,
        totalSumViews: 0,
        totalSumViewsAr: 0,
        totalSumViewsVr: 0,
        totalSumInteractions: 0,
        dataLoaded: false
    })

    const debounce = (func, delay) => {
        let debounceTimer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        }
    }
    
    const debouncedSetStartDate = debounce(setStartDate, 1000);
    const debouncedSetEndDate = debounce(setEndDate, 1000);

// Fetch models when the component mounts
useEffect(() => {
    const fetchModels = async () => {
        try {
            console.log('Fetching models...');
            const id = auth.id;
            const modelsResponse = await axios.post(apiGetModels, { idUtente: id }, {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': id }
            });
            
            const isTesting = process.env.REACT_APP_TESTING === 'true';
            const responseData = isTesting ? modelsResponse.data.data : modelsResponse.data.message;
      
            console.log("apigetmodels response");
            console.log(modelsResponse);

            const models = responseData.modelli;
            console.log('Fetched models:', models);
            setModels(models);
        } catch (error) {
            console.error("Error fetching models:", error);
        }
    };

    fetchModels();
}, [auth.id, auth.accessToken]);

// Fetch analytics data when the start date or end date changes
useEffect(() => {
    console.log('Running useEffect hook. Models length:', models.length);
    try {
    const fetchAnalyticsData = async () => {
        console.log('Fetching analytics data...');
        try {
            // Reset the total sum variables
            setDataState(prevData => ({
                ...prevData,
                totalSumViews: 0,
                totalSumViewsAr: 0,
                totalSumViewsVr: 0,
                totalSumInteractions: 0,
            }));

            // Fetch data for each model
            const promises = models.map((model) => {
                return axios.get(`https://api.sketchfab.com/v3/models/${model.uid}`, { headers: { 'Authorization': 'Token ' + SFtoken } });
            });
            const responses = await Promise.all(promises);
            const data = responses.map((response) => response.data);
    
            // Sort models based on createdAt
            const sortedModels = models
                .map((model, index) => ({
                    ...model,
                    createdAt: new Date(models[index].sfDoc.createdAt)
                }))
                .sort((a, b) => b.createdAt - a.createdAt);

            // Order the data based on sortedModels
            const sortedData = sortedModels.map(sortedModel => {
                return responses.find(response => response.data.uid === sortedModel.uid).data;
            });
            
            // Fetch the analytics data for each model
            const modelDataPromises = sortedModels.map(fetchDataForModel);
            const modelDataResponses = await Promise.all(modelDataPromises);

            // Update modelPreliminaryData in dataState
            setDataState(prevData => ({
                ...prevData,
                modelPreliminaryData: modelDataResponses.map(response => response.data),
            }));    

            // Fetch analytics data
            const analyticsResponse = await axios.post(APIURL + '/tacito/analytics', {
                id_utente: auth.id,
                start_date: startDate,
                end_date: endDate
            }, {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
            }).then(response => {
                const responseData = response?.data?.data ?? {};

                // Check if responseData is null
                if (!responseData) {
                    throw new Error('Response data is null');
                }
                
                // Loop through each key in the data object and set null values to 0
                for (let key in responseData) {
                    if (responseData[key] === null) {
                        responseData[key] = 0;
                    }
                }
                
                console.debug("responseData inside then: ",responseData);
                setGoodAnalyticsData(responseData);
                // Continue with your logic using the modified responseData

                            
            const analyticsData = goodAnalyticsData;
            console.debug("goodAnalyticsData: ", goodAnalyticsData)

    
            // Extract model IDs with the most views
            const mostViewedModelID = responseData?.max_views_model?._id ?? -1;
            const mostViewedArModelID = responseData?.max_views_model_ar?._id ?? -1;
            const mostViewedVrModelID = responseData?.max_views_model_vr?._id ?? -1;
            const mostViewedModel_viewnumber = responseData?.max_views_model?.total ?? -1;
            const mostViewedArModel_viewnumber = responseData?.max_views_model_ar?.total ?? -1;
            const mostViewedVrModel_viewnumber = responseData?.max_views_model_vr?.total ?? -1;
    
            // Log the results
            console.debug("Models:", models);
            console.debug("Data for each model:", data);
            console.debug("sortedData for each model:", sortedData);
            console.debug("Sorted models:", sortedModels);
            console.debug("Analytics data:", analyticsData);
            console.debug("responseData.vis_tot.sum_views: ", responseData?.vis_tot.sum_views ?? 0);
            console.debug("responseData.vis_tot.sum_views_ar: ", responseData?.vis_tot.sum_views_ar ?? 0);
            console.debug("responseData.vis_tot.sum_views_vr: ", responseData?.vis_tot.sum_views_vr ?? 0);
            console.debug("analyticsData.vis_tot.sum_interactions: ", responseData?.vis_tot.sum_interactions ?? 0);
            console.debug("analyticsData.mean_vis_time.avg_view_time: ", responseData?.mean_vis_time.avg_view_time ?? 0);
            console.debug("Most viewed model ID:", mostViewedModelID ?? -1);
            console.debug("Most viewed AR model ID:", mostViewedArModelID ?? -1);
            console.debug("Most viewed VR model ID:", mostViewedVrModelID ?? -1);
            console.debug("Most viewed model view number:", responseData?.max_views_model.total?? -1);
            console.debug("Most viewed AR model view number:", responseData?.max_views_model_ar.total?? -1);
            console.debug("Most viewed VR model view number:", responseData?.max_views_model_vr.total?? -1);

            // 1. Getting the index of the mostViewedModels in sortedModels
            const mostViewedModelIndex = sortedModels.findIndex(model => model._id === mostViewedModelID);
            console.debug("Index of most viewed model:", mostViewedModelIndex);
            const mostViewedArModelIndex = sortedModels.findIndex(model => model._id === mostViewedArModelID);
            console.debug("Index of most viewed AR model:", mostViewedArModelIndex);
            const mostViewedVrModelIndex = sortedModels.findIndex(model => model._id === mostViewedVrModelID);
            console.debug("Index of most viewed AR model:", mostViewedVrModelIndex);

            // 2. Storing the mostViewedModels directly
            const mostViewedModel = sortedModels.find(model => model._id === mostViewedModelID);
            console.debug("Most viewed model:", mostViewedModel);
            const mostViewedArModel = sortedModels.find(model => model._id === mostViewedArModelID);
            console.debug("Most viewed Ar model:", mostViewedArModel);
            const mostViewedVrModel = sortedModels.find(model => model._id === mostViewedVrModelID);
            console.debug("Most viewed Vr model:", mostViewedVrModel);

            // setting the dataState const.
            setDataState(prevData => ({
                ...prevData,
                sortedModels: sortedModels,
                secondaryData: sortedData,
                totViews: responseData?.vis_tot.sum_views ?? 0,
                totViewsAr: responseData?.vis_tot.sum_views_ar ?? 0,
                totViewsVr: responseData?.vis_tot.sum_views_vr ?? 0,
                totInteractions: responseData?.vis_tot.sum_interactions ?? 0,
                meanVisualizationTime: responseData?.mean_vis_time.avg_view_time ?? 0,
                mostViewedModelIndex : mostViewedModelIndex ?? -1,
                mostViewedArModelIndex : mostViewedArModelIndex ?? -1,
                mostViewedVrModelIndex : mostViewedVrModelIndex ?? -1,
                mostViewedModel_viewnumber : mostViewedModel_viewnumber ?? 0,
                mostViewedArModel_viewnumber : mostViewedArModel_viewnumber ?? 0,
                mostViewedVrModel_viewnumber : mostViewedVrModel_viewnumber ?? 0,
                dataLoaded: true
            }));

            // console.debug('dataState: ' , dataState);
            })
            .catch(error => {
                console.error("Error fetching analytics:", error);
                setError(error.message);
            });

            // Set isLoading to false
            setIsLoading(false);
            
            // Log the total sums
            // console.log('Total sum of views:', totalSumViews);
            // console.log('Total sum of AR views:', totalSumViewsAr);
            // console.log('Total sum of VR views:', totalSumViewsVr);
    
        } catch (error) {
            console.error("Error fetching and processing data:", error);
            setIsLoading(false);
        };
    }
        
        if (models.length > 0) {
            console.log('Models length is greater than 0. Calling fetchAnalyticsData...');
            fetchAnalyticsData();
        }
    } catch (error) {
        console.error('Error defining fetchAnalyticsData function:', error);
    }
}, [models, startDate, endDate, auth.id, auth.accessToken]);

    const switchToSingleModel = (model,modelnumber) => {
        console.debug('switchToSingleModel modelnumber: ', modelnumber);
        console.debug('switchToSingleModel model: ', model);
        setSelectedModel(modelnumber);
        //setSelectedTab('SingleModel');
    }

    const switchToAllModels = () => {
        setSelectedTab('ModelTab');
        setSelectedModel(-1);
    }

    // Define an async function to fetch the data for each model
    async function fetchDataForModel(model) {
        try {
            const response = await fetch(APIURL + '/tacito/model/sum', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.accessToken}`,
                'id': auth.id,
                '3d-portlab-api-key': '22646265-e26f-4158-92c6-db812bdf9524',
                },
                body: JSON.stringify({
                start_date: startDate,
                end_date: endDate,
                fields: {
                    id_modello: model._id
                }
                })
            });
        
            const data = await response.json();
            
            // If data is null, return from the function
            if (!data.success || !data.data) {
                console.log('Data for model', model._id, 'is null');
                return {};
            }

            console.log('Data for model', model._id, ':', data);

            // Increment the total sum variables in dataState
            setDataState(prevData => ({
                ...prevData,
                totalSumViews: prevData.totalSumViews + (data.data.sum_views || 0),
                totalSumViewsAr: prevData.totalSumViewsAr + (data.data.sum_views_ar || 0),
                totalSumViewsVr: prevData.totalSumViewsVr + (data.data.sum_views_vr || 0),
                totalSumInteractions: prevData.totalSumInteractions + (data.data.sum_interactions || 0)
            }));

            return data;
        } catch (error) {
            console.error('Error fetching data for model', model._id, ':', error);
            return {}; // Return an empty object when the request fails
        }
    }

    useEffect(() => {
        if (selectedModel >= 0) {
            setSelectedTab('SingleModel');
        }
    }, [selectedModel]);

    return (
        <>
        <SidebarContainer>
            <Sidebar/>
        </SidebarContainer>
        <ViewContainer>
            <HeaderBarContainer>
                <HeaderBarWrapper>
                    <HeaderRowWrapper>
                        <HeaderTitle>Analytics</HeaderTitle>
                        <DatePickerWrapper>
                            Da:
                            <DatePicker 
                                type="date" 
                                value={tempStartDate} 
                                onChange={(e) => setTempStartDate(e.target.value)} 
                                onBlur={(e) => setStartDate(tempStartDate)}
                            />
                            A:
                            <DatePicker 
                            value={tempEndDate} 
                            onChange={(e) => setTempEndDate(e.target.value)} 
                            onBlur={(e) => setEndDate(tempEndDate)}
                            /> 
                        </DatePickerWrapper>
                    </HeaderRowWrapper>
                    <HeaderTabsContainer>
                        <HeaderTabElement isActive={selectedTab == 'AccountAnalytics'} onClick={() => {setSelectedTab('AccountAnalytics'); setSelectedModel(-1)}}>Generali</HeaderTabElement>
                        <HeaderTabElement isActive={selectedTab == 'ModelTab' | selectedTab ==  'SingleModel'} onClick={() => {setSelectedTab('ModelTab'); setSelectedModel(-1)}}>Modelli</HeaderTabElement>
                    </HeaderTabsContainer>
                </HeaderBarWrapper>
            </HeaderBarContainer>
            <BodyContainer>
            {error ? (
                <div className="error-message">
                    <p>{error}</p>
                </div>
            ) : (
                <>
                    { !isLoading ? (
                    <>
                    {selectedTab === 'AccountAnalytics' && dataState.dataLoaded && 
                    <AnalyticsAccount 
                    modelsUrl={modelsUrl} 
                    data={dataState}
                    setSelectedModel={setSelectedModel}
                    />}

                    {selectedTab === 'ModelTab' && 
                    <AnalyticsModelSelection 
                    data={dataState} 
                    onModelSelect={switchToSingleModel} 
                    />}

                    {selectedTab === 'SingleModel' && 
                    <AnalyticsDetail 
                    selectedModel={selectedModel} 
                    data={dataState} 
                    switchToAllModels={switchToAllModels}
                    startDate={startDate}
                    endDate={endDate}
                    />}

                    </>
                    ) 
                    : ( 
                        <>loading</>
                    )}
                </>
            )}
            
            </BodyContainer>
        </ViewContainer>
        </>
    )
}

export default AnalyticsNew;