import React, {useState, useEffect, useRef} from 'react';
import { CenterWrapper, ModelBoxContainer}  from './analyticsmodelselectionElements.js';
import ModelBox from '../modelbox/index.js';

const AnalyticsModelSelection = (props) => {

    console.log("props in AnalyticsModelSelection: ", props)
    const models = props.data.sortedModels;
    const switchToSingleModel = props.onModelSelect;

return (
    <CenterWrapper>
        <ModelBoxContainer> 
            {models ? models.map((model, key) => (
                <ModelBox model={model} key={key} modelNumber={key} modelsUrl={props.data.secondaryData[key].thumbnails.images[2].url} handleClick={ () => {console.log("switch inside analytics model selection: ", model, key); switchToSingleModel(model, key)}} />
            )) : "Nessun modello disponibile"}
        </ModelBoxContainer>
    </CenterWrapper>
)

}

export default AnalyticsModelSelection