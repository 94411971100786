import React from 'react';

import Sidebar from '../sidebar/index.js';
import {SidebarContainer,ViewContainer,HeaderBar,ContentWrapper} from './uploadpageElements.js';
import UploadForm from '../uploadform/index.js';
import useAuth from '../../hooks/useAuth.js';

const UploadPage = (props) => {

    const {auth} = useAuth();

    return(
        <>
        <SidebarContainer>
            <Sidebar/>
        </SidebarContainer>
        <ViewContainer>
            <HeaderBar>
                
            </HeaderBar>
            <ContentWrapper>
                <UploadForm props={props} selectedUser={auth.id}></UploadForm>
            </ContentWrapper>
        </ViewContainer>
        </>
    )
}

export default UploadPage;