import styled from 'styled-components';

export const PopupContainer = styled.div`
    display: flex;
    position: fixed;
    background: rgba(15,23,42,0.67);
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 10;
`   

export const PopupWrapper = styled.div`
    position: relative;
    display: flex;
    padding: 16px;
    background: linear-gradient(45deg, rgb(255 255 255 / 16%), rgb(255 255 255 / 8%));
    border-radius: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    backdrop-filter: blur(32px);

`   

export const ButtonClose = styled.img`
    position: absolute;
    right: 32px;
    top: 32px;
    height: 24px;
    width: 24px;
    font-size: 16px;
    border-radius: 50%;
    cursor: pointer;
` 