import useAuth from './useAuth';

const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = () => {
    // Clear the localStorage
    localStorage.removeItem("auth");

    // Reset the auth state
    setAuth({});
  };

  return logout;
};

export default useLogout;