import styled from 'styled-components';

export const ModalOverlay = styled.div`
    display: flex;
    position: fixed;
    background: rgba(15,23,42,0.67);
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 10;
`;

export const ModalContent = styled.div`
    position: relative;
    display: flex;
    padding: 16px;
    background: linear-gradient(45deg, rgb(255 255 255 / 16%), rgb(255 255 255 / 8%));
    border-radius: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    backdrop-filter: blur(32px);
`;

export const ModalTitle = styled.h2`
    margin-top: 0;
`;

export const ModalButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button {
        margin-left: 10px;
    }
`;