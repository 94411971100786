import React, {useState} from 'react';
import { PreviewContainer, PreviewWrapper, IFrameElement, IFrameWrapper,
  PreviewTitle, PreviewTitleWrapper, PreviewLogo, FooterWrapper, EmbedButton,EmbedIcon,
BackButton,BackButtonIcon } from './outputpreviewElements';
import { useParams } from 'react-router-dom';
import logo from '../../img/3dalogowhite64.png';
import codeicon from "../../img/code24icon.png";
import backicon from '../../img/back24icon.png';

const OutputPreview = (props) => {
  const { modelUid, authId, mode , modelName} = useParams();
  const [copied, setCopied] = useState(false);    
  var url = ``;

  if( mode === 'test'){
    url = `https://test.3danimate.it/` + authId  +`/${modelUid}test`;
  } else {
    url = `https://test.3danimate.it/` + authId  +`/${modelUid}`;
  }

  const handleCopyToClipboard = () => {
    const urlToCopy = url; 

    navigator.clipboard.writeText(urlToCopy)
        .then(() => {
        setCopied(true); // Set copied state to true
        setTimeout(() => {
            setCopied(false); // Reset copied state after 2 seconds
        }, 2000);
        })
        .catch((error) => {
        console.error('Failed to copy URL to clipboard:', error);
    });
}

  return (
    <>
    <PreviewContainer>
      <PreviewWrapper>
        <PreviewTitleWrapper>
          <BackButton to={"/dashboard"}>
              <BackButtonIcon  src={backicon}></BackButtonIcon>
          </BackButton>
          <PreviewTitle>{modelName}</PreviewTitle>
          <PreviewLogo src={logo}/>
        </PreviewTitleWrapper>
        <IFrameWrapper>
          <IFrameElement src={url} width="100%" height="100%"></IFrameElement>
        </IFrameWrapper>
        <FooterWrapper>
        <EmbedButton
            style={{
              backgroundColor: copied
                ? (mode === 'prod' ? '#EF580C' : mode === 'test' ? '#4f46e5' : 'rgba(0,0,0,0.2)')
                : (mode === 'prod' ? 'rgba(239, 88, 12, 0.2)' : mode === 'test' ? 'rgba(79, 70, 229, 0.2)' : 'rgba(0,0,0,0.2)'),
              transition: 'background-color 0.3s ease',
            }}
            onClick={handleCopyToClipboard}
          >
            <EmbedIcon src={codeicon} />
            {copied ? 'Copiato!' : url}
          </EmbedButton>
        </FooterWrapper>
      </PreviewWrapper>
    </PreviewContainer>
    </>
  );
};

export default OutputPreview;