import styled from 'styled-components';

export const PanelWrapper = styled.div`
  display: flex;
  backdrop-filter: blur;
  background: rgba(30, 41, 59, 0.4);
  flex-grow: 1;
  margin: 8px;
  border-radius: 8px;
`;

export const PanelContent = styled.div`
  border-radius: 16px;
  padding: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const FirstSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 24px;
  height: 100%;
`;

export const SecondSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 24px;
  height: 100%;
`;

export const LargePanel = styled.div`
  border: 1px solid rgba(255,255,255,0.08);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.08));
    display: flex;
    align-content: center;
    justify-content: center;
`;

export const LargestPanel = styled.div`
  border: 1px solid rgba(255,255,255,0.08);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.08));
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
    display: flex;
    align-content: center;
    justify-content: center;
`;

export const SmallestPanel = styled.div`
  border: 1px solid rgba(255,255,255,0.08);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  background: 
    linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.08));
  box-sizing: border-box;
    display: flex;
    align-content: center;
    justify-content: center;
`;

export const ColumnContainer = styled.div`
  display: grid;
  gap: 24px;
  flex-direction: column;
  grid-row: 1 / span 3;
`;

export const DescriptionContainer = styled.div`
  font-size: 12px;
  color: #94A3B8;
  text-transform: uppercase;
  text-align: left;
`;

export const IconContainer = styled.img`
  padding: 16px 0;
  width: 36px;
  height: 36px;
`;

export const ValueContainer = styled.div`
font-size: 28px;
color: #F8FAFC;
font-weight: bold;
`;

export const LargePanelDescription = styled.div`
  font-size: 14px;
`;

export const LargePanelName = styled.div`
  color: #94A3B8;
  font-size: 16px;
    text-align: left;
`;

export const LargePanelValueName = styled.div`
  font-size: 12px;
  color: #94A3B8;
`;

export const LargePanelValueNumber = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const LargePanelValueRow = styled.div`
  display: flex;
  flex-direction: row;
    gap: 8px;
`;

export const LeftPanelColumn = styled.div`
  display: flex;
  flex-grow: 1;
  background: #ddd;
    height: 100%;
    max-width: 160px;
    width: 50%;
    overflow: hidden;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
`;

export const RightPanelColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
`;

export const LargeImagePanel = styled.img`
  min-width: 72px;
    flex-grow: 1;
    height: 180px;
`;

export const LargePanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-content: center;
  align-items: center;
  margin: -12px;
  border-radius: 8px;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  width: 100%;
  color: #F8FAFC;
  overflow-y: auto;
    height: 100%;

  @media (max-width: 1023px) {
  grid-template-columns: 1fr;
    height: 100%;
  }
  ::-webkit-scrollbar {
    width: 0 !important
    }

`

export const PanelLineContent = styled.div`
  border-radius: 16px;
  /*padding: 12px;*/
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  & canvas {
    
    aspect-ratio: auto;
  }
`

export const LargePanelLink = styled.a`
    text-align: left;
    font-size: 12px;
    cursor: pointer;
`;