import React, { useState, useEffect, useRef } from 'react';
import { BlockPicker, ChromePicker } from 'react-color';

import {SwitchContainer,HiddenCheckbox,RoundSlider, RoundSliderThumb,Slider,SliderThumb,
  ColumnLayout,InputDescription,InputLabel,InputLeftSection,RowLayout, InputNumber, 
  InputText, InputSelect, PremiumTag,ColorPickerContainer,ColorPickerInput} from './configurationbuttonElements';

const ConfigurationButton = ({ 
  id, 
  type, 
  label,
  description,
  initialState, 
  onChange, 
  options, 
  min, 
  max, 
  step, 
  mode,
  disabled: parentDisabled,
  premium
   }) => {
  const [checked, setChecked] = useState(initialState);
  const [selectedOption, setSelectedOption] = useState(type === 'dropdown' ? initialState : null);
  const [numericValue, setNumericValue] = useState(initialState);
  const [textValue, setTextValue] = useState(initialState);
  const [color, setColor] = useState(initialState);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(parentDisabled);
  }, [parentDisabled]);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    onChange(id, event.target.checked ? 1 : 0, type);
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    onChange(id, event.target.value, type);
  };

  const handleNumericInputChange = (event) => {
    let inputValue = parseFloat(event.target.value, 10);
    inputValue = Math.max(min, Math.min(max, inputValue));

    setNumericValue(inputValue);
    onChange(id, inputValue, type);
  };

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
    onChange(id, event.target.value, type);
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
    onChange(id, color.hex, type);
  };

  return (
    <>
      {type === 'checkbox' && (
        <RowLayout  disabled={disabled}>
          <InputLeftSection>
            {premium && (
              <PremiumTag>Funzionalità Premium</PremiumTag>
            )}
            <InputLabel>{label}</InputLabel>
            <InputDescription>{description}</InputDescription>
          </InputLeftSection>
          <SwitchContainer>
            <HiddenCheckbox
              checked={checked}
              onChange={handleCheckboxChange}
              disabled={disabled}
            />
              <Slider checked={checked} mode={mode}>
                <SliderThumb checked={checked}/>
              </Slider>
          </SwitchContainer>
        </RowLayout>
      )}
      {type === 'dropdown' && (
        <ColumnLayout disabled={disabled}>
          <InputLabel>{label}</InputLabel>
          <InputDescription>{description}</InputDescription>
          <InputSelect value={selectedOption} onChange={handleDropdownChange} disabled={disabled} >
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </InputSelect>
        </ColumnLayout>
      )}
      {type === 'number' && (
        <ColumnLayout disabled={disabled}>
          <InputLabel>{label}</InputLabel>
          <InputDescription>{description}</InputDescription>
          <InputNumber
            type="number"
            value={numericValue}
            onChange={handleNumericInputChange}
            min={min}
            max={max}
            step={step}
            disabled={disabled}
          />
        </ColumnLayout>
      )}
      {type === 'text' && (
        <ColumnLayout disabled={disabled}>
          <InputLabel>{label}</InputLabel>
          <InputDescription>{description}</InputDescription>
          <InputText
            type="text"
            value={textValue}
            onChange={handleTextChange}
            disabled={disabled}
          />
        </ColumnLayout>
      )}
      {type === 'colorpicker' && (
        <ColumnLayout disabled={disabled}>
          <InputLabel>{label}</InputLabel>
          <InputDescription>{description}</InputDescription>
          <ColorPickerContainer>
              <ColorPickerInput onClick={() => setShowColorPicker(true)} value={color||"#000000"} readOnly>
                {color||"#000000"}
              </ColorPickerInput>
              {!disabled && (
                <BlockPicker color={color||"#000000"} onChange={handleColorChange} 
                onChangeComplete={()=>setShowColorPicker(false)}
                />
              )}
          </ColorPickerContainer>
        </ColumnLayout>
      )}
    </>
  );
};

export default ConfigurationButton;