import React, {useState} from "react";
import useAuth from '../../hooks/useAuth.js';
import axios from "../../api/axios.js";
import { ChromePicker } from "react-color";

import {ButtonAction,DBAformcontainer,DBAformline,DBAformwrapper,SingleInput,SingleName,LogoUploader,
InternalButton,InternalCancelButton} from './dbanewuserElements.js';
import UploadLogoForm from "../uploadlogoform/index.js";

const DbaNewUser = () => {
  const { auth } = useAuth();
  //console.log("DBA New User AUTH:");
  //console.log(auth.id);
  //console.log(auth.accessToken);

  //const apiURL = "https://test2.3dabackend.com"; + aggiungi censor/ prima della registration
  const apiURL = `${process.env.REACT_APP_API_BASE_URL}`;

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState("#000000");
  const [messageError, setMessageError] = useState(false);
  const [idCreatedUser, setIdCreatedUser] = useState(null);   
  const [step, setStep] = useState(1);

  const [formData1, setFormData1] = useState({
    username: '',
    password: '',
    role: 1,
    email: '',
  });

  const [formData2, setFormData2] = useState({
    nomeAnagrafico: '',
    url: '',
    description: '',
    });

  const [formData3, setFormData3] = useState({
    logo: "",
    useDefaultLogo: true,
    });

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleChange1 = (e) => {
    const value = e.target.name === 'role' ? parseInt(e.target.value) : e.target.value;
    setFormData1({
      ...formData1,
      [e.target.name]: value
    });
  };

  const handleChange2 = (e) => {
    setFormData2({
      ...formData2,
      [e.target.name]: e.target.value
    });
  };

  const handleChange3 = (e) => {
    setFormData3({
      ...formData3,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(step === 1){
      // CREATE USER
      console.log(formData1.username, formData1.password, formData1.role, formData1.email);
      createUser(formData1.username, formData1.password, formData1.role, formData1.email);

    } else if (step === 2){
      // CREATE PROFILE
      //createProfile(formData2.nomeAnagrafico, formData2.url, formData2.color);
      const stringIdCreatedUser = idCreatedUser.toString();
      const stringCollection = "3DA" + stringIdCreatedUser;
      console.log(stringCollection);
      createProfile(idCreatedUser,stringCollection, formData2.description, formData2.nomeAnagrafico, formData2.url, color)
    } else if (step === 3){
      // UPLOAD LOGO
      if(formData3.useDefaultLogo){
        // DEFAULT LOGO
        console.log("default logo")
        console.log(idCreatedUser);
        console.log(formData3.useDefaultLogo);
        uploadLogo(idCreatedUser, '',  formData3.useDefaultLogo);
      } else {
        // GIVEN LOGO
        console.log("given logo")
        uploadLogo(idCreatedUser, formData3.logo, false);
      }

    } else if (step === 4){
      // ACTIVATE USER
      activateUser(idCreatedUser);

    } else { 
      console.log("errore submit"); 
      return false; 
    }
  };

  const handleLogoUpload = (logoUrl) => {
    setFormData3({
      ...formData3,
      logo: logoUrl
    });
    setStep(step + 1);
  };

  // Create a new user
  const createUser = (usr, psw, role, email) => {
    axios.post( apiURL +
      '/censor/admin/registration',
      {
        usr: usr,
        psw: psw,
        role: role,
        email: email
      },
      {
        headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id,
                // 'ngrok-skip-browser-warning': 'true',
                 },
        params: {
          "idUtente": auth.id
        }
      }
    )
    .then(response => {
      // handle success
      // if success setStep(2);
      // prendo id_Utente
      if(response.data.success){
        console.log("registration success");
        //setIdCreatedUser(response.data?.idUtente);
        setIdCreatedUser(response.data?.data?.id);
        console.log(idCreatedUser);
        console.log(response.data.data.id);
        console.log(response);
        setStep(2);
      }
      if(!response.data.success){
        if(response.status === 200){
          //setMessageError(response.data.message);
          setMessageError(response.data.data.message);
          console.log(response.data.idUtente);
          console.log(response.data?.message);
          //setIdCreatedUser(response.data.idUtente);
          setIdCreatedUser(response.data.data.id);
          setStep(2);
        } else if (response.status === 400){
          console.log("registration 400");
          setMessageError(response.data.message);
          setIdCreatedUser(response.data.data.idUtente);
          setStep(2);
        } else {
          console.log("registration not success and not 200");
        }
      }
    })
    .catch(error => {
      console.log("registration error");
      setMessageError(error);
      console.log(error.response);
      console.log(error.response?.data);
      console.log(error.response?.status);
      console.log(error.response?.headers);
    });
  };

  // Create a new profile
const createProfile = (idCreatedUser,collection, description, anagrafica, url, color) => {
  console.log("idCreatedUser, collection, description, anagrafica, url, color: ",idCreatedUser,collection, description, anagrafica, url, color)
  axios.post( apiURL +
    '/portus/collection/create',
    { 
      idUtente: idCreatedUser,
      name: collection,
      description: description,
      anagrafica: anagrafica,
      urlUtente: url,
      coloreUtente: color,
    },
    {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id,
      // 'ngrok-skip-browser-warning': 'true', 
    }
    }
  )
  .then(response => {
    // handle success
    if(response.data.success){
    //if(response.data?.data?.success){
      console.log("/portus/collection/create SUCCESS CREATE PROFILE");
      console.log(response);
      setStep(3);
    }
    else{
      console.log("error create profile");
      console.log(response);
      setMessageError(response.message);
      if(response.data.message === 'You already have a collection with this name'){
        setStep(3);
      }
    }
    // if success setStep(3);

  })
  .catch(error => {
    // handle error
    console.log("/portus/collection/create: ",error);
    setMessageError(error);
  });
};

// Upload a logo file
const uploadLogo = (idUtente, logoFile, isDefault) => {
  console.log("upload logo function")
  const formData = new FormData();

  formData.append('idUtente', idUtente);

  if(logoFile){
    formData.append('logo', logoFile);
  } else {
    formData.append('logo', '');
  }

  formData.append('default', isDefault )
  console.log(formData["idUtente"])
  console.log(formData["default"])
  
  axios.post(apiURL + '/forum/ftp/upload/logo', formData, {
    headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 
               'id' : auth.id,
              //  'ngrok-skip-browser-warning': 'true', 
              },
  })
  .then(response => {
    // handle success
    // if success setStep(4);
    //if(response.data.success){
    if(response.data.data.success){
      setStep(4);
      console.log(response);
    } else {
      setMessageError(response.message);
      console.log(response);
    }
  })
  .catch(error => {
    // handle error
  });
};

// Activate a user
const activateUser = (idUtente) => {
  axios.post( apiURL +
    '/censor/admin/activate',
    {
      // idUtente: idUtente
      idUtente: 5
    },
    {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id,
      // 'ngrok-skip-browser-warning': 'true',
       },
    }
  )
  .then(response => {
    // handle success
    // if success setStep(5)
    //if(response.data.success){
    if(response.data.data.success){
      setStep(5);
      console.log(response);
    } else {
      setMessageError(response.message);
      console.log(response);
    }
  })
  .catch(error => {
    // handle error
    console.log(error);
    setMessageError(error);
  });
};

const handleUseDefaultLogo = () => {
  setFormData3({
    ...formData3,
    useDefaultLogo: true
  });
};
  
    return (
      <>
      {/* <ButtonAction onClick={() => setStep(1)}>1</ButtonAction> 
      <ButtonAction onClick={() => setStep(2)}>2</ButtonAction> 
      <ButtonAction onClick={() => setStep(3)}>3</ButtonAction> 
      <ButtonAction onClick={() => setStep(4)}>4</ButtonAction> 
      <ButtonAction onClick={() => setStep(5)}>5</ButtonAction>  */}
        {step === 1 && (
          <>

            Create New User

            <DBAformcontainer>
              <DBAformwrapper>
                <DBAformline>
                  <SingleName>Username</SingleName>
                  <SingleInput
                    name="username"
                    value={formData1.username}
                    onChange={handleChange1}
                    required
                  />
                </DBAformline>
                <DBAformline>
                  <SingleName>Password</SingleName>
                  <SingleInput
                    type="text"
                    name="password"
                    value={formData1.password}
                    onChange={handleChange1}
                    required
                  />
                </DBAformline>
                <DBAformline>
                  <SingleName>Email</SingleName>
                  <SingleInput
                    type="email"
                    name="email"
                    value={formData1.email}
                    onChange={handleChange1}
                    required
                  />
                </DBAformline>
                <DBAformline>
                  <SingleName>Role</SingleName>
                  <select name="role" value={formData1.role} onChange={handleChange1} required>
                    <option value="1">Standard</option>
                    <option value="2">Premium</option>
                  </select>
                </DBAformline>
              </DBAformwrapper>
            </DBAformcontainer>
            <InternalButton onClick={handleSubmit}>Next</InternalButton>
          </>
        )}

    {step === 2 && (
      <>
        <DBAformcontainer>
          <DBAformwrapper>
            <DBAformline>
              <SingleName>Nome Anagrafico</SingleName>
              <SingleInput
                name="nomeAnagrafico"
                value={formData2.nomeAnagrafico}
                onChange={handleChange2}
                required
              />
            </DBAformline>
            <DBAformline>
              <SingleName>URL</SingleName>
              <SingleInput
                name="url"
                value={formData2.url}
                onChange={handleChange2}
                required
              />
            </DBAformline>
            {showColorPicker && (
              <ChromePicker color={color} onChange={handleColorChange} />
            )}

            <DBAformline>
              <SingleName>Color</SingleName>
              <SingleInput
                type="text"
                name="color"
                required
                value={color}
                onFocus={() => setShowColorPicker(true)}
                onBlur={() => setShowColorPicker(false)}
                onChange={(e) => setColor(e.target.value)}
              />
            </DBAformline>
            <InternalCancelButton onClick={toggleColorPicker}>
              {showColorPicker ? "Close" : "Open"} Color Picker
            </InternalCancelButton>
          </DBAformwrapper>
        </DBAformcontainer>
        <InternalButton onClick={handleSubmit}>Next</InternalButton>
      </>
    )}

    {step === 3 && (
      <>
        <DBAformcontainer>
          <DBAformline>
            <SingleName>Logo</SingleName>
            <div>
              <input
                type="radio"
                id="use-default-logo"
                name="logo"
                checked={formData3.useDefaultLogo}
                onChange={() =>
                  setFormData3({ ...formData3, useDefaultLogo: true })}
              />
              <label htmlFor="use-default-logo">Use default logo</label>
            </div>
            <div>
              <input
                type="radio"
                id="upload-logo"
                name="logo"
                checked={!formData3.useDefaultLogo}
                onChange={() =>
                  setFormData3({ ...formData3, useDefaultLogo: false })
                }
              />
              <label htmlFor="upload-logo">Upload logo</label>
              {!formData3.useDefaultLogo ? (
                <UploadLogoForm selectedUser={idCreatedUser} dbaselectedUser={auth.id} dbaSelectedUser={idCreatedUser} onSuccess={() => setStep(4)}/>
              ) : null }
            </div>
          </DBAformline>
        </DBAformcontainer>
        <InternalButton onClick={handleSubmit}>Next</InternalButton>
      </>
    )}
  
  {step === 4 && (
      <>
        <DBAformcontainer>
          User successfully created. Click to activate the new user.
          <InternalButton onClick={handleSubmit}>Activate</InternalButton>
        </DBAformcontainer>
      </>
    )}

    {step === 5 && (
      <>
        <DBAformcontainer>
          User successfully activated. Click to go back.
          <InternalButton onClick={() => (window.location.href = "/DBA")}>Back to Users</InternalButton>
        </DBAformcontainer>
      </>
    )}

    <ButtonAction onClick={() => setStep(1)}>1</ButtonAction> 
    <ButtonAction onClick={() => setStep(2)}>2</ButtonAction> 
    <ButtonAction onClick={() => setStep(3)}>3</ButtonAction> 
    <ButtonAction onClick={() => setStep(4)}>4</ButtonAction> 
    <ButtonAction onClick={() => setStep(5)}>5</ButtonAction> 

        </>
    )
}

export default DbaNewUser;