import styled from 'styled-components';

export const SidebarContainer = styled.div`
height: 72px;
width: 100%;
background: transparent;
color: #eeeeee;
display: flex;
`

export const ViewContainer = styled.div`
height: calc(100% - 72px);
width: 100%;
background: transparent;
//background: linear-gradient(60deg, #2c287b, #773824);
color: #eeeeee;
display: flex;
flex-direction: row;
z-index: 1;
`

export const HeaderBar = styled.div`
height: 42px;
display: flex;
justify-content: space-between;
padding: 0px 8px;
align-items: center;
`

export const ContentWrapper = styled.div`
display:flex;
padding: 0 16px;
justify-content: center;
`

export const UploadWindowContainer = styled.div`
display: flex;
width: 600px;
height: 360px;
background: #eee;
border-radius: 16px;
`

export const UploadWindowWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 16px;
width: 100%;
`

export const WindowHeader = styled.div`
height: 32px;
flex-grow: 0;
`

export const WindowContainer = styled.div`
flex-grow: 1;
`

export const ErrorWrapper = styled.div`
text-align: center;
flex-grow: 0;
`

export const ErrorContent = styled.h4`

`

export const WindowFooter = styled.div`
height: 48px;
width: 100%;
flex-grow: 0;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
`

export const ButtonUpload = styled.button`
height: 32px;
width: 80px;
border-radius: 8px;
background: linear-gradient(60deg,#C83F3B,#E5AA47);
border: none;
font-weight: bold;
font-size: 11px;
text-transform: uppercase;
cursor: pointer;
`

export const DragAndDrop = styled.div`
background-color: #222;
width: 240px;
height: 140px;    
display: flex;
justify-content: center;
align-items: center;

& P{
    padding: 0;
    margin: 0;
}
`