import styled from "styled-components";

export const ParentContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    user-select: none;
`

export const ButtonContainer = styled.div`
    z-index: 3;
    display: flex;
    justify-content: center;
`

export const IFrame = styled.iframe`
    width: 100%;
    height: 100%;
    border: 0;
    position: absolute;
    left: 0;
    z-index: 1;
    user-select: none;
`

export const ButtonClose = styled.img`
    position: absolute;
    right: 48px;
    top: 48px;
    height: 24px;
    width: 24px;
    font-size: 16px;
    border-radius: 50%;
    cursor: pointer;
` 

export const AnnotationsContainer = styled.div`
    width: 100%;
    height: 100%;
    background: transparent ;
    //user-select: none;
    position: absolute;
    top: 0px;
    overflow: hidden;
`

export const AnnotationElementContainer = styled.div`    
    width: fit-content;
    padding: 0 8px;
    height: 32px;
    border-radius: 16px;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    gap: 4px;
    font-weight: 500;
    user-select: none;
    z-index: 3;

    transition: opacity 0.25s ease-in-out;

    pointer-events: ${(props) => (props.isMoving ? 'none' : 'auto')};
    opacity:  ${(props) => (props.isMoving ? '0.5' : '1')};
    background: ${(props) => (props.isSelected ? 'rgb(255, 255, 255,0.75)' : 'rgba(255, 255, 255, 0.1)')};

    &:hover{
        background: rgb(255, 255, 255, 1);
    }
`

export const AnnotationTitle = styled.div`
    font-size: 1rem;
`

export const AnnotationNumber = styled.div`
    font-size: 1rem;
`

export const BackButton = styled.div`
  background-color: rgb(255 255 255 / 66%);
  position: absolute;
  bottom: 24px;
  border-radius: 12px;
  height: 24px;
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  &:hover{
        background: rgb(255, 255, 255, 1);
  }
`

export const DetailWrapper = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
`

export const DetailContainer = styled.div`
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    background: white;
    display: flex;
    flex-direction: column;
    
    transform-origin: center center; 
    transition: transform 0.5s ease-in-out; 
    transform: scale(${(props) => (props.visible ? '1' : '0')});
`

export const DetailTitle = styled.div`
    background-color: rgb(30, 41, 59);
    color: rgb(238, 238, 238);
    padding: 8px;
    font-size: 1rem;
    font-weight: 500;
`

export const DetailBody = styled.div`
    padding: 8px;
    overflow-y: auto;
    scrollbar-width: auto;
    scrollbar-color: #dddddd #ffffff;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 10px;
        border: 2px solid #ffffff;
    }
  
    flex-grow: 1;

    & img {
        max-width: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }

    & iframe {
        max-height: -webkit-fill-available;
    }
`

export const AnnotationContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  user-select: none;
  z-index: 3;
`

export const AnnotationInteractionContainer = styled.div`
  margin-top: 16px;
  user-select: none;
  z-index: 3;
`

export const AnnotationInteractionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  user-select: none;
`

export const AnnotationInteractionButton = styled.div`
  background-color: rgb(255 255 255 / 80%);
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 2px 2px rgb(0 0 0 / 20%);
  user-select: none;

  &:hover{
    font-weight: bold;
  }
`

export const RightClickOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2; // Adjust as needed to be just above the iframe
  pointer-events: none; // Allow events to pass through by default
`

// 14 = copertina secondo libro mensola
// 697 alcuni libri mensola
// 689 alcuni libri mensola
// 681 alcuni libri mensola
// 670 alcuni libri mensola
// 25 libro mensola
// 14 secondo libro mensola

// 55 stecca superiore lampada
// 1218 stecca inferiore lampada
// 1191 sfera stecca lampada
// 66 base lampada
// 1145 corpo testa lampada
// 1137 interno testa lampada
// 1026 elemento lampada
// 1037 elemento lampada
// 959 elemento lampada
// 951 elemento lampada
// 869 elemento lampada
// 312 elemento lampada
// 301 elemento lampada
// 290 elemento lampada
// 3 elemento lampada
// 880 elemento lampada
// 387 elemento lampada
// 332 elemento lampada

// 662 luci muro dx
// 654 luci muro dx
// 638 luci muro dx
// 630 luci muro dx
// 622 luci muro dx
// 614 luci muro dx
// 606 luci muro dx
// 598 luci muro dx
// 470 luci muro dx
// 462 luci muro dx

// 398 panneli muro dx
// 121 pannelli muro dx

// 387 intonaco e pavimento

// 376 soffitto

// 365 finestre

// 590 TV
// 502 TV
// 494 TV

// 582 XBOX
// 574 XBOX
// 566 XBOX

// 558 vaso mensola

// 550 altoparlante bluetooth
// 542 altoparlante bluetooth
// 534 altoparlante bluetooth
// 526 altoparlante bluetooth
// 518 altoparlante bluetooth
// 510 altoparlante bluetooth

// 451 radio

// 486 decorazione mensola verticale e altoparlante bluetooth
// 259 mensola verticale

// 165 mensola

// 967 dettagli lampada e luci soffitto

// 1172 elemento luci soffitto
// 945 elemento luci soffitto
// 939 elemento luci soffitto
// 933 elemento luci soffitto
// 927 elemento luci soffitto
// 921 elemento luci soffitto
// 915 elemento luci soffitto
// 909 elemento luci soffitto
// 900 elemento luci soffitto
// 894 elemento luci soffitto
// 888 elemento luci soffitto
// 880 elemento luci soffitto
// 420 elemento luci soffitto ( barra )
// 409 elemento luci soffitto
// 343 elemento luci soffitto
// 270 elemento luci soffitto
// 229 elemento luci soffitto
// 220 elemento luci soffitto
// 176 elemento luci soffitto

// 332 tappeto

// 237 battiscopa

// 1018 hexagon acquario

// 1126 tenda sx
// 279 tenda sx
// 248 tenda sx
// 198 tenda sx
// 187 tenda sx
// 154 tenda sx
// 143 tenda sx
// 132 tenda sx

// 1099 elementi valigia
// 1088 valigia

// 354 tavolino divano

// 209 tavolo

// 1107 libro grande su lampada
// 1118 libro piccolo su lampada

// 88 lati inferiori mensola a muro principale

// 1199 interni foto cornici mensola
// 1180 foto cornici mensola
// 2 elemento cornici mensola

// 1164 lattina tavolo

// 861 secondo libro tavolo
// 837 primo libro tavolo
// 853 quinto libro tavolo
// 845 quarto libro tavolo
// 826 colore pagine libri tavolo

// 1153 uccello

// 730 prima pianta lato sx
// 732 prima pianta lato sx
// 705 prima pianta lato sx

// 1207 seconda pianta lato sx
// 1226 seconda pianta lato sx
// 1234 seconda pianta lato sx
// 1242 seconda pianta lato sx
// 1258 seconda pianta lato sx
// 1250 seconda pianta lato sx

// 767 terza pianta lato sx
// 740 terza pianta lato sx
// 1266 terza pianta lato sx

// 724 quarta pianta lato sx
// 716 quarta pianta lato sx

// 818 quinta pianta lato sxa
// 810 quinta pianta lato sx
// 802 quinta pianta lato sx
// 794 quinta pianta lato sx
// 786 quinta pianta lato sx
// 775 quinta pianta lato sx

// 759 sesta pianta lato sx
// 751 sesta pianta lato sx

// 1080 elemento divano
// 1072 elemento divano
// 1064 elemento divano
// 1053 elemento divano


/*
{
  "ScenePosition": [
    0.7735485269962713,
    0.3660042004946349,
    0.5569034814834595
  ],
  "WorldPosition": [
    0.7735485269962713,
    0.3660042004946349,
    0.5569034814834595
  ],
  "eye": {
    "0": 0.7498479175231579,
    "1": -0.2883821613080203,
    "2": 1.138746470486927
  },
  "target": {
    "0": 0.74849213590221,
    "1": 0.18416509457422756,
    "2": 0.7960843610468014
  },
  "title": "Tavolo",
  "text": "T1"
}
{
  "ScenePosition": [
    0.7250242944919993,
    1.8205000162124634,
    0.528134312872824
  ],
  "WorldPosition": [
    0.7250242944919993,
    1.8205000162124634,
    0.528134312872824
  ],
  "eye": {
    "0": 0.7456831690087469,
    "1": 0.7051149647352845,
    "2": 1.0499758657869538
  },
  "target": {
    "0": 0.7458231779547949,
    "1": 0.7137531570112451,
    "2": 1.0449397927435438
  },
  "title": "Tavolino",
  "text": "T3"
}
{
  "ScenePosition": [
    -1.0907759573987506,
    2.284376971220028,
    0.13850000500679016
  ],
  "WorldPosition": [
    -1.0907759573987506,
    2.284376971220028,
    0.13850000500679016
  ],
  "eye": {
    "0": 0.08426714771834734,
    "1": 1.302531181998609,
    "2": 0.9086043263606218
  },
  "target": {
    "0": 0.07814113616069135,
    "1": 1.3094008889875592,
    "2": 0.904695476365875
  },
  "title": "Piante",
  "text": "T4"
}
{
  "ScenePosition": [
    1.2318067775029395,
    3.011899948120117,
    0.9467500378757479
  ],
  "WorldPosition": [
    1.2318067775029395,
    3.011899948120117,
    0.9467500378757479
  ],
  "eye": {
    "0": 1.3280705474540662,
    "1": 1.566495074016036,
    "2": 1.3671432894090283
  },
  "target": {
    "0": 1.3287754379147783,
    "1": 1.576263242206706,
    "2": 1.3651218988883274
  },
  "title": "Televisione",
  "text": "T5"
}
{
  "ScenePosition": [
    0.5989734245022279,
    3.3351991176605225,
    1.473314394978119
  ],
  "WorldPosition": [
    0.5989734245022279,
    3.3351991176605225,
    1.473314394978119
  ],
  "eye": {
    "0": 0.8510694089165572,
    "1": 0.7777103966214787,
    "2": 1.6078246559790716
  },
  "target": {
    "0": 0.8847434305260529,
    "1": 2.0927669351337155,
    "2": 1.6444066102254373
  },
  "title": "Mensole",
  "text": "T6"
}
{
  "ScenePosition": [
    2.7332000732421875,
    1.3139725410190517,
    1.5594341122501527
  ],
  "WorldPosition": [
    2.7332000732421875,
    1.3139725410190517,
    1.5594341122501527
  ],
  "eye": {
    "0": 1.3439847280695134,
    "1": -0.6045208335679606,
    "2": 1.9732887730394493
  },
  "target": {
    "0": 2.325939724357017,
    "1": 0.4416940435055059,
    "2": 1.7602685144129222
  },
  "title": "Muro",
  "text": "T7"
}

*/