import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { responsive } from '../../cssvariable.js';

const shine = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const spin = keyframes`
    to {
      transform: rotate(360deg)
    }
`

const move = keyframes`
    0% {
      transform: translate(0%, 100%);
    }
    15% {
      transform: translate(-120%, 160%);
    }
    30% {
      transform: translate(100%, -80%);
    }
    40% {
      transform: translate(-140%, 0%);
    }
    60% {
      transform: translate(40%, -80%);
    }
    80% {
      transform: translate(-160%, -20%);
    }
    100% {
      transform: translate(40%, 60%);
    }
`

export const SidebarContainer = styled.div`
height: 72px;
width: 100%;
background: transparent;
color: #eeeeee;
display: flex;
`   

export const ViewContainer = styled.div`
  height: calc(100% - 72px);
  width: 100%;
  background: transparent;
  //background: linear-gradient(60deg, #2c287b, #773824);
  color: #eeeeee;
  display: flex;
  flex-direction: row;
  z-index: 1;
`

export const HeaderBar = styled.div`
height: 42px;
display: flex;
justify-content: space-between;
padding: 0px 8px;
align-items: center;
`

export const ContentWrapper = styled.div`
    display:flex;
    padding: 16px;
    gap: 56px;
    flex-wrap: wrap;
    align-content: flex-start;
    flex-grow: 1;
    overflow-y: auto;
`

export const LeftWrapper = styled.div`
    display:flex;
    width: 336px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    background: rgba(15, 23, 42, 0.64);
`

export const RightWrapper = styled.div`
    display:flex;
    width: calc(100% - 336px);
`

export const LeftContent = styled.div`
    display:flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: 24px;
    font-size: 56px;
    font-weight: bold;
`

export const MetaBallContainer = styled.video`
    height: 160px;
    width: 160px;
    scale: 1.8;
`

export const LeftButtonUpload = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  background: linear-gradient(135deg, #C2410C, #F97316);
  height: 44px;
  width: 240px;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0px 6px 12px -2px rgb(234 88 12 / 0.4);
  cursor: pointer;
  gap: 6px;
  color: #F8FAFC;

  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -16px;
    width: 120%;
    height: 120%;
    background: linear-gradient(90deg, transparent, transparent, rgba(255, 255, 255, 0.2),transparent, transparent);
    background-size: 300% 100%;
    transform: skewX(-30deg);
    transform-origin: 0;
    pointer-events: none;
  }

  &:hover::before {
    animation: ${shine} 3s infinite linear;
  }
`

export const LeftButtonList = styled.div`
    display:flex;
    background: #4F46E5;
    height: 44px;
    width: 240px;
    border-radius: 22px;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    box-shadow: 0px 6px 12px 0px rgb(79 70 229 / 0.4);
    cursor: pointer;
    gap: 6px;
`

export const ButtonImage = styled.img`
    height: 24px;
    width: 24px;
`

export const LeftText = styled.h6`
    display:flex;
    font-size: 20px;
    line-height: 36px;
    font-weight: 400;
    max-width: 275px;
    margin-top: 16px;
`

export const ListContainer = styled.div`
  position: fixed;
  right: ${props => (props.isOpen ? '0' : '-485px')};
  top: 72px;
  width: 485px;
  height: calc(100% - 72px);
  background: rgba(15, 23, 42, 0.8);
  backdrop-filter: blur(32px);
  border-left: 1px solid rgba(255,255,255,0.08);
  z-index: 4;
  transition: right 0.3s ease-in-out;
`;

export const ListWrapper = styled.div`
  padding: 20px 32px 0px 32px; 
  display: flex;
  flex-direction: column;
  height: 100%;
  scrollbar-width: auto;
  scrollbar-color: #334155 #1e293b;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #1e293b;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #334155;
    border-radius: 10px;
    border: 2px solid #1e293b;
  }
`

export const ListHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const ListFirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const ListTitle = styled.div`
  font-weight: bold;
`

export const ListCloseButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export const ListSearchContainer = styled.div`
    display: flex;
    height: 48px;
    width: 100%;
    border-radius: 4px;
    background-color: rgba(30, 41, 59, 1);
    justify-content: space-between;
    align-items: center;
    gap: 8px;
`

export const ListSearchIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 16px;
`

export const ListSearchTextInput = styled.input.attrs({
    type: 'text',
    placeholder: 'Cerca modelli 3D...'
    })`
    color: #64748B;
    font-size: 14px;
    font-family: 'Space Grotesk', sans-serif;
    flex-grow: 1;
    outline: none;
    background: transparent;
    border: none;
`

export const ListContent = styled.div`
  overflow-y: auto;
`

export const InputEditName = styled.input`
  background: #334155;
  border-radius: 4px;
  padding: 12px 16px;
  border: none;
  width: calc(100% - 32px);
  text-align: center;
  color: #F8FAFC;
  font-family: 'Space Grotesk', sans-serif;
  outline:0; 
  font-weight: 300;

  &:disabled{
      background: rgba(71, 85, 105, 0.4);
  }
`

export const EmtpyContainer = styled.div`
    display:flex;
    padding: 16px;
    gap: 56px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    flex-grow: 1;
`

/* META BALLS */

export const MetaElement = styled.div`
    background: inherit;
    animation: 7s spin ease-in-out infinite;

    &:before,&:after{
        content: "";
        position: absolute;
        width:100px;
        height:100px;
        left:50%;
        top:50%;
        margin:-50px;
        background: orange;
        border-radius:50%;
        -webkit-filter: blur(25px);
        filter: blur(25px);
        animation: ${move} 24s infinite alternate ease-in-out;
    }

    &:before{
        animation-duration: 19.3s;
        animation-delay: -3.3s;
    }

    &:nth-child(2):before {
    animation-duration: 14.7s;
    animation-delay: -2.7s;
    background: #C2410C;
    }

    &:nth-child(2):after {
    animation-duration: 8.7s;
    animation-delay: -5.32s;
    background: #B2410B;
    }

`

export const MetaBallBackgroundContainer = styled.div`
    overflow: hidden;
    //-webkit-filter: contrast(25);
    //filter: contrast(25);
    //opacity: 0.2;
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0px;
    top: 0px;
    margin: 0;
    padding: 0;
    z-index: 0;
`

export const BackgroundLoop = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    filter: drop-shadow(2px 4px 6px rgba(50,50,255,0.5)) blur(5px);
    z-index: -1;
  }
`;

  export const BackgroundLoopContainer = styled.div`
  height: 100%;
  width: 100%;
`