import styled from 'styled-components';

export const CenterWrapper = styled.div`
  display: flex;    
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
    overflow-y: hidden;
`

export const ModelBoxContainer = styled.div`
  display: flex;
  gap: 24px;
    flex-wrap: wrap;
    grid-column: 1 / span 2;
    align-content: flex-start;
`;