import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

import {
  ListElementButton,
  ListElementButtonsContainer,
  ListElementIcon,
  ListElementIconContainer,
  ListElementName,
  ListElementRow,
  SubMenuContainer,
  SubMenuIcon,
  SubMenuLine,
  SubMenuRow,
  SubMenuRowLink,
  SubMenuWrapper
} from './modellistElements';

import previewicon from '../../img/preview24iconblack.png';
import settingsicon from '../../img/settings24icon.png';
import analyticsicon from '../../img/analytics24icon.png';
import submenuicon from '../../img/submenu24icon.png';
import deleteicon from '../../img/delete24icon.png';
import renameicon from '../../img/rename24icon.png';

const ModelList = ( props ) => {
    console.debug("modellist")
    console.debug(props.model.sfDoc.thumbnails.images)
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const submenuRef = useRef(null);
    const submenuIconRef = useRef(null);
    const { auth } = useAuth();
    const navigate = useNavigate();
  
    const handleSubmenuClick = () => {
      setIsSubmenuOpen(!isSubmenuOpen);
    };

    const handleDeleteClick = (modelId) => {
      setIsSubmenuOpen(false);
      props.handleOpenDeleteDialog(modelId);
    };

    const handleRenameClick = (modelId) => {
      setIsSubmenuOpen(false);
      props.handleRenameClick(modelId);
    }; 
    
    function handleSettingsClick(e) {
      // Prevents click event from bubbling up to parent elements
      e.stopPropagation();
  
      // Your button logic here
      console.debug("settings clicked");
      navigate('/configurations', {
        state: {
          conftest: props.model.confTest,
          confprod: props.model.confProd,
          type: 'prod',
          modelInfo: props.model,
          configurationAlreadyOpen: true
        }
      });
    }

    function handleAnalyticsClick(e) {
      // Prevents click event from bubbling up to parent elements
      e.stopPropagation();
      console.debug("handleAnalyticsClick")
      navigate('/analytics', {
        state: {
          fromModelPanel: true,
          models: props.models,
          selectedModel: props.model,
          selectedModelNumber: props.modelNumber,
          selectedModelHDImage: props.modelHDImageUrl,
          selectedTab: '2'
        }
      });
    }
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          submenuRef.current &&
          submenuIconRef.current &&
          !submenuRef.current.contains(event.target) &&
          !submenuIconRef.current.contains(event.target) &&
          isSubmenuOpen
        ) {
          setIsSubmenuOpen(false);
        }
      };
  
      document.addEventListener('click', handleClickOutside);
  
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [isSubmenuOpen]);
  

  return (
    <ListElementRow>
      <ListElementIconContainer>
        <ListElementIcon  src={props.modelsIconUrl}/>
      </ListElementIconContainer>
      <ListElementName>{props.model.sfDoc.name}</ListElementName>
      <ListElementButtonsContainer>
        <ListElementButton src={settingsicon} onClick={handleSettingsClick}/>
        <ListElementButton src={analyticsicon} onClick={handleAnalyticsClick}/>
        <ListElementButton src={submenuicon} onClick={handleSubmenuClick} ref={submenuIconRef} />
        <SubMenuContainer isOpen={isSubmenuOpen} ref={submenuRef}>
          <SubMenuWrapper>
            <SubMenuRow onClick={() => handleRenameClick(props.model.uid)}>
              <SubMenuIcon src={renameicon}></SubMenuIcon>
              Rinomina
            </SubMenuRow>
            <SubMenuRow onClick={() => handleDeleteClick(props.model.uid)}>
              <SubMenuIcon src={deleteicon}></SubMenuIcon>
              Elimina Modello
            </SubMenuRow>
            <SubMenuLine />
            <SubMenuRowLink to={`/output/${auth.id}/${props.model.uid}/${'test'}`} target='__blank'>
              <SubMenuIcon src={previewicon}></SubMenuIcon>
              Link Test
            </SubMenuRowLink>
            <SubMenuRowLink to={`/output/${auth.id}/${props.model.uid}/${'prod'}`} target='__blank'>
              <SubMenuIcon src={previewicon}></SubMenuIcon>
              Link LIVE
            </SubMenuRowLink>
          </SubMenuWrapper>
        </SubMenuContainer>
      </ListElementButtonsContainer>
    </ListElementRow>
  );
};

export default ModelList;