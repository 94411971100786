import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import {ParentContainer,AnnotationsContainer,AnnotationElementContainer,AnnotationTitle,AnnotationNumber,
    DetailWrapper,DetailContainer,DetailTitle,DetailBody,IFrame,ButtonClose} from "./annotationstestElements.js"

import closeicon from  '../../img/close24icon.png';
import customAnnotations from './customAnnotations.json';

const SketchfabViewer = () => {
  const [annotations, setAnnotations] = useState([]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [detailContent, setDetailContent] = useState({ name: '', content: '' });
  const iframeRef = useRef(null);
  const [apiInstance, setApiInstance] = useState(null);
  const url = "e6c2163fc97f4cc687dccd4e7cf10c8a";
  const [dragging, setDragging] = useState(false);

  const customIndexArray = [2,3];  // Replace with your array of indexes

const imageComponent = ({ src, alt }) => {
    const image = detailContent.images.find(img => img.src === src);
    const width = image && image.width ? image.width : 'auto';
    return <img src={src} alt={alt} style={{ width, maxWidth: '100%' }} />;
};

const videoComponent = ({ src }) => (
  <iframe 
      src={src}
      width="100%"
      height="100%"
      title="YouTube video" 
      frameBorder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowFullScreen
  ></iframe>
);

// Add the video component to the components object
const components = {
  img: imageComponent,
  iframe: videoComponent,
};

  // Markdown inside annotations
  // https://help.sketchfab.com/hc/en-us/articles/205611655-Markdown

  useEffect(() => {
    if ( !iframeRef.current) return;
    const client = new window.Sketchfab(iframeRef.current);

    const error = () => {
      console.error('Error initializing Sketchfab API');
    };
    
    const config = {
      
      success: function onSuccess(api) {
        setApiInstance(api);
        api.start();

        api.addEventListener('viewerready', function () {

          console.debug("viewer ready");

          api.getAnnotationList((err, annotationsList) => {
            if (err) {
              console.error('Failed to retrieve annotations:', err);
              return;
            }
            if (!err) {
                console.debug(annotationsList);
            }
            setAnnotations(annotationsList);
            console.debug('Annotations retrieved:', annotationsList);
            runAnnotationLoop(api, annotationsList);
          });
        });
          
      },
      autostart: 1,
      annotation: 0,
      annotations_visible: 0,
      ui_annotations: 0,
      ui_controls: 0,
      ui_watermark: 0,
      ui_infos: 0,
      ui_hint: 0,
      ui_start: 0,
      ui_stop: 0,
      error: error
    };

    client.init(url, config);
  }, [url]);

  const runAnnotationLoop = (api, annotationsList) => {
    const updateAnnotations = () => {
      const updatedAnnotations = [];
      let completed = 0;

      annotationsList.forEach((annotation, index) => {
        // Call getWorldToScreenCoordinates with a callback function
        api.getWorldToScreenCoordinates(annotation.position, (coord) => {
            // Update the annotation with the new position
            const updatedAnnotation = { ...annotation, position: coord.canvasCoord };
            updatedAnnotations[index] = updatedAnnotation;

            // Increment the completed counter
            completed += 1;

            // Check if all async operations are completed
            if (completed === annotationsList.length) {
                // Update the state with the updated annotations
                setAnnotations(updatedAnnotations);

                // Request the next frame
                requestAnimationFrame(updateAnnotations);
            }
        });
    });
  };

    updateAnnotations();
  };

  const onAnnotationClick = (eye, target) => {
    apiInstance.lookat(
        eye,
        target,
        0.5,  // duration, 0 for instant
        () => { console.log('Camera moved to annotation position'); }
    );
  };

  const showAnnotationDetail = (annotation, index) => {
    const customAnnotation = customAnnotations.find(item => item.id === index);
    let content;
    let video = null;
    let images = [];
    if (customAnnotation) {
        content = customAnnotation.content.text;
        images = customAnnotation.content.images || [];
        images.forEach((image, idx) => {
            const imgPlaceholder = `{{img${idx}}}`;
            content = content.replace(`![Image${idx !== 0 ? idx : ''}]`, imgPlaceholder);
        });
        if (customAnnotation.video) {
            video = customAnnotation.video;
        }
    } else {
        content = annotation.content.raw;
    }
    setDetailContent({ name: annotation.name, content, video, images });
    setDetailVisible(true);
  };

  const hideAnnotationDetail = () => {
      setDetailVisible(false);
  };

  return (
    <ParentContainer>
      <AnnotationsContainer>
      <IFrame
        ref={iframeRef}
        title="Sketchfab Viewer"
        id="player__iframe"
        src=""
      />
        {annotations.map((annotation, index) => (
          <AnnotationElementContainer
              key={index}
              onClick={() => {
                  onAnnotationClick(annotation.eye, annotation.target);
                  showAnnotationDetail(annotation, index);
              }}
              style={{
              position: "absolute",
              transform: `translate(${(annotation.position && annotation.position[0]) || 0}px, ${(annotation.position && (annotation.position[1]-96)) || 0}px)`,
              // ...other styles
            }}
          >
              <AnnotationNumber>{index+1}</AnnotationNumber>
              <AnnotationTitle>{annotation.name}</AnnotationTitle>
          </AnnotationElementContainer>
        ))}
      </AnnotationsContainer>
        {detailVisible && (
            <DetailWrapper>
              <DetailContainer visible={detailVisible}>
                <DetailTitle>{detailContent.name}</DetailTitle>
                  <DetailBody>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={detailContent.content} />
                    {detailContent.images && detailContent.images.map((image, idx) => (
                      <img
                          src={image.src}
                          alt={image.alt}
                          style={{ width: image.width ? image.width : 'auto' }}
                          key={idx}
                      />
                    ))}
                    {detailContent.video && (
                      <iframe 
                          src={detailContent.video.src} 
                          width={detailContent.video.width} 
                          height={detailContent.video.height} 
                          title="YouTube video" 
                          frameBorder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                          allowFullScreen
                      ></iframe>
                    )}
                  </DetailBody>
              </DetailContainer>
              <ButtonClose onClick={hideAnnotationDetail} src={closeicon}></ButtonClose>
            </DetailWrapper>
        )}
    </ParentContainer>
  );
};

export default SketchfabViewer;