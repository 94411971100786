import styled from "styled-components";
import { Link } from "react-router-dom";

export const BackgroundContainer = styled.div`
    background: -webkit-radial-gradient(bottom right,#8d2d33,#10172a 67%);
    background: radial-gradient(at bottom right,#8d2d33,#10172a 67%);
    
    color: white;
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 16px;
`

export const LogoWhite = styled.img`
    //filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    width: 240px;
`


export const LoginButton = styled(Link)`
    width: 144px;
    height: 32px;
    border-radius: 16px;
    background-color: #f97316;
    color: #eee;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    line-height: 32px;
    `

    export const MetaBallContainer = styled.div`
    width: 100%;
    height: 100%;
    background: url(${props => props.metaball}) no-repeat center center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 16px;
    `