import styled from 'styled-components';

export const SidebarContainer = styled.div`
  height: 72px;
  width: 100%;
  background: transparent;
  color: #eeeeee;
  display: flex;
`

export const ViewContainer = styled.div`
  height: calc(100% - 72px);
  width: 100%;
  background: transparent;
  //background: linear-gradient(60deg, #2c287b, #773824);
  color: #eeeeee;
  display: flex;
  flex-direction: row;
  z-index: 1;
`

export const HeaderBar = styled.div`
  height: 42px;
  display: flex;
  justify-content: space-between;
  padding: 0px 8px;
  align-items: center;
`

export const ProfileWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const ProfileContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`

export const LogoContainer = styled.div`

`

export const LogoSrc = styled.img`

  width: 200px;
  height: 200px;
  object-fit: contain;

`

export const AnagraphicContainer = styled.div`
  margin-top: 8px;
    width: 340px;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

export const InputEditProfile = styled.input`
    background: #334155;
    border-radius: 4px;
    padding: 12px 16px;
    border: none;
    width: calc(100% - 32px);
    text-align: center;
    color: #F8FAFC;
    font-family: 'Space Grotesk', sans-serif;
    outline:0; 
    font-weight: 300;

    &:disabled{
        background: rgba(71, 85, 105, 0.4);
    }
`

export const AnagraphicName = styled.div`

`

export const AnagraphicWebsite = styled.div`

`
export const SubsrcripionType = styled.div`

`

export const ModelsAmount = styled.div`

`

export const ProfileDetailsContainer = styled.div`

`

export const ProfileDetailsWrapper = styled.div`

`

export const DetailsElementWrapper = styled.div`

`

export const DetailName = styled.div`

`

export const DetailAttr = styled.div`

`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const ProfileHeader = styled.div`
  height: 200px;
  display: flex;
  flex-direction: row;
  background: linear-gradient(90deg,#111111,#4f46e5);
  background: url(${props => props.cover}) no-repeat top center;
  background-size: cover;
  align-items: flex-end;
  justify-content: space-between;
`

export const ProfileIconContainer = styled.div`
  width: 240px;
  height: 240px;
  margin-bottom: -92px;
`

export const ProfileMetaBall = styled.div`
  width: 240px;
  height: 240px;
  background-color: #111111;
  background: url(${props => props.metaball}) no-repeat center center;
  background-size: 300px 300px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`

export const ProfileIcon = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 1px solid;
  background-size: contain;
    background-color: rgb(30 41 59);
`

export const EditIcon = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: url(${props => props.src}) no-repeat center center, #5a5f6c21;
  z-index: 2;
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:hover{
    opacity: 1;
  }
`

export const ProfileEditButton = styled.div`
  height: 24px;
  font-size: 16px;
  text-transform: uppercase;
  background: rgba(248, 250, 252, 0.64);
  color: #0F172A;
  border-radius: 12px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;

  &:hover{
    background: #0F172A;
    color: rgba(248,250,252,1);
  }
`

export const ProfileInfoContainer = styled.div`

`

export const ProfileInfoFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 76px 0px 0px 64px;
  gap: 24px;
  align-content: center;
  align-items: center;
`

export const ProfileInfoName = styled.div`
  font-size: 40px;
  font-weight: bold;
  z-index: 2;
`

export const PremiumTag = styled.div`
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(90deg,#A21CAF,#EA580C);
  color: #F8FAFC;
  padding: 8px 16px;
  border-radius: 24px;
  height: 20px;
`

export const LogoutWrapper= styled.div`
  text-decoration: underline;
  cursor: pointer;
`

export const ProfileInfoSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0px 0px 64px;
  gap: 24px;
`

export const ProfileWebsite = styled.div`
  font-size: 20px;
  text-decoration: underline;
  color: #94A3B8;
`

export const ProfileStatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 48px 24px 48px 64px;
`

export const CardContainer = styled.div`
  height: 242px;
  width: 200px;
  padding: 16px;
  border: 1px solid rgba(255,255,255,0.08);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg,rgba(255,255,255,0.08),rgba(255,255,255,0.02));
`

export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: rgba(30, 41, 59, 0.4);
  gap: 8px;
`

export const CardIcon = styled.img`

`

export const CardName = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: #94A3B8;
`

export const CardValue = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #F8FAFC;
`

export const RightContainer = styled.div`
  width: 736px;
  display: flex;
  flex-direction: column;
  background-color: #1E293B;
`

export const AchievementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
    margin: 0 0 0 32px;
    padding-right: 32px;
  overflow-y: auto;

  scrollbar-width: auto;
  scrollbar-color: #334155 #1e293b;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #1e293b;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #334155;
    border-radius: 10px;
    border: 2px solid #1e293b;
  }

  @media (max-width: 1279px) {
    margin: 0 0 0 24px;
    padding-left: 0;
  }
`

export const AchievementsHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`

export const AchievementsElement = styled.div`
  display: flex;
  opacity:  ${({ disabled }) => (disabled ? '0.6' : '1')};
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  gap: 16px;
  width: 100%;
  border-bottom: 1px solid #334155;
`

export const AchievementsIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`

export const AchievementsIcon = styled.img`
  height: 40px;
  width: 40px;
`

export const AchievementsCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
`

export const AchievementsName = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #F8FAFC;
  text-align: left;
`

export const AchievementsDescription = styled.div`
  font-size: 16px;
  color: #94A3B8;
  text-align: left;
`

export const AchievementsValueContainer = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #E2E8F0;
`

export const AchievementsValue = styled.div`
    white-space: nowrap;
`