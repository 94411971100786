import styled from 'styled-components';

export const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 44px;
  min-width: 44px;
  height: 24px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ checked, mode }) => (checked ? (mode === 'test' ? '#4f46e5' : '#ea580c') : '#ccc')};
  border-radius: 24px;
  transition: 0.4s;
`;

export const SliderThumb = styled.span`
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: ${({ checked }) => (checked ? '22px' : '2px')};
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 10px;
`;

export const ColumnLayout = styled.div`
  display: flex;
  opacity: ${({disabled}) => (disabled ? '0.66' : '1')}; 
  flex-direction: column;
  gap: 4px;
  flex-direction: column;
  margin: 16px 0;
  width: 100%;
  color: #F8FAFC;
`

export const RowLayout = styled.div`
  display: flex;
  flex-direction: row;
  opacity: ${({disabled}) => (disabled ? '0.66' : '1')}; 
  justify-content: space-between;
  width: 100%;
  color: #F8FAFC;
  gap: 16px;
  padding: 16px 0;
  align-items: center;
`

export const InputLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const InputLabel = styled.div`
  display:flex;
  font-size: 16px;
  text-align: left;
`

export const InputDescription = styled.div`
  font-size: 14px;
  color: #94A3B8;
  text-align: left;
`

export const InputNumber = styled.input`
  margin-top: 16px;
  height: 16px;
  background: #334155;
  border-radius: 4px;
  border: none;
  outline: none;
  color: #F8FAFC;
  font-size: 14px;
  padding: 16px;

  &::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
`

export const InputText = styled.input`
  margin-top: 16px;
  height: 16px;
  background: #334155;
  border-radius: 4px;
  border: none;
  outline: none;
  color: #F8FAFC;
  font-size: 14px;
  padding: 16px;
`

export const InputSelect = styled.select`
  height: 48px;
  background: #334155;
  border-radius: 4px;
  border: none;
  outline: none;
  color: #F8FAFC;
  font-size: 14px;
  padding: 16px;
  text-indent: 16px;
`

export const PremiumTag = styled.div`
  height: 18px;
  width: 120px;
  border-radius: 10px;
  background: linear-gradient(160deg,#A21CAF,#EA580C);
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`

export const ColorPickerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const ColorPickerInput = styled.div`
  margin-top: 16px;
  height: 16px;
  background: #334155;
  border-radius: 4px;
  border: none;
  outline: none;
  color: #F8FAFC;
  font-size: 14px;
  padding: 16px;
  width: calc(100% - 32px);
`