import styled from "styled-components";
import { Link } from "react-router-dom";

export const ListElementRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 71px;
  align-content: center;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #475569;
`
export const ListElementIconContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`

export const ListElementIcon = styled.img`
  height: 60px;
`

export const ListElementName = styled.div`
  flex-grow: 1;
  text-align: left;
`

export const ListElementButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const ListElementButton = styled.img`
  width: 24px;
  height: 24px;
  opacity: 32%;
  cursor: pointer;

  &:hover{
    opacity: 1;
  }
`

export const SubMenuContainer = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  right: 32px;
  margin-top: 28px;
  width: 176px;
  background: #F8FAFC;
  border-radius: 8px;
`

export const SubMenuWrapper = styled.div`
  display:flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
`

export const SubMenuRow = styled.div`
  display:flex;
  flex-direction: row;
    align-content: center;
    align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #334155;
  cursor: pointer;
`

export const SubMenuIcon = styled.img`
    height: 24px;
    width: 24px;
`

export const SubMenuLine = styled.div`
  display:flex;
  height: 1px;
  background-color: #E2E8F0;
`

export const SubMenuRowLink = styled(Link)`
  display:flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #334155;
  cursor: pointer;
  text-decoration: none;
`