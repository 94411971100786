import styled from 'styled-components';

export const LoginWrapper = styled.div`
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
`

export const LoginContentWrapper = styled.div`
    height: 100%;
    max-width: 485px;
    width: 75%;
    background: #0F172A64;
    display: flex;
    color: #eeeeee;
    font-family: 'Space Grotesk', sans-serif;
`

export const LoginContent = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    padding: 0 32px 0 48px;
    gap: 16px;
`

export const LogoWrapper = styled.div`
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const LogoContent = styled.img`
    z-index: 2;
    max-width: 640px;
`

export const CredentialWrapper = styled.div`
    width: 100%;

`

export const NameCredContent = styled.a`
    height: 26px;
`

export const InputCredContent = styled.input.attrs({ type: 'text' })`
    width: calc(100% - 32px);
    height: 48px;
    border: none;
    padding: 0 16px ;
    border-radius: 4px;
    font-family: 'Space Grotesk', sans-serif;
    color: #94A3B8;
    font-size: 14px;
    background-color: #334155;
    outline: none;

    &:-webkit-autofill , :-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
`

export const InputCredPswContent = styled.input.attrs({ type: 'password' })`
    max-width: calc(100% - 32px);
    width: 100%;
    height: 48px;
    border: none;
    padding: 0 16px ;
    border-radius: 4px;
    font-family: 'Space Grotesk', sans-serif;
    color: #94A3B8;
    font-size: 14px;
    background-color: #334155;
    outline: none;
`

export const LoginButton = styled.button`
    width: 100%;
    height: 44px;
    background: #4F46E5;
    color: #F8FAFC;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 22px;
    border: none;
    cursor: pointer;
`

export const CopyrightContent = styled.a`
    font-size: 12px;
    color: #cccccc;
`

export const ErrorContent = styled.a`

`

export const LogoH1 = styled.h1`
    font-size: 40px;
`


export const MetaBallContainer = styled.div`
    width: 100%;
    height: 100%;
    background: url(${props => props.metaball}) no-repeat center center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    filter: blur(2px);
    position: absolute;
`

export const LoginFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    gap: 8px;
`
export const Logo3danimate = styled.img`
    width: 48px;

`