import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PreviewContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
align-content: center;
justify-content: space-between;
align-items: stretch;
position: relative;
`

export const PreviewWrapper = styled.div`
flex-direction: column;
display: flex;
margin: 40px 64px;
flex-grow: 1;
gap: 8px;
`

export const IFrameWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    border-radius: 24px;
    background: rgba(255,255,255,0.2);
    position: relative;
    padding: 16px;
    border: 1px solid rgba(255,255,255,0.08);
`

export const IFrameElement = styled.iframe`
    border: none;
    flex-grow: 1;
    //background: orange;
    border-radius: 16px;
`

export const PreviewTitle = styled.div`
font-size: 32px;
color: #F8FAFC;
font-weight: bold;
`

export const PreviewTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;

`

export const PreviewLogo = styled.img`
    width: 64px;
    height: 64px;
`

export const FooterWrapper = styled.div`
    display: flex;
`

export const EmbedButton = styled.div`
    height: 32px;
    border-radius: 16px;
    background: #EF580C;
    border: 1px solid rgba(255,255,255,0.2);
    padding: 0 16px 0 0;
    line-height: 32px;
    color: #eeeeee;
    font-weight: 600;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    cursor: pointer;
`

export const EmbedIcon = styled.img`
    width: 24px;
    height: 24px;
    background: #EF580C;
    padding: 8px;
`

export const BackButton = styled(Link)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export const BackButtonIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`