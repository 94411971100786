import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import {ContentWrapper,ThumbnailWrapper,ThumbnailContent,TitleWrapper,TitleContent,ButtonWrapper,
  AnalyticsButton,OutputTestButton,OutputProdButton,CardContainer,SettingsButton,SubmenuButton,
  ButtonContainer, GradientOverlayContent,SubMenuContainer,SubMenuIcon,SubMenuLine,SubMenuRow,
  SubMenuWrapper,
  SubMenuRowLink} from './modelpanelElements.js';

import Popup from '../confirmpopup/index.js';
import useAuth from '../../hooks/useAuth.js';

import Dialog from '../dialog/index.js';

import previewicon from '../../img/preview24iconblack.png';
import settingsicon from '../../img/settings24icon.png';
import analyticsicon from '../../img/analytics24icon.png';
import submenuicon from '../../img/submenu24icon.png';
import renameicon from '../../img/rename24icon.png';
import deleteicon from '../../img/delete24icon.png';

const ModelPanel = ( props ) => {

    const [isEditing, setIsEditing] = useState(false);
    const [refresh, setRefresh] = useState(false); // add state for refresh
    const [newName, setNewName] = useState(props.model.sfDoc.name);    
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [isDeletePopup, setIsDeletePopup] = useState(false);
    const [submenuVisible, setSubmenuVisible] = useState(false);
    
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const submenuRef = useRef(null);
    const submenuIconRef = useRef(null);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const { auth } = useAuth();
    const navigate = useNavigate();

    //const apiURL = "https://test2.3dabackend.com";
    const apiURL = `${process.env.REACT_APP_API_BASE_URL}`

    console.debug("props in ModelPanel");
    console.debug(props);

    const startEditing = () => {
        setIsEditing(true);
    }

    const cancelEditing = () => {
        setIsEditing(false);
        setNewName(props.model.sfDoc.name);
    }

    const handleNameChange = (event) => {
        setNewName(event.target.value);
    }

    const saveName = () => {
        setIsPopupOpen(true);
        setPopupMessage('Do you want to save the changes?');
      };

    const confirmSave = () => {
        /* AXIOS CHIAMATA RENAME */
        axios.post(apiURL + '/forum/model/rename', {
            modelId: props.model.id,
            uid: props.model.uid,
            name: newName
        })
        .then(response => {
            console.debug(response);
            setRefresh(true); // set refresh state to true on success
            setIsEditing(false);
            setIsPopupOpen(false);
        })
        .catch(error => {
            console.debug(error);
        });
    }

    const cancelSave = () => {
        setIsPopupOpen(false);
      };    

    const newWindowClick = (url) => {
        window.open(url, '_blank');
    }

    const deleteModel = () => {
        setIsPopupOpen(true);
        setPopupMessage(`Are you sure you want to delete ${props.model.sfDoc.name}?`);
        setIsDeletePopup(true);
      };
    
      const confirmDelete = () => {
        axios
          .post(apiURL + `/portus/model/delete`,
          { 
            idUtente: auth.id,
            uid: props.model.uid,
          },
          {
            headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id, }
          }
        )
          .then((response) => {
            console.debug(response);
            setIsPopupOpen(false);
          })
          .catch((error) => {
            console.debug(error);
          });
      };
    
      const cancelDelete = () => {
        setIsPopupOpen(false);
        setIsDeletePopup(false);
      };

    // aggiungere la data di ogni modello

    function handleSettingsClick(e) {
      // Prevents click event from bubbling up to parent elements
      e.stopPropagation();
  
      // Your button logic here
      console.debug("settings clicked");
      navigate('/configurations', {
        state: {
          conftest: props.model.confTest,
          confprod: props.model.confProd,
          type: 'prod',
          modelInfo: props.model,
          selectedModelHDImage: props.modelHDImageUrl,
          configurationAlreadyOpen: true
        }
      });
    }

    function handlePanelClick(e) {
          // Prevents click event from bubbling up to parent elements
          e.stopPropagation();
  
          // Your button logic here
          console.debug("settings clicked");
          navigate('/configurations', {
            state: {
              conftest: props.model.confTest,
              confprod: props.model.confProd,
              type: 'prod',
              modelInfo: props.model,
              selectedModelHDImage: props.modelHDImageUrl,
              configurationAlreadyOpen: false
            }
          });
      }

    function handleAnalyticsClick(e) {
      console.debug(props.selectedModelHDImage)
      // Prevents click event from bubbling up to parent elements
      e.stopPropagation();
      navigate('/analytics', {
        state: {
          fromModelPanel: true,
          models: props.models,
          selectedModel: props.model,
          selectedModelNumber: props.modelNumber,
          selectedModelHDImage: props.modelHDImageUrl,
          selectedTab: '2'
        }
      });
    }
        
    const handleSubmenuClick = () => {
      setIsSubmenuOpen(!isSubmenuOpen);
    };
    
    // useEffect hook to re-render component on refresh state change
    useEffect(() => {
      if (refresh) {
          setRefresh(false); // set refresh state back to false
      }
    }, [refresh]);

    // submenu useEffect
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          submenuRef.current &&
          submenuIconRef.current &&
          !submenuRef.current.contains(event.target) &&
          !submenuIconRef.current.contains(event.target) &&
          isSubmenuOpen
        ) {
          setIsSubmenuOpen(false);
        }
      };
  
      document.addEventListener('click', handleClickOutside);
  
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [isSubmenuOpen]);

    const openDeleteDialog = () => {
      setIsDeleteDialogOpen(true);
    };
    
    const closeDeleteDialog = () => {
      setIsDeleteDialogOpen(false);
    };
    
    const handleDeleteConfirm = () => {
      props.handleDeleteConfirm(props.model.uid)
    };
    
    const handleDeleteCancel = () => {
      setIsDeleteDialogOpen(false);
    };
    
    const handleSubmenuDeleteClick = (modelId) => {
      setIsSubmenuOpen(false);
      props.handleOpenDeleteDialog(modelId);
    };

    const handleRenameClick = (modelId) => {
      setIsSubmenuOpen(false);
      props.handleRenameClick(modelId);
    };    

    return(
        <CardContainer >
            {/*<ContentWrapper data-tilt className="js-tilt">*/}
            <ButtonWrapper>
              <ButtonContainer>
                <SettingsButton src={settingsicon} onClick={handleSettingsClick}></SettingsButton>
                <AnalyticsButton src={analyticsicon} onClick={handleAnalyticsClick}></AnalyticsButton>
                <SubmenuButton src={submenuicon} onClick={handleSubmenuClick} ref={submenuIconRef}></SubmenuButton>
                {/*
                <OutputTestButton onClick={() => newWindowClick(props.model.urlTest)}>Test</OutputTestButton>
                <OutputProdButton onClick={() => newWindowClick(props.model.urlProd)}>Prod</OutputProdButton>
                <button onClick={deleteModel}>Delete</button>
                */}
              </ButtonContainer>
              {isSubmenuOpen && (
                <SubMenuContainer isOpen={isSubmenuOpen} ref={submenuRef}>
                  <SubMenuWrapper>
                    <SubMenuRow onClick={() => handleRenameClick(props.model.uid)}>
                        <SubMenuIcon src={renameicon}></SubMenuIcon>
                        Rinomina
                    </SubMenuRow>
                    <SubMenuRow onClick={() => handleSubmenuDeleteClick(props.model.uid)}>
                        <SubMenuIcon src={deleteicon}></SubMenuIcon>
                        Elimina Modello
                    </SubMenuRow>
                    <SubMenuLine/>
                    <SubMenuRowLink  to={`/output/${auth.id}/${props.model.uid}/${'test'}`} target='__blank'>
                        <SubMenuIcon src={previewicon}></SubMenuIcon>
                        Link Test
                      </SubMenuRowLink>
                    <SubMenuRowLink  to={`/output/${auth.id}/${props.model.uid}/${'prod'}`} target='__blank'>
                      <SubMenuIcon src={previewicon}></SubMenuIcon>
                        Link LIVE
                    </SubMenuRowLink>
                  </SubMenuWrapper>
                </SubMenuContainer>
              )}
            </ButtonWrapper>
            <ContentWrapper  onClick={handlePanelClick}>
              <GradientOverlayContent></GradientOverlayContent>
              <ThumbnailWrapper>
                  <ThumbnailContent src={props.modelsUrl}></ThumbnailContent>
              </ThumbnailWrapper>
              <TitleWrapper>  
              {isEditing ? (
                <input
                    type="text"
                    value={newName}
                    onChange={handleNameChange}
                    onBlur={cancelEditing}
                    onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        saveName();
                    }
                    }}
                />
                ) : (
                <div onClick={startEditing}>{props.model.sfDoc.name}</div>
              )}
              </TitleWrapper>
            </ContentWrapper>
            <Popup isOpen={isPopupOpen} onClose={cancelDelete} onCancel={cancelDelete} onConfirm={isDeletePopup ? confirmDelete : confirmSave} message={popupMessage} />

            </CardContainer>
    )
}

export default ModelPanel;