import styled from 'styled-components';

export const SidebarContainer = styled.div`
    height: 72px;
    width: 100%;
    background: transparent;
    color: #eeeeee;
display: flex;
`   

export const ViewContainer = styled.div`
  height: calc(100% - 72px);
  width: 100%;
  background: transparent;
  //background: linear-gradient(60deg, #2c287b, #773824);
  color: #eeeeee;
  display: flex;
  flex-direction: column;
  z-index: 1;
`

export const BodyContainer = styled.div`
  display:flex;
  color: #F8FAFC;
  flex-grow: 1;    
  align-items: center;
  padding: 40px 64px 40px 64px;
  height: calc(100% - 225px);
`;

export const HeaderBarContainer = styled.div`
    border-bottom: 2px solid #1E293B;
`

export const HeaderBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: stretch;
    margin: 36px 0px 0px 64px;
    gap: 16px;
`

export const HeaderRowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-grow: 1;
`

export const HeaderTitle = styled.div`
    font-weight: bold;
    font-size: 40px;
`

export const HeaderTabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 40px;
    gap: 8px;
`

export const HeaderTabElement = styled.div`
    background: ${({ isActive }) => (isActive ? '#1E293B' : 'transparent')};
    border-radius: 8px 8px 0px 0px;
    color: ${({ isActive }) => (isActive ? '#F8FAFC' : '#334155')};
    padding: 10px 12px;
    cursor: pointer;
`

export const DatePickerWrapper = styled.div`
    display: flex;    
    gap: 16px;
    padding: 0 16px;
    align-items: center;
`

export const DatePicker = styled.input`
    height: 32px;
    border-radius: 8px;
    border: 1px solid rgb(255 255 255 / 8%);
    padding: 0 16px;
    background: #1e293b;
    color: #eeeeee;
    font-size: 16px;
    font-family: 'Space Grotesk', sans-serif;
    color-scheme: dark;
`