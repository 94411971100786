import { css } from 'styled-components';

export const responsive = css`
    --sizeresponsive64: 64px;
    --sizeresponsive54: 54px;
    --sizeresponsive48: 48px;
    --sizeresponsive32: 32px;
    --sizeresponsive24: 24px;

  @media (max-width: 767px) {
    --sizeresponsive64: 16px;
    --sizeresponsive54: 16px;
    --sizeresponsive48: 16px;
    --sizeresponsive32: 8px;
    --sizeresponsive24: 8px;
  }

`