import styled from 'styled-components';

export const SidebarContainer = styled.div`
height: 72px;
width: 100%;
background: transparent;
color: #eeeeee;
display: flex;
`

export const ViewContainer = styled.div`
height: calc(100% - 72px);
width: 100%;
background: transparent;
//background: linear-gradient(60deg, #2c287b, #773824);
color: #eeeeee;
display: flex;
flex-direction: column;
z-index: 1;
    align-content: center;
    align-items: center;
    gap: 8px;
`

export const HeaderBar = styled.div`
height: 42px;
display: flex;
justify-content: space-between;
padding: 0px 8px;
align-items: center;
`

export const ButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px;
`

export const ButtonIcon = styled.img`
width: 32px;
height: 32px;
`

export const RowButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: end;
    justify-content: space-evenly;
    gap: 8px;
`


export const ButtonSectionHeader = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
`


export const ButtonSectionContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 16px;
`

export const DataSection = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
`

export const BackButton = styled.button`
    width: 100px;
    height: 40px;
    background-color: #111;
    border-radius: 8px;
    padding: 0 12px;
    cursor: pointer;
    text-align: center;
    color: #ddd;
`

export const ActionDiv = styled.div`
    height: 40px;
    background-color: #111;
    border-radius: 16px;
    padding: 0 12px;
    margin: 8px;
    cursor: pointer;
    text-align: center;
    word-break: break-word;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ButtonAction = styled.div`
    width: 32px;
    height: 32px;
    background: #e9e9e9;
    color: #eeeeee;
    border-radius: 4px;
    cursor: pointer;
`

export const InternalButton = styled.div`
    background: linear-gradient(135deg,rgba(249, 115, 22, 1),rgba(194, 65, 12, 1));
    height: 32px;
    flex-grow: 1;
    color: #F8FAFC;
    font-family: 'Space Grotesk', sans-serif;
    text-transform: uppercase;
    padding: 0 16px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 16px;
    line-height: 32px;
`


export const InternalCancelButton = styled.div`
    background: #334155;
    height: 32px;
    flex-grow: 0;
    color: #F8FAFC;
    font-family: 'Space Grotesk', sans-serif;
    text-transform: uppercase;
    padding: 0 16px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 16px;
    line-height: 32px;
`

export const DBAformcontainer = styled.div`

`

export const DBAformwrapper = styled.div`

`

export const DBAformline = styled.div`

`

export const SingleName = styled.h4`

`

export const SingleInput = styled.input`

`


export const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 8px;
    height: 100%;    
`

export const ActionInput = styled.input`
    background: #334155;
    border-radius: 4px;
    padding: 12px 16px;
    border: none;
    width: calc(100% - 32px);
    text-align: center;
    color: #F8FAFC;
    font-family: 'Space Grotesk', sans-serif;
    outline:0; 
    font-weight: 300;

    &:disabled{
        background: rgba(71, 85, 105, 0.4);
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 8px;

    button {
        margin-left: 10px;
    }
`;


export const ViewWrapper = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
`