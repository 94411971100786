import React, { createContext, useContext, useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth.js';
import io from 'socket.io-client';
import { toast } from 'react-toastify';

const WebSocketContext = createContext();

// Custom hook to use the context easily
export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const { auth } = useAuth();
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [uploadStatusMap, setUploadStatusMap] = useState({});
  const [modelNameMap, setModelNameMap] = useState({});
  const apiURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (!auth?.id) return;

    const socketInstance = io(apiURL, {
      path: '/cursus/socket.io',
      query: { "client_id": auth.id },
      transports: ["websocket"],
      secure: true,
    });

    setSocket(socketInstance);

    //console.log(`Client connected: ${auth.id}`);

    socketInstance.on('connect', () => {
      //console.log('Socket connected');
    });

    socketInstance.on('message', (data) => {
      console.log('Received message:', data);
      setMessages((prevMessages) => [...prevMessages, data]);

      const { type, data: { status, model } } = data;

      if (type === 'UPLOAD_OUTCOME') {
        const { uid } = model;
        console.log('websocket modelmap: ', modelNameMap);

        if (!uploadStatusMap[uid]) {
          uploadStatusMap[uid] = { name: model.name };
        }

        const modelName = modelNameMap[uid] || 'Unknown Model';


        switch (status) {
          case 'INITIALIZED':
            toast.info(`Upload of a new model initialized. [1/5]`);
            break;
          case 'PROCESSING':
            toast.info(`Processing of model ${modelName} has started. [2/5]`);
            break;
          case 'SUCCEEDED':
            toast.info(`Upload of model ${modelName} succeeded. [3/5]`);
            break;
          case 'FINALIZING':
            toast.info(`Finalizing model ${modelName}. [4/5]`);
            break;
          case 'FINALIZED':
            toast.success(`Model ${modelName} finalized successfully. [5/5]`);
            // delete uploadStatusMap[uid];
            break;
          case 'FAILED':
            toast.error(`Upload of model ${modelName} failed.`);
            // delete uploadStatusMap[uid];
            break;
          default:
            toast.info(`Status of model ${modelName}: ${status}.`);
        }

        setUploadStatusMap({ ...uploadStatusMap });
      }

      acknowledgeMessage(data.key).catch(error => {
        console.error('Failed to acknowledge message:', error);
      });
    });

    socketInstance.on('disconnect', () => {
      //console.log('Socket disconnected');
    });

    return () => {
      socketInstance.disconnect();
    };
  }, [auth?.id, modelNameMap]);

  const acknowledgeMessage = async (messageKey) => {
    try {
      const response = await fetch(`${apiURL}/cursus/message/ack`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth?.accessToken}`,
          'id': auth.id,
        },
        body: JSON.stringify({ message_key: messageKey }),
      });

      if (!response.ok) {
        throw new Error(`Error acknowledging message: ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Message acknowledged:', result);
    } catch (error) {
      console.error('Failed to acknowledge message:', error);
    }
  };

  return (
    <WebSocketContext.Provider value={{ messages, modelNameMap, setModelNameMap }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
