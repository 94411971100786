import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './js/reportWebVitals';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './App';
import Redirects from './components/redirects';
import { MobileProvider } from './context/MobileContext';
import './index.css';
import WebSocketProvider from './context/WebSocketContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <BrowserRouter>
    <Routes>
      <Route path="/redirects/*" element={<Redirects />} />
      <Route path="/*" element={
          <AuthProvider>
              <MobileProvider>
                  <WebSocketProvider>
                      <App />
                      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                  </WebSocketProvider>
              </MobileProvider>  
          </AuthProvider>
      } />
    </Routes>
  </BrowserRouter>
  
);

reportWebVitals();
