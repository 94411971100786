import { useEffect, useState, useRef} from 'react';
import useAuth from '../../hooks/useAuth.js';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import {LoginWrapper,LoginContentWrapper,LoginContent,LogoWrapper,LogoContent,CredentialWrapper,
  InputCredPswContent,InputCredContent,LoginButton,CopyrightContent,ErrorContent,LogoH1
  ,MetaBallContainer, LoginFooter,Logo3danimate} from './loginElements.js';
import AuthContext from '../../context/AuthProvider.js';
import https from 'https';
import LogoColor from '../../img/LogoColorLanding.svg';
import landingball from '../../img/backloopopen1.gif'
import logo from '../../img/3dportlabwhite.png';


const LOGIN_URL = `${process.env.REACT_APP_API_BASE_URL}/censor/login`;
// const LOGIN_URL = 'https://0146-87-17-214-243.ngrok-free.app/censor/login'
let LogoPNG = require('../../img/3dalogo.png');

function Login() {

  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const userRef = useRef();
  const errRef = useRef();

  const [ user, setUser ] = useState("");
  const [ pwd, setPwd ] = useState("");
  const [ errMsg, setErrMsg ] = useState("");

  const agent = new https.Agent({
    rejectUnauthorized: false
  });

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg("");
  }, [ user, pwd ])

  const handleSubmit2 = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(LOGIN_URL, {
        usr: user,
        psw: pwd
      });

      const isTesting = process.env.REACT_APP_TESTING === 'true';
      const success = isTesting ? response.data.data.success : response.data.success;
      const tokenData = isTesting ? response.data.data : response.data;

      const { token: accessToken, role: roles, id } = tokenData;

      if (success) {
        console.log("login response: ", response);
        console.log("login response.data: ", response.data);
        console.log("login roles: ", roles);

        setAuth({ id, user, pwd, roles, accessToken, loading: false });
        setPwd('');
        if ([1, 2].includes(roles)) {
          navigate('/dashboard');
        } else if (roles === 3) {
          navigate('/DBA');
        } else {
          console.log('Invalid role');
        }
      } else {
        throw new Error('Login Failed');
      }
    } catch (err) {
      let errMsg = 'Login Failed';
      if (err?.response?.status === 500 && err.response.data.message === 'User not found') {
        errMsg = 'Credenziali non valide';
      }
      setErrMsg(errMsg);
      errRef.current?.focus();
    }
  }

  return(
    <>
      <LoginWrapper>
          <LoginContentWrapper>
            <LoginContent>
              <LogoH1>Login</LogoH1>
              <CredentialWrapper>
                  <InputCredContent id="username" type="text" placeholder="Username" name="username" ref={userRef} value={user} onChange={(e) => setUser(e.target.value)} required></InputCredContent>
              </CredentialWrapper>
              <CredentialWrapper>
                  <InputCredPswContent id="password"  type="password" placeholder="Password" name="password" value={pwd} onChange={(e) => setPwd(e.target.value)} required></InputCredPswContent>
              </CredentialWrapper>
              {/*
              <LoginButton type="submit">Login</LoginButton>
              */}
              <LoginButton onClick={handleSubmit2}>Login</LoginButton>
              {errMsg ? <ErrorContent>{errMsg}</ErrorContent> : null }
              <LoginFooter>
              Powered By:
              <Logo3danimate src={LogoPNG}></Logo3danimate>
              </LoginFooter>
            </LoginContent>
          </LoginContentWrapper>
          <LogoWrapper>
              <LogoContent src={logo}/>
            <MetaBallContainer metaball={landingball} />
          </LogoWrapper>
      </LoginWrapper>
    </>
  )
}

export default Login;