import styled from "styled-components";

export const ButtonAction = styled.div`
    width: 128px;
    height: 32px;
    background: #111111;
    color: #eeeeee;
    border-radius: 4px;
`

export const DBAformcontainer = styled.div`

`

export const DBAformwrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const DBAformline = styled.div`

`

export const SingleName = styled.h4`

`

export const SingleInput = styled.input`
    background: #334155;
    border-radius: 4px;
    padding: 12px 16px;
    border: none;
    width: calc(100% - 32px);
    text-align: center;
    color: #F8FAFC;
    font-family: 'Space Grotesk', sans-serif;
    outline:0; 
    font-weight: 300;

    &:disabled{
        background: rgba(71, 85, 105, 0.4);
    }
`

export const LogoUploader = styled.input`

`

export const InternalButton = styled.div`
    background: linear-gradient(135deg,rgba(249, 115, 22, 1),rgba(194, 65, 12, 1));
    height: 32px;
    // flex-grow: 1;
    color: #F8FAFC;
    font-family: 'Space Grotesk', sans-serif;
    text-transform: uppercase;
    padding: 0 16px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 16px;
    line-height: 32px;
`


export const InternalCancelButton = styled.div`
    background: #334155;
    height: 32px;
    flex-grow: 1;
    color: #F8FAFC;
    font-family: 'Space Grotesk', sans-serif;
    text-transform: uppercase;
    padding: 0 16px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 16px;
    line-height: 32px;
`