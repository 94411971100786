import styled, { keyframes } from 'styled-components';

const rotate51Animation = keyframes`
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
`;

const translate52Animation = keyframes`
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(24px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
`;

const translate53Animation = keyframes`
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-24px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
`;

const translate54Animation = keyframes`
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, 24px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
`;

const translate55Animation = keyframes`
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, -24px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
`;

export const LoaderContainer = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    align-content: center;
    justify-content: center;
`

export const LoaderWrapper = styled.div`
	position: relative;
	height: 32px;
	width: 32px;	        
    animation: ${rotate51Animation} 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;


    &::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: auto;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #FFF;
	border-radius: 50%;
    animation: ${translate52Animation} 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }

    &::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: auto;
	bottom: 0; right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #FFF;
	border-radius: 50%;
    animation: ${translate53Animation} 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }

`

export const LoaderSpan = styled.span`
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 32px;
    width: 32px;

    &::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: auto; right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #FFF;
	border-radius: 50%;
    animation: ${translate54Animation} 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    } 

    ::after {
	content: "";
	display: block;
	position: absolute;
	top: auto; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #FFF;
	border-radius: 50%;
    animation: ${translate55Animation} 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }  

`