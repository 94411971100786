import React, { useEffect, useRef, useState } from 'react';

const ConfigurationTest = () => {
    const viewerRef = useRef(null);
    const [api, setApi] = useState(null);
    const [materials, setMaterials] = useState([]);
    const [selectedMaterialIndex, setSelectedMaterialIndex] = useState("");


    const setOpacity = (number, opacityValue) => {
        const materialToUpdate = materials[number];
        materialToUpdate.channels.Opacity.factor = opacityValue;
        materialToUpdate.channels.Opacity.enable = opacityValue !== 1;
    
        api.setMaterial(materialToUpdate, function() {
            console.log(materialToUpdate);
            window.console.log('Material updated');
        });
    };

    useEffect(() => {
        const clientInit = () => {
            const iframe = viewerRef.current;
            const version = '1.7.0';
            const urlid = 'd73c009a2816429b82f659cfdf74cf80';
            const client = new window.Sketchfab(version, iframe);

            client.init(urlid, {
                success: (apiInstance) => {
                    setApi(apiInstance);
                    apiInstance.start();
                    apiInstance.addEventListener('viewerready', () => {
                        // Once the viewer is ready, get the list of materials
                        apiInstance.getMaterialList((err, materialsList) => {
                            if (!err) {
                                setMaterials(materialsList);
                                console.log(materialsList)
                            }
                        });
                    });
                },
                autostart: 1,
                vr: 1,
                error: () => {
                    console.error('Viewer error');
                }
            });
        };

        if (window.Sketchfab) {
            clientInit();
        } else {
            const script = document.createElement('script');
            script.src = 'https://static.sketchfab.com/api/sketchfab-viewer-1.7.0.js';
            script.onload = clientInit;
            document.body.appendChild(script);
        }
    }, []);

    return (
        <div>
        <iframe ref={viewerRef} width="640" height="480" 
        allowFullScreen="" src=""
        allow='xr-spacial-tracking'></iframe>
        <div>
            <select
                value={selectedMaterialIndex}
                onChange={(e) => setSelectedMaterialIndex(e.target.value)}
            >
                <option value="">Select a material</option>
                {materials.map((material, index) => (
                    <option key={material.id} value={index}>
                        {material.name}
                    </option>
                ))}
            </select>
            <button
                onClick={() => {
                    if (selectedMaterialIndex !== "") {
                        const idx = Number(selectedMaterialIndex);
                        const currentOpacity = materials[idx].channels.Opacity.factor;
                        const newOpacity = currentOpacity === 0 ? 1 : 0;
                        setOpacity(idx, newOpacity);
                    }
                }}
            >
                Toggle Material Visibility
            </button>
        </div>
    </div>
    );
};

export default ConfigurationTest;
