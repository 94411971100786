import React from 'react';
import {LoaderContainer,LoaderWrapper,LoaderSpan} from './circleloaderElements'

const CircleLoader = (props) => {

    return(
        <>
            <LoaderContainer>
                <LoaderWrapper>
                    <LoaderSpan></LoaderSpan>
                </LoaderWrapper>
            </LoaderContainer>
        </>
    )

}

export default CircleLoader;